import React, { FC } from 'react'
import OnboardingStepTemplate from './OnboardingStepTemplate'

interface Props {
  phaseNum: number
  numPhases: number
  onNext: () => void
}
const OnboardingConclusionDialog: FC<Props> = (props) => {
  const { phaseNum, onNext, numPhases } = props

  return (
    <OnboardingStepTemplate
      phaseNum={phaseNum}
      numPhases={numPhases}
      heading={`You're all set! Let Skeema do the organizing from here so you can focus on what truly matters.`}
      buttonText={`Get Started`}
      onButtonClick={onNext}
      delayButtonActivation={false}
    />
  )
}
export default OnboardingConclusionDialog
