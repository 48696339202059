import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Text,
  Spinner,
  Collapse,
  Box,
  Flex,
  Button,
  ToastId,
  UseToastOptions,
  useToast,
} from '@chakra-ui/react'
import { FiChevronDown, FiDownload } from 'react-icons/fi'
import PutasideTabController from './PutasideTabController'
import { TablistPageType } from '../../../models/tablist_pages.types'
import { styled } from 'styled-components'
import FavIconList, { FavIconListItemType } from '../FavIconList'
import { useUserContext } from '../../../contexts/UserContext'
import { useReduxSelector, useReduxDispatch } from '../../../redux/baseStore'
import {
  useAppendTablistPagesMutation,
  useDeleteTablistPageMutation,
  useGetTablistPagesQuery,
  useLazyGetOlderSavedPagesQuery,
} from '../../../redux/services/skeema/tablist_pages.endpoints'

import SavedPagesLimitModal from './SavedPagesLimitModal'
import { FEATURE_FLAG_KEYS_ENUM } from '../../../models/feature_flags.types'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import { loadOlderSavedPages, updateMostRecentSavedPages } from '../../../redux/tablistSlice'
import { useGetOpenTabsQuery, useDeleteOpenTabMutation } from '../../../webapp/redux/extension'
import InstantSaveToast from '../../morningPageV2/instantSave/InstantSaveToast'
import { cx, css } from '@emotion/css'
import { IoFilter } from 'react-icons/io5'
import ToggleButton from '../../../components/ToggleButton'
import SearchResultsReplacementWrapper from '../search/SearchResultsReplacementWrapper'

export interface SelectedTablistPageType {
  [id: string]: TablistPageType
}

export enum TABLIST_AREA_NAME_ENUM {
  MostUsed = 'most_used_tabs',
  Open = 'open_tabs',
  RecentlySaved = 'other_recently_used_tabs',
  SearchResults = 'search_results',
  SmartSessions = 'smart_sessions',
  FeedHistory = 'feed_history',
}

const SectionContainer = styled.div`
  margin-bottom: 16px;
`

const TableHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-left: 16px;
`

const TableHeaderCell = styled.div<{ $isClickable?: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'default')};

  h2 {
    font-size: 14px;
    font-weight: 500;
    color: #585858;
    line-height: 22px; /* 157.143% */
  }
`

const CollapseIcon = styled.span<{ $isCollapsed: boolean }>`
  height: 16px;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 1.2em;
  font-weight: bold;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 8px;
  width: 16px;
  color: black;

  & > svg {
    display: flex;
    transform: ${({ $isCollapsed }) => `rotate(${$isCollapsed ? -90 : 0}deg)`};
    transition: transform 0.5s;
    transform-origin: 50% 50%;
  }
`

const TabSweepZeroStateContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 16px 16px;
  width: 100%;

  & > * {
    font-size: 14px;
    color: #a7a7a7;
    white-space: pre;
  }
`

const onboardingPrepAnimationClassName = css`
  .using-now-header {
    transition: opacity 500ms ease-in-out 500ms;
    opacity: 0;
  }
  .saved-header {
    transition: opacity 500ms ease-in-out 1.5s;
    opacity: 0;
  }
  .most-used-section {
    transition: max-height 2600ms ease-in-out 2.5s;
    max-height: 0;
    overflow: hidden;
  }
`

const onboardingAnimationClassName = css`
  .using-now-header {
    opacity: 1;
  }
  .saved-header {
    opacity: 1;
  }
  .most-used-section {
    max-height: 500px;
  }
`

const LOADING_REFETCH_INTERVAL = 2000
const MAX_LOADING_REFETCH_COUNT = 10
const TOAST_DURATION_MS = 8000
const USE_TOAST_OPTIONS: UseToastOptions = {
  position: 'bottom',
  containerStyle: {
    maxWidth: 'none',
    margin: 0,
  },
}

interface Props {
  shouldRefetch?: boolean
  isOnboardingTutorialEnabled?: boolean
}

const PutasideTabList: FC<Props> = (props) => {
  const { shouldRefetch = false, isOnboardingTutorialEnabled = false } = props
  const { captureAnalytics, userInfo } = useUserContext()
  const { featureFlags } = useFeatureFlagContext()
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()

  const toast = useToast(USE_TOAST_OPTIONS)
  const toastIdRef = React.useRef<ToastId | undefined>(undefined)

  const shouldIncludeOpenTabs = featureFlags[FEATURE_FLAG_KEYS_ENUM.ShouldIncludeOpenTabs]
  const IsPaywallEnabled = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsPaywallEnabled]
  const shouldHaveUnlimitedInstantSave =
    userInfo?.is_premium || featureFlags[FEATURE_FLAG_KEYS_ENUM.ShouldHaveUnlimitedInstantSave]
  const isShowMoreSavedPagesEnabled = userInfo?.is_premium

  const instantSaveQuota = useReduxSelector((state) => state.user.instantSaveQuota)
  const savedPages = useReduxSelector((state) => state.tablist.savedPages)

  const [isManualFilterActive, setIsManualFilterActive] = useState<boolean>(false)
  const filteredSavedPages = useMemo(() => {
    if (!isManualFilterActive) {
      return savedPages
    }

    return savedPages?.filter((page) => {
      return page.is_manual
    })
  }, [isManualFilterActive, savedPages])

  const handleIsManualButtonClicked = () => {
    setIsManualFilterActive(!isManualFilterActive)
    captureAnalytics('putaside_tab_list:is_manual_toggle_click', {
      isToggledOn: !isManualFilterActive,
    })
  }

  const [isMostUsedPagesCollapsed, setIsMostUsedPagesCollapsed] = useState<boolean>(
    !isOnboardingTutorialEnabled,
  )
  const [isOpenTabsCollapsed, setIsOpenTabsCollapsed] = useState<boolean>(
    !isOnboardingTutorialEnabled,
  )

  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false)
  useEffect(() => {
    setTimeout(() => {
      setShouldAnimate(isOnboardingTutorialEnabled)
    }, 500)
  }, [isOnboardingTutorialEnabled])

  const [isSavedPagesLimitModalOpen, setIsSavedPagesLimitModalOpen] = useState<boolean>(false)

  // Server queries and mutations
  const { data: openTabPages } = useGetOpenTabsQuery(undefined, { pollingInterval: 1000 })

  const { data: tablistPagesQueryResult, refetch } = useGetTablistPagesQuery(undefined)
  const mostVisitedPages = tablistPagesQueryResult?.most_visited_pages
  const mostRecentSavedPages = tablistPagesQueryResult?.tablist

  useEffect(() => {
    //Syncing the most recent saved pages with the redux store
    mostRecentSavedPages && dispatch(updateMostRecentSavedPages(mostRecentSavedPages))
  }, [dispatch, mostRecentSavedPages])

  const numRefetches = useRef<number>(0)
  useEffect(() => {
    //Refetching the tablist pages at regular intervals if needed (e.g. during onboarding)
    if (!shouldRefetch) {
      return
    }

    const refetchHelper = () => {
      if (numRefetches.current < MAX_LOADING_REFETCH_COUNT) {
        refetch()
        numRefetches.current += 1
        setTimeout(refetchHelper, LOADING_REFETCH_INTERVAL)
      }
    }

    setTimeout(refetchHelper, 0)
  }, [refetch, shouldRefetch])

  const [
    triggerGetOlderTablistPagesQuery,
    {
      data: olderTablistPagesQueryData,
      error: olderTablistPagesQueryError,
      isFetching: isMoreSavedPagesLoading,
    },
  ] = useLazyGetOlderSavedPagesQuery({ refetchOnFocus: false })

  const doMoreSavedPagesExist = olderTablistPagesQueryData
    ? olderTablistPagesQueryData.do_more_saved_pages_exist
    : tablistPagesQueryResult?.do_more_saved_pages_exist

  useEffect(() => {
    //Loading the older "load more" saved pages into the redux store
    if (olderTablistPagesQueryError) {
      console.error(olderTablistPagesQueryError)
      return
    }

    olderTablistPagesQueryData && dispatch(loadOlderSavedPages(olderTablistPagesQueryData.tablist))
  }, [dispatch, olderTablistPagesQueryData, olderTablistPagesQueryError])

  const [appendTablistPages] = useAppendTablistPagesMutation()
  const [_deleteTablistPage] = useDeleteTablistPageMutation()
  const [_deleteOpenTab] = useDeleteOpenTabMutation()

  const deleteTablistPage = _deleteTablistPage as unknown as (params: {
    page: TablistPageType
    index: number
  }) => Promise<void>
  const deleteOpenTab = _deleteOpenTab as unknown as (params: { id: string }) => Promise<void>

  const handleOnClickUpgrade = useCallback(() => {
    captureAnalytics('instant_save_toast:upgrade_click')
    navigate('/saved#pricing')
  }, [captureAnalytics, navigate])

  const saveAndCloseOpenTab = useCallback(
    async (params: { page: TablistPageType }) => {
      const { page } = params
      const id = page.id

      const isPaywallBlockingSave =
        IsPaywallEnabled && !shouldHaveUnlimitedInstantSave && (instantSaveQuota ?? 0) <= 0

      if (!isPaywallBlockingSave) {
        await appendTablistPages({ page, isManual: true })
          .unwrap()
          .then((res) => {
            if (res.ok) {
              deleteOpenTab({ id })
            }
          })
          .catch((error) => {
            console.error(error)
          })
      }

      if (IsPaywallEnabled && !shouldHaveUnlimitedInstantSave) {
        const toastParams = {
          duration: TOAST_DURATION_MS,
          render: () => (
            <InstantSaveToast
              instantSaveQuota={instantSaveQuota}
              onClickUpgrade={handleOnClickUpgrade}
            />
          ),
          onCloseComplete: () => {
            toastIdRef.current = undefined
          },
        }
        if (toastIdRef.current) {
          toast.update(toastIdRef.current, toastParams)
        } else {
          toastIdRef.current = toast({ position: 'bottom', ...toastParams })
        }
      }
    },
    [
      IsPaywallEnabled,
      appendTablistPages,
      deleteOpenTab,
      handleOnClickUpgrade,
      instantSaveQuota,
      shouldHaveUnlimitedInstantSave,
      toast,
    ],
  )

  useEffect(() => {
    // Update the toast if the instant save quota changes
    if (IsPaywallEnabled && toastIdRef.current) {
      const toastParams = {
        duration: TOAST_DURATION_MS,
        render: () => (
          <InstantSaveToast
            instantSaveQuota={instantSaveQuota}
            onClickUpgrade={handleOnClickUpgrade}
          />
        ),
      }
      toast.update(toastIdRef.current, toastParams)
    }
  }, [IsPaywallEnabled, handleOnClickUpgrade, instantSaveQuota, toast])

  const lastSavedPageTimestampMs = savedPages?.[savedPages.length - 1]?.last_access_timestamp_ms
  const onClickShowMoreSavedPages = useCallback(() => {
    captureAnalytics('putaside_tab_list:show_more_saved_pages_click', {
      lastSavedPageTimestampMs: lastSavedPageTimestampMs,
    })

    if (!lastSavedPageTimestampMs) {
      return
    }

    triggerGetOlderTablistPagesQuery({
      to_ts: Math.floor(lastSavedPageTimestampMs / 1000),
    })
  }, [captureAnalytics, lastSavedPageTimestampMs, triggerGetOlderTablistPagesQuery])

  const handleSavedPagesLimitError = useCallback(() => {
    captureAnalytics('putaside_tab_list:saved_pages_limit_modal_show')
    setIsSavedPagesLimitModalOpen(true)
  }, [captureAnalytics])

  const handleDismissSavedPagesLimitModal = () => {
    captureAnalytics('putaside_tab_list:saved_pages_limit_modal_dismiss_click')
    setIsSavedPagesLimitModalOpen(false)
  }

  const handleSubmitSavedPagesLimitModal = () => {
    captureAnalytics('putaside_tab_list:saved_pages_limit_modal_upgrade_click')
    setIsSavedPagesLimitModalOpen(false)
    navigate('/saved#pricing')
  }

  return (
    <Box
      className={cx({
        [onboardingPrepAnimationClassName]: isOnboardingTutorialEnabled,
        [onboardingAnimationClassName]: shouldAnimate,
      })}
      width="100%"
      height="100%"
      overflow="hidden"
      display="flex"
      flexDirection="column"
    >
      <Box p="24px 8px 24px 16px">
        <SearchResultsReplacementWrapper>
          {mostVisitedPages && mostVisitedPages.length > 0 && (
            <SectionContainer className="most-used-section">
              <TableHeaderContainer>
                <TableHeaderCell
                  onClick={() => setIsMostUsedPagesCollapsed(!isMostUsedPagesCollapsed)}
                  $isClickable={true}
                >
                  <h2>Most used tabs</h2>
                  <CollapseIcon $isCollapsed={!!isMostUsedPagesCollapsed}>
                    <FiChevronDown />
                  </CollapseIcon>
                </TableHeaderCell>
              </TableHeaderContainer>

              <span
                style={{
                  flex: '1 2 auto',
                  display: isMostUsedPagesCollapsed ? 'flex' : 'none',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  overflow: 'auto',
                  minWidth: '24px',
                  maxWidth: '100%',
                  padding: '0 10px',
                }}
              >
                <FavIconList
                  items={mostVisitedPages.map((t) => {
                    return {
                      id: t.id,
                      url: t.url,
                      title: t.title,
                      faviconUrl: t.favicon_url,
                    } as FavIconListItemType
                  })}
                  size="md"
                  showTitleTooltip={true}
                  isClickable={true}
                  areaName={TABLIST_AREA_NAME_ENUM.MostUsed}
                />
              </span>

              <Collapse in={!isMostUsedPagesCollapsed} animateOpacity>
                <div>
                  {mostVisitedPages?.map((t, idx) => {
                    return (
                      <PutasideTabController
                        key={t.id}
                        id={t.id}
                        page={t}
                        showTimeString={false}
                        queryValue={''}
                        index={idx}
                        numTotalResults={mostVisitedPages.length}
                        areaName={TABLIST_AREA_NAME_ENUM.MostUsed}
                        isDraggingDisabled={false}
                      />
                    )
                  })}
                </div>
              </Collapse>
            </SectionContainer>
          )}

          {shouldIncludeOpenTabs && openTabPages && openTabPages.length > 0 && (
            <SectionContainer>
              <TableHeaderContainer>
                <TableHeaderCell
                  className="using-now-header"
                  onClick={() => setIsOpenTabsCollapsed(!isOpenTabsCollapsed)}
                  $isClickable={true}
                >
                  <h2>Using now</h2>
                  <CollapseIcon $isCollapsed={!!isOpenTabsCollapsed}>
                    <FiChevronDown />
                  </CollapseIcon>
                </TableHeaderCell>
              </TableHeaderContainer>

              <span
                style={{
                  flex: '1 2 auto',
                  display: isOpenTabsCollapsed ? 'flex' : 'none',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  overflow: 'auto',
                  minWidth: '24px',
                  maxWidth: '100%',
                  padding: '0 10px',
                }}
              >
                <FavIconList
                  items={openTabPages.map((t) => {
                    return {
                      id: t.id,
                      url: t.url,
                      faviconUrl: t.favicon_url,
                      title: t.title,
                      windowId: t.window_id,
                      isOpen: true,
                      tabId: parseInt(t.id),
                    } as FavIconListItemType
                  })}
                  isClickable={true}
                  areaName={TABLIST_AREA_NAME_ENUM.Open}
                  size="md"
                  showTitleTooltip={true}
                  maxLength={8}
                  onClickExpand={() => setIsOpenTabsCollapsed(false)}
                />
              </span>

              <Collapse in={!isOpenTabsCollapsed} animateOpacity>
                <div>
                  {openTabPages?.map((t, idx) => {
                    return (
                      <PutasideTabController
                        key={t.id}
                        id={t.id}
                        page={t}
                        onDeleteOpenTab={deleteOpenTab}
                        onSaveOpenTab={saveAndCloseOpenTab}
                        showTimeString={false}
                        queryValue={''}
                        index={idx}
                        numTotalResults={openTabPages.length}
                        areaName={TABLIST_AREA_NAME_ENUM.Open}
                      />
                    )
                  })}
                </div>
              </Collapse>
            </SectionContainer>
          )}

          <SectionContainer>
            <TableHeaderContainer>
              <TableHeaderCell className="saved-header">
                <h2>All saved tabs</h2>
              </TableHeaderCell>
            </TableHeaderContainer>

            <Box m="0 0 16px 8px">
              <ToggleButton
                icon={<IoFilter />}
                label={'Saved by Me'}
                isToggledOn={isManualFilterActive}
                onClick={handleIsManualButtonClicked}
              />
            </Box>

            {!filteredSavedPages && (
              <div className="flex-center" style={{ width: '100%', marginTop: '32px' }}>
                <Spinner color="blue.500" size="lg" speed="1s" />
              </div>
            )}

            {filteredSavedPages && filteredSavedPages.length === 0 && !isManualFilterActive && (
              <div className="flex-center" style={{ width: '100%' }}>
                <Text textAlign={'center'} margin={4} fontSize="14px" color="#a7a7a7">
                  Check back later! Your saved tabs will be right here.
                </Text>
              </div>
            )}

            {filteredSavedPages && filteredSavedPages.length === 0 && isManualFilterActive && (
              <TabSweepZeroStateContainer>
                <Text>{`"Saved by Me" tabs will appear when `}</Text>
                <Text>{`you manually `}</Text>
                <Text>{`save an `}</Text>
                <Text>{`open `}</Text>
                <Text>{`tab `}</Text>
                <Text>{`with`}</Text>
                <span className="flex-center" style={{ padding: '0 4px' }}>
                  <FiDownload style={{ width: '16px', height: '16px', color: '#585858' }} />
                </span>
                <Text>{`in `}</Text>
                <Text>{`Skeema, `}</Text>
                <Text>{`or use the Skeema extension icon`}</Text>
                <span className="flex-center" style={{ padding: '0 4px' }}>
                  <img
                    style={{ width: '16px', height: '16px', color: '#585858' }}
                    src="/logo-128.png"
                    alt="Skeema logo icon"
                  />
                </span>
                <Text>{`while `}</Text>
                <Text>{`looking `}</Text>
                <Text>{`at that tab.`}</Text>
              </TabSweepZeroStateContainer>
            )}

            <div>
              {filteredSavedPages?.map((t, idx) => {
                return (
                  <PutasideTabController
                    key={t.id}
                    id={t.id}
                    page={t}
                    onDeleteTablistPage={deleteTablistPage}
                    queryValue={''}
                    titleHighlights={undefined}
                    index={idx}
                    numTotalResults={filteredSavedPages.length}
                    areaName={TABLIST_AREA_NAME_ENUM.RecentlySaved}
                  />
                )
              })}
            </div>

            {/* START LOAD MORE BUTTON FOR TABLIST */}
            {doMoreSavedPagesExist !== undefined && (
              <Flex w="100%" alignItems="center" justifyContent="flex-end">
                {doMoreSavedPagesExist && (
                  <Button
                    key="extra"
                    size="sm"
                    fontSize={12}
                    fontWeight={500}
                    borderRadius={'16px'}
                    mt={'8px'}
                    h="32px"
                    minH="32px"
                    w="100%"
                    px={2}
                    onClick={
                      isShowMoreSavedPagesEnabled
                        ? onClickShowMoreSavedPages
                        : handleSavedPagesLimitError
                    }
                    bg="#F6F6F6"
                    color="#585858"
                    _hover={{ bg: '#EBEBEB' }}
                    isLoading={isMoreSavedPagesLoading}
                  >
                    {`Show older`}
                  </Button>
                )}
                {!doMoreSavedPagesExist && (savedPages?.length ?? 0) !== 0 && (
                  <Text
                    fontSize={12}
                    fontWeight={500}
                    mt="8px"
                    w="100%"
                    px={2}
                    color="#A7A7A7"
                    textAlign="center"
                  >
                    {`You've reached the end of your saved tabs history`}
                  </Text>
                )}
              </Flex>
            )}
            {/* END LOAD MORE BUTTON FOR TABLIST */}
          </SectionContainer>

          <SavedPagesLimitModal
            isOpen={isSavedPagesLimitModalOpen}
            onCancel={handleDismissSavedPagesLimitModal}
            onSubmit={handleSubmitSavedPagesLimitModal}
          />
        </SearchResultsReplacementWrapper>
      </Box>
    </Box>
  )
}

export default PutasideTabList
