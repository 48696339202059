import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { Text, Spinner, Box, Flex, Button } from '@chakra-ui/react'
import {
  useGetSmartSessionsWithOpenTabsV2Query,
  useLazyGetSmartSessionsWithOpenTabsV2Query,
} from '../../redux/services/skeema/smart_sessions.endpoints'
import SmartSessionCard from './smartSessions/SmartSessionCard'
import { TablistPageType } from '../../models/tablist_pages.types'
import { useUserContext } from '../../contexts/UserContext'
import { useFeatureFlagContext } from '../../contexts/FeatureFlagContext'
import { useReduxDispatch, useReduxSelector } from '../../redux/baseStore'
import {
  selectNumActiveProjects,
  useCreateProjectMutation,
  useGetActiveProjectsQuery,
} from '../../redux/services/skeema/projects.endpoints'
import { setNewlyCreatedProjectId } from '../../redux/projectsSlice'
import { useGetOpenTabsQuery } from '../../webapp/redux/extension'
import { styled } from 'styled-components'
import { FEATURE_FLAG_KEYS_ENUM } from '../../models/feature_flags.types'
import ProjectLimitModal from './projectsSidebar/ProjectLimitModal'
import { useNavigate } from 'react-router-dom'
import { SmartSessionType } from '../../models/smart_sessions.types'
import DraftProjectLimitModal from './smartSessions/DraftProjectLimitModal'

const CardListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

function getDateString(date: Date) {
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
}

function groupSessions(sessions: SmartSessionType[]) {
  const date_today = getDateString(new Date())
  const date_yesterday = getDateString(new Date(Date.now() - 24 * 60 * 60 * 1000))
  const grouped = sessions.reduce(
    (acc, s) => {
      const dateStr = getDateString(new Date(s.datetime_end_ts * 1000))
      const groupHeader =
        dateStr === date_today ? 'Today' : dateStr === date_yesterday ? 'Yesterday' : dateStr
      if (!acc[groupHeader]) {
        acc[groupHeader] = []
      }
      acc[groupHeader].push(s)
      return acc
    },
    {} as Record<string, SmartSessionType[]>,
  )

  return grouped
}

interface Props {
  authHeader: string
  isOnboardingTutorialEnabled: boolean
}

const SmartSessionDashboard: FC<Props> = (props) => {
  const { authHeader, isOnboardingTutorialEnabled } = props
  const navigate = useNavigate()
  const { captureAnalytics, userInfo } = useUserContext()
  const dispatch = useReduxDispatch()
  const [sessions, setSessions] = useState<SmartSessionType[] | undefined>(undefined)
  const [currentPageDatetimeEnd, setCurrentPageDatetimeEnd] = useState<number | undefined>(
    undefined,
  )
  const [shouldSkipQueryHook, setShouldSkipQueryHook] = useState<boolean>(false)
  const [isProjectLimitModalOpen, setIsProjectLimitModalOpen] = useState<boolean>(false)
  const [isDraftProjectLimitModalOpen, setIsDraftProjectLimitModalOpen] = useState<boolean>(false)

  const { projectConfig, featureFlags } = useFeatureFlagContext()
  const numProjects = useReduxSelector(selectNumActiveProjects)
  const maxNumProjects = projectConfig.maxNumProjects
  const isProjectLimitReached = numProjects === undefined || numProjects >= maxNumProjects

  const isSmartSessionsEnabled =
    userInfo?.is_premium || featureFlags[FEATURE_FLAG_KEYS_ENUM.IsSmartSessionsEnabled]

  const { data: openTabPages, isLoading: isOpenTabsLoading } = useGetOpenTabsQuery(undefined)
  // TODO:
  // 1. When page is reloaded, this query will be triggered twice once with empty openTabs and once with actual openTabs
  // 2. The way we handle query refetch with the shouldSkipQueryHook is awkward
  const { data: sessionsQueryData } = useGetSmartSessionsWithOpenTabsV2Query(
    {
      openTabs: openTabPages,
      from_ts: currentPageDatetimeEnd,
    },
    {
      skip: shouldSkipQueryHook || isOpenTabsLoading,
    },
  )

  const sessionsGroupedByDate = useMemo(() => {
    if (!sessions) {
      return undefined
    }
    return groupSessions(sessions)
  }, [sessions])

  useEffect(() => {
    if (sessionsQueryData) {
      setSessions(sessionsQueryData.sessions)
      if (sessionsQueryData.sessions.length > 0) {
        setCurrentPageDatetimeEnd(
          sessionsQueryData.sessions[sessionsQueryData.sessions.length - 1].datetime_end_ts,
        )
      }
    }
  }, [sessionsQueryData])

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (window.document.hidden) {
        setShouldSkipQueryHook(false)
      }
    }
    window.document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      window.document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [captureAnalytics, dispatch])

  const { data: existingProjects } = useGetActiveProjectsQuery(undefined, { skip: !authHeader }) //TODO: Remove this, not needed for create project
  const [createProjectMutation] = useCreateProjectMutation()

  const [triggerSmartSessionsQuery, manualSmartSessionsQueryResult] =
    useLazyGetSmartSessionsWithOpenTabsV2Query({ refetchOnFocus: false })
  const { data: manualSmartSessionsData, error: manualSmartSessionsError } =
    manualSmartSessionsQueryResult
  const doMorePagesExist = manualSmartSessionsData
    ? manualSmartSessionsData.do_more_pages_exist
    : sessionsQueryData?.do_more_pages_exist

  useEffect(() => {
    if (manualSmartSessionsError) {
      console.error('Error with manualSmartSessionsQueryResult', manualSmartSessionsError)
      return
    }

    if (!manualSmartSessionsData) {
      return
    }

    if (manualSmartSessionsData.sessions.length > 0) {
      setSessions((prevSessions) => {
        return [...(prevSessions ?? []), ...manualSmartSessionsData.sessions]
      })
      if (manualSmartSessionsData.sessions.length > 0) {
        setCurrentPageDatetimeEnd(
          manualSmartSessionsData.sessions[manualSmartSessionsData.sessions.length - 1]
            .datetime_end_ts,
        )
      }
    }
  }, [manualSmartSessionsData, manualSmartSessionsError])

  const handleCreateProject = useCallback(
    async (title: string, tablistPages: TablistPageType[]) => {
      if (isProjectLimitReached) {
        return
      }

      const project = await createProjectMutation({
        title,
        tablistPages,
        existingProjects: existingProjects === undefined ? [] : existingProjects,
      })
        .then((result) => {
          if ('error' in result) {
            console.error(result.error)
            return undefined
          }
          return result.data
        })
        .catch((e) => {
          console.error(e)
          return undefined
        })

      if (project) {
        dispatch(setNewlyCreatedProjectId(project.id))
      }
    },
    [createProjectMutation, dispatch, existingProjects, isProjectLimitReached],
  )

  const onClickShowOlderSessions = useCallback(() => {
    const to_ts = currentPageDatetimeEnd ?? Math.floor(Date.now() / 1000) - 24 * 60 * 60
    captureAnalytics('smart_session_dashboard:show_older_sessions_click', {
      isFullDraftProjectsEnabled: isSmartSessionsEnabled,
      to_ts: to_ts,
    })
    setShouldSkipQueryHook(true)
    triggerSmartSessionsQuery({
      openTabs: openTabPages,
      to_ts,
    })
  }, [
    captureAnalytics,
    currentPageDatetimeEnd,
    isSmartSessionsEnabled,
    openTabPages,
    triggerSmartSessionsQuery,
  ])

  const handleDraftProjectLimitError = useCallback(() => {
    captureAnalytics('smart_session_dashboard:draft_project_limit_modal_show')
    setIsDraftProjectLimitModalOpen(true)
  }, [captureAnalytics])

  const handleDismissDraftProjectLimitModal = () => {
    captureAnalytics('smart_session_dashboard:draft_project_limit_modal_dismiss_click')
    setIsDraftProjectLimitModalOpen(false)
  }

  const handleSubmitDraftProjectLimitModal = () => {
    captureAnalytics('smart_session_dashboard:draft_project_limit_modal_upgrade_click')
    setIsDraftProjectLimitModalOpen(false)
    navigate('/saved#pricing')
  }

  const handleProjectLimitError = useCallback(() => {
    captureAnalytics('smart_session_dashboard:project_limit_modal_show')
    setIsProjectLimitModalOpen(true)
  }, [captureAnalytics])

  const handleDismissProjectLimitModal = () => {
    captureAnalytics('smart_session_dashboard:project_limit_modal_dismiss_click')
    setIsProjectLimitModalOpen(false)
  }

  const handleSubmitProjectLimitModal = () => {
    captureAnalytics('smart_session_dashboard:project_limit_modal_upgrade_click')
    setIsProjectLimitModalOpen(false)
    navigate('/saved#pricing')
  }

  return (
    <Box p="24px 16px 24px 8px">
      {!sessionsGroupedByDate && (
        <div className="flex-center" style={{ width: '100%', margin: '32px' }}>
          <Spinner color="blue.500" size="lg" speed="1s" />
        </div>
      )}
      {sessionsGroupedByDate && Object.keys(sessionsGroupedByDate).length === 0 && (
        <div className="flex-center" style={{ width: '100%' }}>
          <Text textAlign={'center'} margin={4} fontSize="14px" color="#a7a7a7">
            {`Skeema uses AI to suggest projects for you. Keep browsing and they'll appear here soon!`}
          </Text>
        </div>
      )}
      <CardListContainer>
        {sessionsGroupedByDate &&
          Object.keys(sessionsGroupedByDate).length > 0 &&
          Object.keys(sessionsGroupedByDate).map((key, idx) => {
            const group = sessionsGroupedByDate[key]
            return (
              <React.Fragment key={key}>
                <Text
                  ml="6px"
                  mt={idx > 0 ? '4px' : '0'}
                  fontSize={'12px'}
                  fontWeight={500}
                  color="#A7A7A7"
                >
                  {key}
                </Text>
                {group.map((session, idx) => (
                  <SmartSessionCard
                    key={`${session.name}${idx}`}
                    session={session}
                    forceShowSaveButton={isOnboardingTutorialEnabled}
                    handleCreateProject={handleCreateProject}
                    isProjectLimitReached={isProjectLimitReached}
                    handleProjectLimitError={handleProjectLimitError}
                  />
                ))}
              </React.Fragment>
            )
          })}

        {doMorePagesExist !== undefined && (
          <Flex w="100%" alignItems="center" justifyContent="flex-end">
            {doMorePagesExist && (
              <Button
                key="extra"
                size="sm"
                fontSize={12}
                fontWeight={500}
                borderRadius={'16px'}
                h="32px"
                minH="32px"
                w="100%"
                px={2}
                onClick={
                  isSmartSessionsEnabled ? onClickShowOlderSessions : handleDraftProjectLimitError
                }
                bg="#F6F6F6"
                color="#585858"
                _hover={{ bg: '#EBEBEB' }}
                isLoading={manualSmartSessionsQueryResult.isLoading}
              >
                {`Show older`}
              </Button>
            )}
            {!doMorePagesExist && sessions && sessions.length > 0 && (
              <Text
                fontSize={12}
                fontWeight={500}
                mt="8px"
                w="100%"
                px={2}
                color="#A7A7A7"
                textAlign="center"
              >
                {`You've reached the end of Draft Projects`}
              </Text>
            )}
          </Flex>
        )}
      </CardListContainer>

      <DraftProjectLimitModal
        isOpen={isDraftProjectLimitModalOpen}
        onCancel={handleDismissDraftProjectLimitModal}
        onSubmit={handleSubmitDraftProjectLimitModal}
      />

      <ProjectLimitModal
        isOpen={isProjectLimitModalOpen}
        onCancel={handleDismissProjectLimitModal}
        onSubmit={handleSubmitProjectLimitModal}
      />
    </Box>
  )
}

export default SmartSessionDashboard
