import { FC, useEffect, useRef } from 'react'
import { UNINSTALL_FEEDBACK_FORM_URL } from '../../../constants'
import { useUserContext } from '../../../contexts/UserContext'
import { postExtensionUninstallation } from '../../../apiClient/skeema/fetchWrappers'
import { useAuthContext } from '../../../contexts/AuthContext'

const postExtensionUninstallationWrapper = async (headers: Record<string, string>) => {
  await postExtensionUninstallation(headers)
}

const ExtensionUninstalledPage: FC = () => {
  const { authHeader } = useAuthContext()
  const { captureAnalytics } = useUserContext()
  const hasLoggedPosthog = useRef<boolean>(false) // used to prevent multiple tracking calls
  const hasLoggedUninstallation = useRef<boolean>(false) // used to prevent multiple tracking calls

  useEffect(() => {
    //Fall back in case redirect after logging somehow goes wrong
    setInterval(() => {
      window.location.href = UNINSTALL_FEEDBACK_FORM_URL
    }, 3000)
  }, [])

  useEffect(() => {
    if (hasLoggedPosthog.current) {
      return
    }

    captureAnalytics('uninstall')

    hasLoggedPosthog.current = true
  }, [captureAnalytics])

  useEffect(() => {
    if (hasLoggedUninstallation.current) {
      return
    }

    if (authHeader !== undefined) {
      const headers = { Authorization: authHeader }
      void postExtensionUninstallationWrapper(headers)
        .then(() => {
          hasLoggedUninstallation.current = true
        })
        .catch((e) => {
          console.error('Error logging extension uninstallation event', e)
        })
        .finally(() => {
          window.location.href = UNINSTALL_FEEDBACK_FORM_URL
        })
    } else {
      console.log("Can't log uninstallation event without auth header")
    }
  }, [authHeader])

  return null
}

export default ExtensionUninstalledPage
