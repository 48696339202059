import React, { FC, useEffect, useRef } from 'react'
import { Button, Flex } from '@chakra-ui/react'
import { useUserContext } from '../../contexts/UserContext'
import AnnouncementsPage from '../../components/AnnouncementsPage'
import { WEBAPP_BASE_URL } from '../../constants'
import { IoMdArrowBack } from 'react-icons/io'

const heading = '404 Not Found'
const content = [
  'Please check the website URL and try again.',
  'If the problem persists, please email support@skeema.com',
]

const NotFoundPage: FC = () => {
  const { captureAnalytics } = useUserContext()
  const didLogRef = useRef(false)

  useEffect(() => {
    if (didLogRef.current) {
      return
    }

    captureAnalytics('not_found_page:view', { url: window.location.href })
    didLogRef.current = true
  }, [captureAnalytics])

  const onClickContactSupport = () => {
    captureAnalytics('not_found_page:click_contact_support')
  }

  const onClickGoHome = () => {
    captureAnalytics('not_found_page:click_go_home')
  }

  return (
    <AnnouncementsPage
      heading={heading}
      content={content}
      widget={
        <Flex mb="4">
          <Button
            as="a"
            backgroundColor="black"
            borderRadius="20px"
            borderColor="#D5D5D5"
            color="white"
            fontWeight="normal"
            padding="0 30px"
            title="Skeema Support Email"
            href={`${WEBAPP_BASE_URL}/saved`}
            rel="noreferrer"
            onClick={onClickGoHome}
            leftIcon={<IoMdArrowBack />}
          >
            Return to Home
          </Button>
          <Button
            as="a"
            ml="16px"
            backgroundColor="black"
            borderRadius="20px"
            borderColor="#D5D5D5"
            color="white"
            fontWeight="normal"
            padding="0 30px"
            title="Skeema Support Email"
            href={`mailto:support@skeema.com?subject=Errors on ${window.location.href}`}
            target="_blank"
            rel="noreferrer"
            onClick={onClickContactSupport}
          >
            Contact Support
          </Button>
        </Flex>
      }
    />
  )
}

export default NotFoundPage
