import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Flex, Text, Button, Spinner, Box, IconButton } from '@chakra-ui/react'
import { styled } from 'styled-components'
import { useReduxDispatch, useReduxSelector } from '../../../redux/baseStore'
import { FiDownload } from 'react-icons/fi'
import { TablistPageType } from '../../../models/tablist_pages.types'
import {
  useDeleteTablistPageMutation,
  useGetTablistPagesQuery,
  useLazyGetOlderSavedPagesQuery,
} from '../../../redux/services/skeema/tablist_pages.endpoints'
import { loadOlderSavedPages, updateMostRecentSavedPages } from '../../../redux/tablistSlice'
import PutasideTabController from '../PutasideTabList/PutasideTabController'
import { TABLIST_AREA_NAME_ENUM } from '../PutasideTabList/PutasideTabList'
import { useUserContext } from '../../../contexts/UserContext'
import { useNavigate } from 'react-router-dom'
import SavedPagesLimitModal from '../PutasideTabList/SavedPagesLimitModal'
import { IoFilter } from 'react-icons/io5'
import ToggleButton from '../../../components/ToggleButton'
import SearchResultsReplacementWrapper from '../search/SearchResultsReplacementWrapper'
import { IoMdArrowBack } from 'react-icons/io'

const TableHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px 16px 14px;
  gap: 8px;
  cursor: pointer;

  h2 {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    line-height: 24px;
  }
`

const TabSweepZeroStateContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 16px 16px;
  width: 100%;

  & > * {
    font-size: 14px;
    color: #a7a7a7;
    white-space: pre;
  }
`

const SavedForLaterDashboard: FC = () => {
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()
  const { userInfo, captureAnalytics } = useUserContext()
  const isShowMoreSavedPagesEnabled = userInfo?.is_premium

  const [isSavedPagesLimitModalOpen, setIsSavedPagesLimitModalOpen] = useState<boolean>(false)
  const [isManualFilterActive, setIsManualFilterActive] = useState<boolean>(false)

  const savedPages = useReduxSelector((state) => state.tablist.savedPages)
  const filteredPages = useMemo(() => {
    if (!isManualFilterActive) {
      return savedPages
    }

    return savedPages?.filter((page) => {
      return page.is_manual
    })
  }, [isManualFilterActive, savedPages])

  const [_deleteTablistPage] = useDeleteTablistPageMutation()
  const deleteTablistPage = _deleteTablistPage as unknown as (params: {
    page: TablistPageType
    index: number
  }) => Promise<void>

  const { data: tablistPagesQueryResult } = useGetTablistPagesQuery(undefined)
  const mostRecentSavedPages = tablistPagesQueryResult?.tablist

  useEffect(() => {
    //Syncing the most recent saved pages with the redux store
    mostRecentSavedPages && dispatch(updateMostRecentSavedPages(mostRecentSavedPages))
  }, [dispatch, mostRecentSavedPages])

  const [
    triggerGetOlderTablistPagesQuery,
    {
      data: olderTablistPagesQueryData,
      error: olderTablistPagesQueryError,
      isFetching: isMoreSavedPagesLoading,
    },
  ] = useLazyGetOlderSavedPagesQuery({ refetchOnFocus: false })

  const doMoreSavedPagesExist = olderTablistPagesQueryData
    ? olderTablistPagesQueryData.do_more_saved_pages_exist
    : tablistPagesQueryResult?.do_more_saved_pages_exist

  useEffect(() => {
    //Loading the older "load more" saved pages into the redux store
    if (olderTablistPagesQueryError) {
      console.error(olderTablistPagesQueryError)
      return
    }

    olderTablistPagesQueryData && dispatch(loadOlderSavedPages(olderTablistPagesQueryData.tablist))
  }, [dispatch, olderTablistPagesQueryData, olderTablistPagesQueryError])

  const lastSavedPageTimestampMs = savedPages?.[savedPages.length - 1]?.last_access_timestamp_ms
  const onClickShowMoreSavedPages = useCallback(() => {
    captureAnalytics('saved_dashboard:show_more_saved_pages_click', {
      lastSavedPageTimestampMs: lastSavedPageTimestampMs,
    })

    if (!lastSavedPageTimestampMs) {
      return
    }

    triggerGetOlderTablistPagesQuery({
      to_ts: Math.floor(lastSavedPageTimestampMs / 1000),
    })
  }, [captureAnalytics, lastSavedPageTimestampMs, triggerGetOlderTablistPagesQuery])

  const handleIsManualButtonClicked = () => {
    setIsManualFilterActive(!isManualFilterActive)
    captureAnalytics('saved_dashboard:is_manual_toggle_click', {
      isToggledOn: !isManualFilterActive,
    })
  }

  const handleSavedPagesLimitError = useCallback(() => {
    captureAnalytics('saved_dashboard:saved_pages_limit_modal_show')
    setIsSavedPagesLimitModalOpen(true)
  }, [captureAnalytics])

  const handleDismissSavedPagesLimitModal = () => {
    captureAnalytics('saved_dashboard:saved_pages_limit_modal_dismiss_click')
    setIsSavedPagesLimitModalOpen(false)
  }

  const handleSubmitSavedPagesLimitModal = () => {
    captureAnalytics('saved_dashboard:saved_pages_limit_modal_upgrade_click')
    setIsSavedPagesLimitModalOpen(false)
    navigate('/saved#pricing')
  }

  const handleClickMenuBack = () => {
    captureAnalytics('saved_dashboard:back_click')
    navigate('/')
  }

  return (
    <Flex direction="column" h="100%" w="100%" p="24px 16px" overflow="auto">
      <SearchResultsReplacementWrapper>
        <TableHeaderContainer>
          <IconButton
            variant="outline"
            border="none"
            aria-label="Go to home page"
            icon={<IoMdArrowBack />}
            onClick={handleClickMenuBack}
            height="24px"
            width="24px"
            minWidth="24px"
            flexShrink={0}
            _hover={{
              bg: '#EBEBEB',
            }}
          />
          <h2>All saved tabs</h2>
        </TableHeaderContainer>

        <Box m="0 0 16px 8px">
          <ToggleButton
            icon={<IoFilter />}
            label={'Saved by Me'}
            isToggledOn={isManualFilterActive}
            onClick={handleIsManualButtonClicked}
          />
        </Box>

        {!savedPages && (
          <div className="flex-center" style={{ width: '100%', marginTop: '32px' }}>
            <Spinner color="blue.500" size="lg" speed="1s" />
          </div>
        )}

        {!isManualFilterActive && savedPages && savedPages.length === 0 && (
          <div className="flex-center" style={{ width: '100%' }}>
            <Text textAlign={'center'} margin={4} fontSize="14px" color="#a7a7a7">
              Check back later! Your saved tabs will be right here.
            </Text>
          </div>
        )}

        {isManualFilterActive && filteredPages && filteredPages.length === 0 && (
          <TabSweepZeroStateContainer>
            <Text>{`"Saved by Me" tabs will appear when `}</Text>
            <Text>{`you manually `}</Text>
            <Text>{`save an `}</Text>
            <Text>{`open `}</Text>
            <Text>{`tab `}</Text>
            <Text>{`with`}</Text>
            <span className="flex-center" style={{ padding: '0 4px' }}>
              <FiDownload style={{ width: '16px', height: '16px', color: '#585858' }} />
            </span>
            <Text>{`in `}</Text>
            <Text>{`Skeema, `}</Text>
            <Text>{`or use the Skeema extension icon`}</Text>
            <span className="flex-center" style={{ padding: '0 4px' }}>
              <img
                style={{ width: '16px', height: '16px', color: '#585858' }}
                src="/logo-128.png"
                alt="Skeema logo icon"
              />
            </span>
            <Text>{`while `}</Text>
            <Text>{`looking `}</Text>
            <Text>{`at that tab.`}</Text>
          </TabSweepZeroStateContainer>
        )}

        <div>
          {filteredPages?.map((t, idx) => {
            return (
              <PutasideTabController
                key={t.id}
                id={t.id}
                page={t}
                onDeleteTablistPage={deleteTablistPage}
                queryValue={''}
                index={idx}
                numTotalResults={filteredPages.length}
                areaName={TABLIST_AREA_NAME_ENUM.RecentlySaved}
                isManualFiltered={isManualFilterActive}
              />
            )
          })}
        </div>

        {/* START LOAD MORE BUTTON FOR TABLIST */}
        {doMoreSavedPagesExist !== undefined && (
          <Flex w="100%" alignItems="center" justifyContent="flex-end">
            {doMoreSavedPagesExist && (
              <Button
                key="extra"
                size="sm"
                fontSize={12}
                fontWeight={500}
                borderRadius={'16px'}
                mt={'8px'}
                h="32px"
                minH="32px"
                w="100%"
                px={2}
                onClick={
                  isShowMoreSavedPagesEnabled
                    ? onClickShowMoreSavedPages
                    : handleSavedPagesLimitError
                }
                bg="#F6F6F6"
                color="#585858"
                _hover={{ bg: '#EBEBEB' }}
                isLoading={isMoreSavedPagesLoading}
              >
                {`Show older`}
              </Button>
            )}
            {!doMoreSavedPagesExist && (savedPages?.length ?? 0) !== 0 && (
              <Text
                fontSize={12}
                fontWeight={500}
                mt="8px"
                w="100%"
                px={2}
                color="#A7A7A7"
                textAlign="center"
              >
                {`You've reached the end of your saved tabs history`}
              </Text>
            )}
          </Flex>
        )}
        {/* END LOAD MORE BUTTON FOR TABLIST */}

        <SavedPagesLimitModal
          isOpen={isSavedPagesLimitModalOpen}
          onCancel={handleDismissSavedPagesLimitModal}
          onSubmit={handleSubmitSavedPagesLimitModal}
        />
      </SearchResultsReplacementWrapper>
    </Flex>
  )
}

export default SavedForLaterDashboard
