import React, { FC } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'

interface Props {
  isOpen: boolean
  onCancel: () => void
  onDelete: () => void
}

const ProjectDeletionModal: FC<Props> = (props) => {
  const { isOpen, onCancel, onDelete } = props

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent borderRadius={'16px'}>
        <ModalHeader>{`Delete this project?`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="16px" color="black">
            {`The project will be permanently deleted`}
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button borderRadius={'100px'} variant="ghost" mr={3} onClick={onCancel}>
            Cancel
          </Button>

          <Button
            size="md"
            variant="solid"
            borderRadius="100px"
            paddingRight="24px"
            paddingLeft="24px"
            backgroundColor={'black'}
            color={'white'}
            _hover={{
              backgroundColor: '#585858',
            }}
            onClick={onDelete}
            autoFocus
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ProjectDeletionModal
