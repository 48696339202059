import React, { FC } from 'react'
import styled from 'styled-components'
import { AiOutlineChrome } from 'react-icons/ai'
import { IconButton, Tooltip } from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'
import DragIcon from '../../../components/DragIcon'

const ProjectPageContainer = styled.div<{
  $isChecked: boolean
  $isDraggingThis: boolean
  $isDraggingDisabled: boolean
  $isDraggingOverThis: boolean
}>`
  position: relative;
  width: 100%;
  height: 28px;
  padding: 0;

  display: flex;
  align-items: center;

  border-radius: 4px;
  opacity: 1;
  z-index: 10;

  opacity: ${({ $isDraggingThis }) => ($isDraggingThis ? 0.5 : 1)};
  cursor: ${({ $isDraggingDisabled, $isDraggingThis }) =>
    $isDraggingThis ? 'grabbing' : $isDraggingDisabled ? 'auto' : 'grab'};

  & .hover-show {
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:hover,
  &.forceHoverStates {
    background: #ebebeb;

    .hover-show {
      background: #ebebeb; //Need background for covering long titles
      opacity: 1;

      &.DragIcon {
        opacity: ${({ $isDraggingDisabled }) => ($isDraggingDisabled ? 0 : 1)};
      }
    }
  }
  &.DragIcon {
    border-radius: 4px;
  }

  /* Wrapping elements in spans to create larger click targets */
  & > span {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .ContainerIcon {
    border-radius: 4px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    & > span {
      position: relative; //Need for children showOnHover/hideOnHover to be properly centered
      width: 16px;
      height: 16px;
      flex-shrink: 0;
    }
  }

  .ContainerTitle {
    flex-grow: 2;

    p.Title {
      color: #585858;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
      cursor: pointer;

      &:hover:not(.disabled) {
        text-decoration: underline;
        color: #585858;
      }
    }
  }
`

const DndIndicatorLine = styled.div<{ $isVisible: boolean; $isTop: boolean }>`
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  position: absolute;
  width: calc(100% - 26px);
  height: 2px;
  top: ${({ $isTop }) => ($isTop ? '-1px' : '27px')};
  right: 12px;
  background: #0071e3;
  pointer-events: none;
  z-index: 11;
`

interface PropTypes {
  title: string
  url: string
  favIconUrl?: string
  connectDnd?: (node: HTMLDivElement | null) => void
  isDraggingThis: boolean
  isDraggingDisabled: boolean
  isDraggingOverThis: boolean
  dragOverLocation?: 'top' | 'bottom'
  shouldForceHighlightTop: boolean
  shouldForceHighlightBottom: boolean
  handleClickIcon: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  handleClickTitle: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  handleClickBody: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  handleClickDelete?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const ProjectPageView: FC<PropTypes> = (props) => {
  const {
    title,
    favIconUrl,
    handleClickIcon,
    handleClickTitle,
    handleClickBody,
    handleClickDelete,
    connectDnd,
    isDraggingOverThis,
    dragOverLocation,
    isDraggingThis,
    isDraggingDisabled,
    shouldForceHighlightTop,
    shouldForceHighlightBottom,
  } = props
  const shouldHighlightTop = shouldForceHighlightTop || dragOverLocation === 'top'
  const shouldHighlightBottom = shouldForceHighlightBottom || dragOverLocation === 'bottom'

  return (
    <ProjectPageContainer
      className="ProjectPageContainer"
      ref={connectDnd}
      $isChecked={false}
      $isDraggingThis={isDraggingThis}
      $isDraggingDisabled={isDraggingDisabled}
      $isDraggingOverThis={isDraggingOverThis}
      onClick={handleClickBody}
    >
      <DndIndicatorLine
        $isVisible={shouldHighlightTop || shouldHighlightBottom}
        $isTop={shouldHighlightTop}
      />
      <span style={{ width: '12px' }} className="ContainerIcon DragIcon hover-show">
        <DragIcon />
      </span>
      <span className="ContainerIcon" onClick={handleClickIcon}>
        {favIconUrl ? (
          <img src={favIconUrl} width="16" height="16" />
        ) : (
          <AiOutlineChrome style={{ width: '16px', height: '16px', color: 'black' }} />
        )}
      </span>

      <span className="ContainerTitle" onClick={handleClickTitle}>
        <p className="Title is-ellipsis-1">{title}</p>
      </span>

      <span
        style={{
          position: 'absolute',
          right: '2px',
          flex: '0 0 auto',
          display: 'flex',
          alignItems: 'center',
          marginLeft: '8px',
          borderRadius: '4px',
          overflow: 'hidden',
        }}
      >
        {handleClickDelete && (
          <span
            className="flex-center hover-show"
            style={{
              height: '100%',
              cursor: 'pointer',
            }}
          >
            <Tooltip placement="top" label="Remove tab">
              <IconButton
                variant="outline"
                border="none"
                aria-label="Remove page"
                as="a"
                icon={<MdClose color="#585858" />}
                onClick={handleClickDelete}
                height="24px"
                width="24px"
                minWidth="24px"
                _hover={{ bg: '#ebebeb' }}
              />
            </Tooltip>
          </span>
        )}
      </span>
    </ProjectPageContainer>
  )
}

export default ProjectPageView
