import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Spinner } from '@chakra-ui/react'
import CreateProjectItem from './CreateProjectItem'
import ProjectSidebarItem from './ProjectSidebarItem'
import { TablistPageType } from '../../../models/tablist_pages.types'
import { useUserContext } from '../../../contexts/UserContext'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import { css, styled } from 'styled-components'
import { useDrop } from 'react-dnd'
import { DND_ITEM_ENUM } from '../../../models/dnd.types'
import { useDndScrolling } from 'react-dnd-scrolling'
import {
  useAddProjectPagesMutation,
  useCreateProjectMutation,
  useDeleteProjectPageMutation,
  useMoveProjectPageMutation,
  useMoveProjectMutation,
  useRenameProjectMutation,
  useGetActiveProjectsQuery,
} from '../../../redux/services/skeema/projects.endpoints'
import { useReduxDispatch, useReduxSelector } from '../../../redux/baseStore'
import { setNewlyCreatedProjectId } from '../../../redux/projectsSlice'
import ProjectPageLimitModal from './ProjectPageLimitModal'
import ProjectLimitModal from './ProjectLimitModal'
import { useNavigate } from 'react-router-dom'

const Container = styled.div<{
  $shouldShowDnDHighlight: boolean
}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: auto;
  transition: all 0.5s;

  ${({ $shouldShowDnDHighlight }) =>
    $shouldShowDnDHighlight
      ? css`
          border-radius: 14px;
          /* border: 1px dashed #0071e3; */
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='14' ry='14' stroke='%230071e3' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='8' stroke-linecap='butt'/%3e%3c/svg%3e");
          background-color: rgba(0, 113, 227, 0.2);
        `
      : css`
          border-radius: 0px;
          /* border: 1px solid transparent; */
          background-color: transparent;
        `};
`

const ProjectsWrapperContainer = styled.div<{
  $shouldShowDnDHighlight: boolean
}>`
  padding-right: 4px;
  padding-left: 4px;

  ${({ $shouldShowDnDHighlight }) =>
    $shouldShowDnDHighlight
      ? css`
          padding-top: 6px;
          padding-bottom: 6px;

          border-radius: 14px;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='14' ry='14' stroke='%230071e3' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='8' stroke-linecap='butt'/%3e%3c/svg%3e");
          background-color: rgba(0, 113, 227, 0.2);
        `
      : css`
          border-radius: 0px;
          background-color: transparent;
        `};
`

const DND_SCROLLING_OPTIONS = {}

interface Props {
  authHeader: string
  isOnboardingTutorialEnabled?: boolean
  isProjectsSidebarBlurred?: boolean
  detailProjectId?: string
}

const ProjectsList: FC<Props> = (props) => {
  const {
    authHeader,
    detailProjectId,
    isProjectsSidebarBlurred = false,
    isOnboardingTutorialEnabled = false,
  } = props
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()
  const [isProjectPageLimitModalOpen, setIsProjectPageLimitModalOpen] = useState<boolean>(false)
  const [isProjectLimitModalOpen, setIsProjectLimitModalOpen] = useState<boolean>(false)
  const isTablistPageDragging = useReduxSelector((state) => state.dnd.isTablistPageDragging)
  const isProjectDragging = useReduxSelector((state) => state.dnd.isProjectDragging)
  const isSmartSessionDragging = useReduxSelector((state) => state.dnd.isSmartSessionDragging)
  const newlyCreatedProjectId = useReduxSelector((state) => state.projects.newlyCreatedProjectId)
  const { captureAnalytics } = useUserContext()
  const { projectConfig } = useFeatureFlagContext()
  const maxNumProjects = projectConfig.maxNumProjects
  const maxNumProjectPages = projectConfig.maxNumProjectsPages
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)
  useDndScrolling(scrollContainerRef, DND_SCROLLING_OPTIONS)

  const [shouldExpandProjects, setShouldExpandProjects] = useState<boolean | null>(null)
  const shouldTriggerExpandProjects = !isProjectsSidebarBlurred && isOnboardingTutorialEnabled

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined = undefined

    if (shouldTriggerExpandProjects) {
      setShouldExpandProjects(true)

      timeout = setTimeout(() => {
        setShouldExpandProjects(false)
      }, 5000)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [shouldTriggerExpandProjects])

  const { data: projects, isLoading } = useGetActiveProjectsQuery(undefined, {
    skip: !authHeader,
  })
  const isProjectLimitReached = !projects || projects.length >= maxNumProjects

  const [createProjectMutation] = useCreateProjectMutation()
  const [renameProjectMutation] = useRenameProjectMutation()
  const [addProjectPagesMutation] = useAddProjectPagesMutation()
  const [deleteProjectPageMutation] = useDeleteProjectPageMutation()
  const [moveProjectPageMutation] = useMoveProjectPageMutation()
  const [moveProjectMutation] = useMoveProjectMutation()

  const handleCreateProject = useCallback(
    async (params: {
      tablistPages: TablistPageType[]
      title?: string
      order?: number
      loggingProps?: Record<string, unknown>
    }) => {
      const { tablistPages, title, order, loggingProps } = params
      if (isProjectLimitReached) {
        return
      }
      const project = await createProjectMutation({
        tablistPages,
        title,
        existingProjects: projects,
        order,
      })
        .then((result) => {
          if ('error' in result) {
            console.error(result.error)
            return undefined
          }
          return result.data
        })
        .catch((e) => {
          console.error(e)
          return undefined
        })

      if (project) {
        dispatch(setNewlyCreatedProjectId(project.id))
      }

      const analyticsName = `projects_list:create_project_${
        tablistPages.length > 0 ? 'dnd' : 'click'
      }`
      const pageEntityType = tablistPages.length > 0 ? tablistPages[0].entity_type : null
      captureAnalytics(analyticsName, {
        pageEntityType,
        numPages: project?.pages?.length,
        ...(project ?? {}),
        ...loggingProps,
      })
    },
    [isProjectLimitReached, createProjectMutation, projects, captureAnalytics, dispatch],
  )

  const handleMoveProject = useCallback(
    async (id: string, order: number) => {
      const project = await moveProjectMutation({ id, order })
        .then((result) => {
          if ('error' in result) {
            console.error(result.error)
            return undefined
          }
          return result.data
        })
        .catch((e) => {
          console.error(e)
          return undefined
        })

      const analyticsName = `projects_list:move_project_dnd`
      captureAnalytics(analyticsName, {
        numPages: project?.pages?.length,
        ...(project ?? {}),
      })
    },
    [captureAnalytics, moveProjectMutation],
  )

  const handleAddTabsToProject = useCallback(
    async (params: {
      projectId: string
      tablistPages: TablistPageType[]
      index?: number
      loggingProps?: Record<string, unknown>
    }) => {
      const { projectId, tablistPages, index, loggingProps } = params
      const project = projects?.find((project) => project.id === projectId)

      if (!project) {
        console.error('Project not found')
        return
      }

      const pages = await addProjectPagesMutation({
        project,
        tablistPages,
        maxNumProjectPages,
        index,
      })
        .then((result) => {
          if ('error' in result) {
            console.error(result.error)
            return undefined
          }
          return result.data
        })
        .catch((e) => {
          console.error(e)
          return undefined
        })

      // Logging analytics below
      const urls = project?.pages?.map((page) => page.url)
      const numNewPages = pages?.length
      const numCurrentPages = urls?.length
      const totalNumPages =
        numNewPages !== undefined && numCurrentPages !== undefined
          ? numCurrentPages + numNewPages
          : undefined
      const pageEntityType = tablistPages.length > 0 ? tablistPages[0].entity_type : null
      captureAnalytics('projects_list:pages_add_dnd', {
        newPages: pages,
        numNewPages,
        numCurrentPages,
        totalNumPages,
        urls,
        maxNumProjectPages,
        pageEntityType,
        index,
        isError: !pages,
        ...project,
        ...loggingProps,
      })
    },
    [addProjectPagesMutation, captureAnalytics, maxNumProjectPages, projects],
  )

  const handleMoveProjectPage = useCallback(
    async (params: { projectId: string; pageId: string; index: number; destProjectId: string }) => {
      const { projectId, pageId, index, destProjectId } = params
      const sourceProject = projects?.find((project) => project.id === projectId)
      const destProject = projects?.find((project) => project.id === destProjectId)
      const page = sourceProject?.pages?.find((page) => page.id === pageId)

      if (!page) {
        console.error(`Error: Page not found`)
        return
      }
      if (!destProject) {
        console.error(`Error: Dest project not found`)
        return
      }

      const pages = await moveProjectPageMutation({
        projectId,
        page,
        index,
        destProject,
        maxNumProjectPages,
      })
        .then((result) => {
          if ('error' in result) {
            console.error(result.error)
            return undefined
          }
          return result.data
        })
        .catch((e) => {
          console.error(e)
          return undefined
        })

      // Logging analytics below
      captureAnalytics('projects_list:pages_move_dnd', {
        page,
        sourceProject,
        destProject,
        index,
        maxNumProjectPages,
        isError: !pages,
      })
    },
    [moveProjectPageMutation, captureAnalytics, projects, maxNumProjectPages],
  )

  const handleProjectPageLimitError = useCallback(() => {
    captureAnalytics('projects_list:project_page_limit_modal_show')
    setIsProjectPageLimitModalOpen(true)
  }, [captureAnalytics])

  const handleDismissProjectPageLimitModal = () => {
    captureAnalytics('projects_list:project_page_limit_modal_cancel_click')
    setIsProjectPageLimitModalOpen(false)
  }

  const handleSubmitProjectPageLimitModal = () => {
    captureAnalytics('projects_list:project_page_limit_modal_upgrade_click')
    setIsProjectPageLimitModalOpen(false)
    navigate('#pricing')
  }

  const handleProjectLimitError = useCallback(() => {
    captureAnalytics('projects_list:project_limit_modal_show')
    setIsProjectLimitModalOpen(true)
  }, [captureAnalytics])

  const handleDismissProjectLimitModal = () => {
    captureAnalytics('projects_list:project_limit_modal_dismiss_click')
    setIsProjectLimitModalOpen(false)
  }

  const handleSubmitProjectLimitModal = () => {
    captureAnalytics('projects_list:project_limit_modal_upgrade_click')
    setIsProjectLimitModalOpen(false)
    navigate('#pricing')
  }

  const [{ isDraggingOver }, connectDropTarget] = useDrop(
    () => ({
      accept: [DND_ITEM_ENUM.TABLIST_PAGE, DND_ITEM_ENUM.SMART_SESSION],
      collect: (monitor) => ({
        isDraggingOver: monitor.isOver(),
      }),
      canDrop: () => {
        return false
      },
    }),
    [],
  )

  return (
    <>
      <Container
        className="scrollbars"
        ref={(node) => {
          scrollContainerRef.current = node
          connectDropTarget(node)
        }}
        $shouldShowDnDHighlight={
          (isTablistPageDragging || isSmartSessionDragging) && !isDraggingOver
        }
      >
        <CreateProjectItem
          isProjectLimitReached={isProjectLimitReached}
          handleProjectLimitError={handleProjectLimitError}
          handleCreateProject={handleCreateProject}
          isDisabled={isOnboardingTutorialEnabled}
        />

        {isLoading && (
          <div className="flex-center" style={{ width: '100%', marginTop: '32px' }}>
            <Spinner color="blue.500" size="lg" speed="1s" />
          </div>
        )}
        <ProjectsWrapperContainer $shouldShowDnDHighlight={isProjectDragging}>
          {projects &&
            projects.map((project) => (
              <ProjectSidebarItem
                key={project.id}
                project={project}
                maxNumPages={maxNumProjectPages}
                isProjectSelected={project.id === detailProjectId}
                isFocusedFromCreation={
                  project.id === newlyCreatedProjectId && !isOnboardingTutorialEnabled
                }
                shouldExpand={shouldExpandProjects}
                handleAddTabsToProject={handleAddTabsToProject}
                handleMoveProjectPage={handleMoveProjectPage}
                handleMoveProject={handleMoveProject}
                handlePageLimitError={handleProjectPageLimitError}
                renameProject={
                  renameProjectMutation as unknown as (params: {
                    id: string
                    title: string
                  }) => Promise<void>
                }
                deleteProjectPage={
                  deleteProjectPageMutation as unknown as (params: {
                    projectId: string
                    pageId: string
                  }) => Promise<void>
                }
              />
            ))}
        </ProjectsWrapperContainer>
      </Container>

      <ProjectPageLimitModal
        isOpen={isProjectPageLimitModalOpen}
        onCancel={handleDismissProjectPageLimitModal}
        onSubmit={handleSubmitProjectPageLimitModal}
      />

      <ProjectLimitModal
        isOpen={isProjectLimitModalOpen}
        onCancel={handleDismissProjectLimitModal}
        onSubmit={handleSubmitProjectLimitModal}
      />
    </>
  )
}

export default ProjectsList
