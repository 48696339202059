import React, { FC } from 'react'
import OnboardingStepTemplate from './OnboardingStepTemplate'

interface Props {
  phaseNum: number
  numPhases: number
  onNext: () => void
}
const OnboardingPrefaceDialog: FC<Props> = (props) => {
  const { phaseNum, onNext, numPhases } = props

  return (
    <OnboardingStepTemplate
      phaseNum={phaseNum}
      numPhases={numPhases}
      heading={`Skeema keeps your tabs organized. Always.`}
      buttonText={`Next`}
      onButtonClick={onNext}
      delayButtonActivation={false}
    />
  )
}
export default OnboardingPrefaceDialog
