import React, { FC, useEffect, useState } from 'react'
import {
  Text,
  Flex,
  Box,
  Heading,
  Button,
  Select,
  VStack,
  Divider,
  Icon,
  Wrap,
  Spacer,
} from '@chakra-ui/react'
import { ExtensionSettingsType } from '../../extension/utils/settingsUtils'
import { sendMessageToExtension } from '../../webapp/utils/externalMessaging'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../extension/models/messaging.types'
import { FormikProvider, useFormik, Field } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useFeatureFlagContext } from '../../contexts/FeatureFlagContext'
import { FEATURE_FLAG_KEYS_ENUM } from '../../models/feature_flags.types'
import { useUserContext } from '../../contexts/UserContext'
import { HiSparkles } from 'react-icons/hi2'
import { FEEDBACK_FORM_URL } from '../../constants'

interface FormikValueType {
  staleThresholdAfterOverwhelm: string | null
  overwhelmThreshold: string | null
}

enum ButtonState {
  Disabled = 'disabled',
  Enabled = 'enabled',
  Saved = 'saved',
}

const SettingsMainContent: FC = () => {
  const navigate = useNavigate()

  const [buttonState, setButtonState] = useState<ButtonState>(ButtonState.Disabled)

  const { isDefault, featureFlags } = useFeatureFlagContext()
  const isConfigurable = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsConfigurable]
  const isDeveloper = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsDeveloper]
  const isDemoAccount = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsDemoAccount]

  const { captureAnalytics } = useUserContext()

  useEffect(() => {
    captureAnalytics('settings_page:page_view')
  }, [captureAnalytics])

  useEffect(() => {
    if (!isDefault && !isConfigurable) {
      navigate('/not-found')
    }
  }, [isConfigurable, isDefault, navigate])

  const formik = useFormik<FormikValueType>({
    initialValues: {
      staleThresholdAfterOverwhelm: null,
      overwhelmThreshold: null,
    },
    onSubmit: (values) => {
      const settings = {
        staleThresholdAfterOverwhelm: Number(values.staleThresholdAfterOverwhelm),
        overwhelmThreshold: Number(values.overwhelmThreshold),
      } as Partial<ExtensionSettingsType>
      sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.SAVE_EXTENSION_SETTINGS, {
        settings,
      })
      formik.resetForm({ values })
      captureAnalytics('settings_page:save_button_click', settings)
      setButtonState(ButtonState.Saved)
      setTimeout(() => {
        setButtonState((prev) => (prev === ButtonState.Saved ? ButtonState.Disabled : prev))
      }, 3000)
    },
  })
  const { dirty, resetForm } = formik

  useEffect(() => {
    sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.GET_EXTENSION_SETTINGS)
      .then((response) => {
        const settings = response as ExtensionSettingsType
        const values = {
          staleThresholdAfterOverwhelm: settings.staleThresholdAfterOverwhelm.toString(),
          overwhelmThreshold: settings.overwhelmThreshold.toString(),
        }
        console.log('Settings fetched', settings)
        console.log('Values', values)
        resetForm({
          values,
        })
      })
      .catch((e) => {
        console.error('Error fetching settings', e)
      })
  }, [resetForm])

  useEffect(() => {
    if (dirty) {
      setButtonState(ButtonState.Enabled)
    } else {
      if (buttonState !== ButtonState.Saved) {
        setButtonState(ButtonState.Disabled)
      }
    }
  }, [buttonState, dirty])

  const form = (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <VStack alignItems={'start'}>
          <Heading as="h3" fontSize="14px" fontWeight={500} mb="4px">
            Time-based limit
          </Heading>
          <Text fontSize="12px" lineHeight="26px" color="#585858">
            Automatically save and close browser tabs after
            <Field
              as={Select}
              display={'inline-block'}
              w="86px"
              h="28px"
              mx={1}
              name="staleThresholdAfterOverwhelm"
              size="xs"
              fontSize="12px"
              borderColor="black"
              borderRadius={'8px'}
              _hover={{ bg: '#E3E3E3' }}
            >
              {isDeveloper && (
                <>
                  <option key="10000" value="10000">
                    10s (developer only)
                  </option>
                  <option key="600000" value="600000">
                    10 min
                  </option>
                </>
              )}
              {isDemoAccount && (
                <option key="60000" value="60000">
                  1 min (demo only)
                </option>
              )}
              <option key="3600000" value="3600000">
                1 hour &nbsp; (recommended)
              </option>
              <option key="14400000" value="14400000">
                4 hours
              </option>
              <option key="28800000" value="28800000">
                8 hours
              </option>
              <option key="86400000" value="86400000">
                24 hours
              </option>
            </Field>
            of inactivity.
          </Text>
          <Heading as="h3" fontSize="14px" fontWeight={500} mt="16px" mb="4px">
            Tabs-based limit
          </Heading>
          <Text fontSize="12px" lineHeight="26px" color="#585858">
            Leave up to
            <Field
              as={Select}
              display={'inline-block'}
              w="60px"
              h="28px"
              mx={1}
              size="xs"
              name="overwhelmThreshold"
              fontSize="12px"
              borderColor="black"
              borderRadius={'8px'}
              _hover={{ bg: '#E3E3E3' }}
            >
              <option key="0" value="0">
                0 &nbsp; (recommended)
              </option>
              <option key="6" value="6">
                6
              </option>
              {isDeveloper && (
                <option key="8" value="8">
                  8 (developer only)
                </option>
              )}
              <option key="12" value="12">
                12
              </option>
              <option key="18" value="18">
                18
              </option>
            </Field>
            most recently used tabs open in each browser window, plus any pinned tabs, regardless of
            length of inactivity.
          </Text>
          <Divider my="24px" />
          <Wrap mb="4px" w="100%" align={'center'}>
            <Heading as="h3" fontSize="14px" fontWeight={500}>
              Adaptive Automatic Save <Icon as={HiSparkles} />
            </Heading>
            <Spacer />
            <Flex align="center">
              <Text
                fontSize="12px"
                lineHeight="16px"
                fontWeight={500}
                color="blue"
                mr={2}
                minWidth={'225px'}
              >
                Coming soon - Help shape this feature!
              </Text>
              <Button
                as="a"
                href={FEEDBACK_FORM_URL}
                target="_blank"
                bg="#0071E3"
                color="white"
                _hover={{ bg: '#0071E3', color: 'white' }}
                borderRadius="100px"
                height="28px"
                alignItems="center"
                minWidth={'90px'}
                p="2px 10px 2px 10px"
                gap="6px"
              >
                <Text fontSize="12px" fontWeight="500" whiteSpace="nowrap">
                  Share ideas
                </Text>
              </Button>
            </Flex>
          </Wrap>
          <Text fontSize="12px" lineHeight="16px" color="#585858">
            Using AI, intelligently adjust Automatic Save to be personalized to your unique way of
            browsing and your specific active projects.
          </Text>
        </VStack>
        <Flex flexDirection="column" alignItems="flex-end">
          <Button
            mt="24px"
            borderRadius="100px"
            bg="black"
            color="white"
            px="20px"
            isDisabled={buttonState !== ButtonState.Enabled}
            _hover={{ bg: buttonState !== ButtonState.Enabled ? '#000000' : '#585858' }}
            _disabled={{ bg: buttonState === ButtonState.Saved ? '#000000' : '#D5D5D5' }}
            type="submit"
          >
            {buttonState === ButtonState.Saved ? 'Saved!' : 'Save'}
          </Button>
        </Flex>
      </form>
    </FormikProvider>
  )

  return (
    <Box
      p="24px"
      width="100%"
      height="100%"
      overflow="hidden"
      display="flex"
      flexDirection="column"
    >
      <Box bg="#F6F6F6" borderRadius="16px" p="24px">
        <Heading as="h2" fontSize="16px" mb="24px" fontWeight={600}>
          Automatic Save
        </Heading>
        <Text fontSize="12px" lineHeight="16px">
          Skeema automatically saves and closes browser tabs that you are not actively using, in
          order to keep your tab bar clean and let you focus on the tabs you need right now. Each
          tab that is automatically saved by Skeema is listed in the Skeema saved tabs list.{' '}
        </Text>
        <Text fontSize="12px" lineHeight="16px" mt="16px">
          Use the settings below to adjust default limits if needed.
        </Text>
        <Divider my="24px" />
        {form}
      </Box>
    </Box>
  )
}

export default SettingsMainContent
