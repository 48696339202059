import React, { FC, useCallback, useEffect, useRef } from 'react'
import { MdCancel, MdSearch } from 'react-icons/md'
import { css, styled } from 'styled-components'
import { useReduxDispatch, useReduxSelector } from '../../../redux/baseStore'
import { useUserContext } from '../../../contexts/UserContext'
import {
  clearSearchQuery,
  moveSearchFocusedIdxDown,
  moveSearchFocusedIdxUp,
  updateSearchQuery,
} from '../../../redux/searchSlice'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { TABLIST_PAGE_ENUM } from '../../../models/tablist_pages.types'
import { sendMessageToExtension } from '../../../webapp/utils/externalMessaging'
import { isMac, shouldOpenInNewTab } from '../../../utils/utils'
import {
  selectAppFilterOptions,
  selectFocusedSearchResult,
} from '../../../redux/utils/advancedSelectors'
import { useLocation } from 'react-router-dom'

const SearchBarContainer = styled.div<{
  $isFilled: boolean
}>`
  position: relative;
  display: flex;

  background: white;
  box-shadow: none;
  border: 1px solid #d5d5d5;
  border-radius: 40px;

  width: 100%;
  height: 44px;
  margin-bottom: 16px;
  transition: all 0.25s ease-out 0s;

  svg {
    color: #a7a7a7;
    transition: all 0.25s ease-out 0s;
  }

  .shortcut-icons {
    opacity: 1;
    transition: opacity 0.25s ease-out 0s;
  }

  &:focus-within {
    border: 1px solid #0071e3;
    box-shadow: 0px 0px 0px 6px rgba(0, 113, 227, 0.25);

    .shortcut-icons {
      opacity: 0;
    }

    svg {
      color: #585858;
    }
  }

  ${({ $isFilled }) =>
    $isFilled
      ? css`
          .shortcut-icons {
            opacity: 0;
          }

          &:focus-within {
            border: 1px solid #a7a7a7;
            box-shadow: none;
          }
        `
      : ''};
`

const SearchInput = styled.input<{
  $isFilled: boolean
}>`
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  color: #585858;
  caret-color: #585858;
  word-wrap: break-word;
  outline: none;
  display: flex;
  flex: 100%;
  -webkit-tap-highlight-color: transparent;
  margin-top: -37px;
  height: 34px;
  font-size: 16px;
  font-weight: 500;

  &::placeholder {
    color: #a7a7a7;
  }

  ${({ $isFilled }) =>
    $isFilled
      ? css`
          &:focus {
            color: #000;
          }
        `
      : ''};
`

const KeyIcon = styled.span`
  font-size: 10px;
  font-weight: 600;
  background-color: #d5d5d5;
  color: white;
  border-radius: 4px;
  margin-right: 2px;
  padding: 4px 7px;
`

const cmdOrCtrl = isMac() ? 'Cmd' : 'Ctrl'

const SearchBar: FC = () => {
  const { captureAnalytics } = useUserContext()
  const { pathname } = useLocation()

  const queryValue = useReduxSelector((state) => state.search.query)
  const numSearchResults = useReduxSelector((state) => state.search.numResults)
  const searchFilters = useReduxSelector((state) => state.search.filters)
  const appFilterOptions = useReduxSelector(selectAppFilterOptions)

  const focusedPage = useReduxSelector(selectFocusedSearchResult)
  const dispatch = useReduxDispatch()
  const searchInputRef = useRef<HTMLInputElement | null>(null)

  const handleClickSearchContainer = useCallback(() => {
    captureAnalytics('tablist:search_container_click', {
      queryValue,
      numTotalResults: numSearchResults ?? 0,
    })
    searchInputRef.current?.focus()
  }, [captureAnalytics, numSearchResults, queryValue])

  const handleClickClearSearch = useCallback(() => {
    captureAnalytics('tablist:search_clear_click', {
      queryValue,
      numTotalResults: numSearchResults ?? 0,
    })
    dispatch(clearSearchQuery())
  }, [captureAnalytics, dispatch, numSearchResults, queryValue])

  const handleSearchPressEscape = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement> | KeyboardEvent, isFocusedOnInput: boolean) => {
      if (!(event instanceof KeyboardEvent)) {
        event.currentTarget.blur()
      }

      captureAnalytics('tablist:search_escape_press', {
        queryValue,
        numTotalResults: numSearchResults ?? 0,
        isFocusedOnInput,
      })
      dispatch(clearSearchQuery())
    },
    [captureAnalytics, dispatch, numSearchResults, queryValue],
  )

  const handleSearchInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // Note 5/22/24: Query value change is logged in the tablist
      dispatch(updateSearchQuery(event.target.value))
    },
    [dispatch],
  )

  const handleSearchPressEnter = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      event.preventDefault()
      if (focusedPage === null) {
        return
      }

      if (focusedPage.entity_type === TABLIST_PAGE_ENUM.OPEN) {
        sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.SWITCH_TO_TAB, {
          tabId: parseInt(focusedPage.id),
          windowId: focusedPage.window_id,
        })
      } else {
        sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.OPEN_TABS, {
          urls: [focusedPage.url],
          shouldActivate: !shouldOpenInNewTab(event),
        })
      }

      captureAnalytics('tablist:search_enter_press', {
        queryValue,
        focusedPage,
        numTotalResults: numSearchResults ?? 0,
      })
    },
    [captureAnalytics, focusedPage, numSearchResults, queryValue],
  )

  const handleSearchPressDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      event.preventDefault()
      dispatch(moveSearchFocusedIdxDown())
      captureAnalytics('tablist:search_down_press', {
        queryValue,
        searchFilters,
        appFilterOptions,
        numTotalResults: numSearchResults ?? 0,
      })
    },
    [appFilterOptions, captureAnalytics, dispatch, numSearchResults, queryValue, searchFilters],
  )

  const handleSearchPressUp = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      event.preventDefault()
      dispatch(moveSearchFocusedIdxUp())
      captureAnalytics('tablist:search_up_press', {
        queryValue,
        searchFilters,
        appFilterOptions,
        numTotalResults: numSearchResults ?? 0,
      })
    },
    [appFilterOptions, captureAnalytics, dispatch, numSearchResults, queryValue, searchFilters],
  )

  const handleSearchKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.code === 'Escape') {
        handleSearchPressEscape(event, true)
      } else if (event.code === 'Enter') {
        handleSearchPressEnter(event)
      } else if (event.code === 'ArrowDown') {
        handleSearchPressDown(event)
      } else if (event.code === 'ArrowUp') {
        handleSearchPressUp(event)
      }
    },
    [handleSearchPressDown, handleSearchPressEnter, handleSearchPressEscape, handleSearchPressUp],
  )

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'f') {
        event.preventDefault()
        searchInputRef.current?.focus()
        captureAnalytics('tablist:search_cmd_f_press')
      } else if (event.code === 'Escape') {
        handleSearchPressEscape(event, false)
      }
    }

    const handleVisibilityChange = () => {
      if (window.document.hidden) {
        dispatch(clearSearchQuery())
      } else {
        searchInputRef.current?.focus()
      }
    }
    window.document.addEventListener('keydown', handleKeyDown)
    window.document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      window.document.removeEventListener('keydown', handleKeyDown)
      window.document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [captureAnalytics, dispatch, handleSearchPressEscape])

  const queryValueRef = useRef<string>(queryValue)
  useEffect(() => {
    queryValueRef.current = queryValue
  }, [queryValue])

  useEffect(() => {
    if (queryValueRef.current !== '') {
      captureAnalytics('tablist:search_url_path_change', {
        pathname,
        queryValue: queryValueRef.current,
      })

      dispatch(clearSearchQuery())
    }
  }, [captureAnalytics, dispatch, pathname])

  return (
    <div style={{ padding: '0 32px' }}>
      <SearchBarContainer $isFilled={queryValue !== ''} onClick={handleClickSearchContainer}>
        <div style={{ flex: 1, display: 'flex', padding: '5px 8px 0 14px' }}>
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingRight: '1em',
              marginTop: '-5px',
            }}
          >
            <MdSearch style={{ width: '20px', height: '20px' }} />
          </span>
          <div style={{ display: 'flex', flex: 1, flexWrap: 'wrap' }}>
            <div style={{ flex: '100%', height: '34px' }} />
            <SearchInput
              $isFilled={queryValue !== ''}
              ref={searchInputRef}
              autoFocus
              type="text"
              placeholder="Search"
              value={queryValue}
              onKeyDown={handleSearchKeyDown}
              onChange={handleSearchInputChange}
            />
          </div>
          <span
            style={{
              width: '20px',
              flex: '0 0 auto',
              marginRight: '5px',
              marginTop: '-5px',
              color: '#D9D9D9',
            }}
          >
            {queryValue !== '' && (
              <MdCancel
                className="fill-container"
                style={{ cursor: 'pointer' }}
                onClick={handleClickClearSearch}
              />
            )}
          </span>
          <span
            className="shortcut-icons"
            style={{
              position: 'absolute',
              flex: '0 0 auto',
              right: '20px',
              top: '7px',
              pointerEvents: 'none',
            }}
          >
            <KeyIcon>{cmdOrCtrl}</KeyIcon>
            <KeyIcon>F</KeyIcon>
          </span>
        </div>
      </SearchBarContainer>
    </div>
  )
}

export default SearchBar
