import React, { FC } from 'react'
import { WEBAPP_BASE_URL } from '../constants'
import { Box, Heading, Text } from '@chakra-ui/react'

interface Props {
  heading: string
  content: string[]
  headingMaxWidth?: string
  widget?: React.ReactNode
  subtext?: string
  linkText?: string
  linkHref?: string
}

const AnnouncementsPage: FC<Props> = (props) => {
  const { heading, content, headingMaxWidth, widget, subtext, linkText, linkHref } = props
  return (
    <Box mx={['20px', '160px']}>
      <Box mt="40px" mb="160px">
        <a href={WEBAPP_BASE_URL}>
          <img src="/logo-wordmark.svg" alt="Skeema logo" style={{ maxHeight: '35px' }} />
        </a>
      </Box>
      <Heading
        size="3xl"
        maxWidth={headingMaxWidth ?? '360px'}
        fontWeight="500"
        style={{ wordWrap: 'normal' }}
      >
        {heading}
      </Heading>
      <Box mt="40px" mb="80px">
        {content.map((paragraph, i) => (
          <Text key={i} fontSize="lg" mb="20px" maxWidth="620px">
            {paragraph}
          </Text>
        ))}
      </Box>
      {widget && <Box>{widget}</Box>}
      <Box mt="20px">
        {subtext && <Text fontSize="sm">{subtext}</Text>}
        {linkText && linkHref && (
          <Text as="u" fontSize="sm">
            <a href={linkHref} target="_blank" rel="noopener noreferrer">
              {linkText}
            </a>
          </Text>
        )}
      </Box>
    </Box>
  )
}

export default AnnouncementsPage
