import { createSelector } from '@reduxjs/toolkit'
import { selectOpenTabs } from '../../webapp/redux/extension'
import { selectMostVisitedPages } from '../services/skeema/tablist_pages.endpoints'
import { getAppFilterOptions } from '../../utils/componentUtils'
import Fuse from 'fuse.js'
import {
  selectBackendSearchResults,
  selectIsSearchFilteringActive,
  selectSearchFilters,
  selectSearchFocusedIdx,
  selectSearchQueryValue,
} from '../searchSlice'
import { TABLIST_PAGE_ENUM, TablistPageSearchResultType } from '../../models/tablist_pages.types'
import { getAppName } from '../../utils/urlUtils'
import { selectTablistSavedPages } from '../tablistSlice'

//NOTE: selectAllTablistPages uses the store saved in the tablist slice, rather than the query cache
//      use selectPutasidePages for the query cache
const selectAllTablistPages = createSelector(
  [selectOpenTabs, selectMostVisitedPages, selectTablistSavedPages],
  (openPages, mostVisitedPages, savedPages) => {
    return [...(openPages ?? []), ...(mostVisitedPages ?? []), ...(savedPages ?? [])]
      .map((page) => {
        return {
          ...page,
          app_name: getAppName(page.url),
        }
      })
      .sort((a, b) => b.last_access_timestamp_ms - a.last_access_timestamp_ms)
  },
)

export const selectAppFilterOptions = createSelector(selectAllTablistPages, (allPages) => {
  const appFilterOptions = getAppFilterOptions(allPages)
  return appFilterOptions
})

const selectFuseTablistPagesInstance = createSelector(selectAllTablistPages, (allPages) => {
  const searchCandidatePages = allPages.filter((page, index) => {
    return allPages.findIndex((p) => p.url === page.url) === index
  })

  const fuse = new Fuse(searchCandidatePages, {
    isCaseSensitive: false,
    includeMatches: true,
    minMatchCharLength: 2,
    shouldSort: false,
    ignoreLocation: true,
    ignoreFieldNorm: true,
    useExtendedSearch: true,
    keys: ['title', 'url'],
  })

  return fuse
})

const selectRecentTablistSearchResults = createSelector(
  [selectSearchQueryValue, selectFuseTablistPagesInstance],
  (queryValue, fuse) => {
    const trimmedQuery = queryValue.trim()

    if (trimmedQuery === '') {
      return null
    }

    const results = fuse.search("'" + trimmedQuery) // prefixing with ' to force exact match according to https://www.fusejs.io/examples.html#extended-search

    const resultsWithMetadata = results.map((r) => {
      const titleMatchIndices = r.matches
        ?.find((m) => m.key === 'title')
        ?.indices.filter((range) => {
          const matchLength = range[1] - range[0] + 1
          return matchLength > 1 && matchLength >= trimmedQuery.length
        }) as [number, number][] | undefined

      const searchResult: TablistPageSearchResultType = {
        ...r.item,
        title_highlights: titleMatchIndices,
      }
      return searchResult
    })

    return resultsWithMetadata
  },
)

export const selectTablistSearchResults = createSelector(
  [selectRecentTablistSearchResults, selectBackendSearchResults],
  (recentSearchResults, backendSearchResults) => {
    if (!recentSearchResults) {
      return null
    }
    if (!backendSearchResults) {
      return recentSearchResults
    }

    return [...recentSearchResults, ...backendSearchResults].sort((a, b) => {
      return b.last_access_timestamp_ms - a.last_access_timestamp_ms
    })
  },
)

export const selectTablistFilteredSearchResults = createSelector(
  [
    selectTablistSearchResults,
    selectSearchFilters,
    selectIsSearchFilteringActive,
    selectAllTablistPages,
  ],
  (searchResults, searchFilters, isFilteringActive, allPages) => {
    const { isToRead, isManual, app } = searchFilters
    let results = searchResults

    if (!isFilteringActive) {
      //Default return search results
      return results
    }

    if (!results) {
      //If no search results, filter based on all pages
      results = allPages
    }

    if (isToRead) {
      results = results.filter((r) => r.is_toread)
    }
    if (app !== null) {
      results = results.filter((r) => r.app_name === app.title)
    }
    if (isManual) {
      results = results.filter((r) => r.is_manual)
    }

    return results
  },
)

export const selectFocusedSearchResult = createSelector(
  [selectTablistFilteredSearchResults, selectSearchFocusedIdx],
  (searchResults, focusedIdx) => {
    if (
      !searchResults ||
      focusedIdx === null ||
      focusedIdx < 0 ||
      focusedIdx >= searchResults.length
    ) {
      return null
    }
    return searchResults[focusedIdx]
  },
)

/////////////////////////////////////// For omnisearch ///////////////////////////////////////

const selectOpenFuseTablistPagesInstance = createSelector([selectOpenTabs], (openPages) => {
  const searchCandidatePages = (openPages ?? []).map((p) => {
    return {
      ...p,
      entity_type: TABLIST_PAGE_ENUM.OPEN,
      location: 'Using now',
    }
  })

  const fuse = new Fuse(searchCandidatePages, {
    isCaseSensitive: false,
    includeMatches: true,
    threshold: 0.3,
    minMatchCharLength: 2,
    shouldSort: true,
    ignoreLocation: true,
    ignoreFieldNorm: true,
    keys: ['title', 'url'],
  })

  return fuse
})

const selectOpenTabSearchResults = createSelector(
  [selectSearchQueryValue, selectOpenFuseTablistPagesInstance],
  (queryValue, fuse) => {
    const trimmedQuery = queryValue.trim()

    if (trimmedQuery === '') {
      return null
    }

    const results = fuse.search("'" + trimmedQuery) // prefixing with ' to force exact match according to https://www.fusejs.io/examples.html#extended-search

    const resultsWithMetadata = results.map((r) => {
      const titleMatchIndices = r.matches
        ?.find((m) => m.key === 'title')
        ?.indices.filter((range) => {
          const matchLength = range[1] - range[0] + 1
          return matchLength > 1 && matchLength >= trimmedQuery.length
        }) as [number, number][] | undefined

      const searchResult: TablistPageSearchResultType = {
        ...r.item,
        title_highlights: titleMatchIndices,
      }
      return searchResult
    })

    return resultsWithMetadata
  },
)

export const selectOmnisearchResults = createSelector(
  [selectOpenTabSearchResults, selectBackendSearchResults],
  (openTabSearchResults, backendSearchResults) => {
    if (!openTabSearchResults) {
      return null
    }
    if (!backendSearchResults) {
      return openTabSearchResults
    }

    return [...openTabSearchResults, ...backendSearchResults]
  },
)
