import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { MdClose } from 'react-icons/md'
import { AiOutlineChrome } from 'react-icons/ai'
import { IoFilter } from 'react-icons/io5'
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import debounce from 'lodash.debounce'
import { useUserContext } from '../contexts/UserContext'
import Portal from '../widgets/Portal'
import { AppFilterOptionType } from '../redux/searchSlice'

const MyMenuButton = styled.button<{ $isFilterActive: boolean; $isMenuOpen: boolean }>`
  all: unset;

  position: relative;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ $isFilterActive }) => ($isFilterActive ? '#000' : '#d5d5d5')};
  color: ${({ $isFilterActive }) => ($isFilterActive ? '#fff' : '#a7a7a7')};
  background-color: ${({ $isFilterActive }) => ($isFilterActive ? '#000' : '#fff')};
  transition: all 0.2s ease-in-out;

  cursor: pointer;

  ${({ $isMenuOpen, $isFilterActive }) =>
    $isMenuOpen && !$isFilterActive
      ? css`
          color: #585858;
          border-color: #585858;
        `
      : ''};

  ${({ $isMenuOpen }) =>
    $isMenuOpen
      ? css`
          & .hover-bridge {
            pointer-events: auto;
            height: 16px;
            width: 100%;
            position: absolute;
            left: 0;
            top: 24px;
          }
        `
      : ''};

  &:hover {
    ${({ $isFilterActive }) =>
      $isFilterActive
        ? ''
        : css`
            color: #585858;
            border-color: #585858;
          `};
  }

  //Chakra UI seems to add this span wrapper around the children
  & > span {
    display: flex;

    & > * {
      pointer-events: none;
    }

    & > .main-icon {
      width: 16px;
      height: 16px;
      margin-right: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      & > svg {
        width: 100%;
        height: 100%;
      }
    }

    & > .app-icon {
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      margin-right: 0px;
      width: 0px;
      transition:
        width 0.2s ease-in-out,
        margin-left 0.2s ease-in-out;

      ${({ $isFilterActive }) =>
        $isFilterActive
          ? css`
              width: 16px;
              margin-right: 4px;
            `
          : ''};

      & > img svg {
        width: 100%;
        height: 100%;
      }
    }

    & > .close-icon {
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      margin-left: 0px;
      width: 0px;
      transition:
        width 0.2s ease-in-out,
        margin-left 0.2s ease-in-out;

      ${({ $isFilterActive }) =>
        $isFilterActive
          ? css`
              width: 16px;
              margin-left: 6px;
            `
          : ''};

      & > svg {
        width: 100%;
        height: 100%;
      }
    }

    & > .label {
      font-size: 12px;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      /* ${({ $isFilterActive }) =>
        $isFilterActive
          ? css`
              display: none;
            `
          : ''}; */
    }
  }
`

const MENU_ITEM_HEIGHT = 28
const NUM_MENU_ITEMS = 10

const StyledMenuList = styled(MenuList).attrs({
  className: 'scrollbars-always-visible',
})`
  /* https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity */
  &&& {
    min-width: 160px;
    width: 160px;
    max-height: ${MENU_ITEM_HEIGHT * NUM_MENU_ITEMS}px;
    border: 1px solid #585858;
    border-radius: 8px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
    overflow-y: overlay;
    padding: 0;
  }
`

const StyledMenuItem = styled(MenuItem)`
  &&& {
    display: flex;
    align-items: center;

    width: 100%;
    height: ${MENU_ITEM_HEIGHT}px;
    padding: 0 8px;
    margin: 2px;
    border-radius: 6px;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;

    & > .item-icon {
      min-width: 16px;
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 6px;

      & > img svg {
        width: 100%;
        height: 100%;
      }
    }

    & > .label {
      font-size: 12px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      background: hsl(0, 0%, 95%);
    }
  }
`

interface Props {
  menuOptions: AppFilterOptionType[]
  selection: AppFilterOptionType | null
  onSelectionChange: (newSelection: AppFilterOptionType | null) => void
}

const AppsFilterButton: FC<Props> = (props) => {
  const { menuOptions, selection, onSelectionChange } = props
  const { captureAnalytics } = useUserContext()
  const menuListRef = useRef<HTMLDivElement | undefined>(undefined)
  const [isMenuHovered, setIsMenuHovered] = useState<boolean>(false)
  const isMenuHoveredRef = useRef<boolean>(isMenuHovered)
  // const [isMenuLockedOpen, setIsMenuLockedOpen] = useState<boolean>(false)
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const isFilterActive = selection !== null

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLogOpened = useCallback(
    debounce(() => captureAnalytics('tablist:app_filter_menu_opened'), 2000, {
      leading: true,
      trailing: false,
      maxWait: 2000,
    }),
    [captureAnalytics],
  )

  const handleMenuHovered = useCallback((isHovered: boolean) => {
    isMenuHoveredRef.current = isHovered
    setIsMenuHovered(isHovered)
  }, [])

  const handleMenuOpen = useCallback(
    (isOpen: boolean) => {
      setIsMenuOpen(isOpen)
      if (isOpen) {
        debouncedLogOpened()
      }
    },
    [debouncedLogOpened],
  )

  const closeMenu = () => {
    handleMenuHovered(false)
    handleMenuOpen(false)
  }

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined = undefined
    if (isMenuHovered) {
      timeout = setTimeout(() => {
        handleMenuOpen(isMenuHoveredRef.current)
      }, 300)
    } else {
      timeout = setTimeout(() => {
        handleMenuOpen(isMenuHoveredRef.current)
      }, 500)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [isMenuHovered, handleMenuOpen])

  useEffect(() => {
    menuListRef.current?.scrollTo(0, 1)
    menuListRef.current?.scrollTo(0, 0)
  }, [isMenuOpen])

  return (
    <span
      onMouseEnter={() => {
        handleMenuHovered(true)
      }}
      onMouseLeave={() => {
        handleMenuHovered(false)
      }}
    >
      <Menu isOpen={isMenuOpen} gutter={2}>
        <MenuButton
          as={MyMenuButton}
          $isFilterActive={isFilterActive}
          $isMenuOpen={isMenuOpen}
          onClick={() => {
            if (isFilterActive) {
              onSelectionChange(null)
            }
          }}
        >
          <span className="main-icon">
            <IoFilter />
          </span>
          <label className="label">{isFilterActive ? selection.title : 'Websites'}</label>
          <span className="close-icon">
            <MdClose />
          </span>

          <span className="hover-bridge" />
        </MenuButton>

        <Portal>
          <StyledMenuList ref={menuListRef}>
            {menuOptions.map((option) => {
              return (
                <StyledMenuItem
                  key={option.title}
                  onClick={() => {
                    onSelectionChange(option)
                    closeMenu()
                  }}
                >
                  <span className="item-icon">
                    {option.favIconUrl ? <img src={option.favIconUrl} /> : <AiOutlineChrome />}
                  </span>
                  <label className="label">{option.title}</label>
                </StyledMenuItem>
              )
            })}
          </StyledMenuList>
        </Portal>
      </Menu>
    </span>
  )
}

export default AppsFilterButton
