import React, { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Collapse, Box, ToastId, UseToastOptions, useToast } from '@chakra-ui/react'
import { FiChevronDown } from 'react-icons/fi'
import PutasideTabController from './PutasideTabController'
import { TablistPageType } from '../../../models/tablist_pages.types'
import { styled } from 'styled-components'
import FavIconList, { FavIconListItemType } from '../FavIconList'
import { useUserContext } from '../../../contexts/UserContext'
import { useReduxSelector } from '../../../redux/baseStore'
import { selectSearchQueryValue } from '../../../redux/searchSlice'
import { useGetTablistPagesQuery } from '../../../redux/services/skeema/tablist_pages.endpoints'
import { FEATURE_FLAG_KEYS_ENUM } from '../../../models/feature_flags.types'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import InstantSaveToast from '../../morningPageV2/instantSave/InstantSaveToast'
import { cx, css } from '@emotion/css'
import SmartSessionDashboard from '../SmartSessionDashboard'
import SearchResultsReplacementWrapper from '../search/SearchResultsReplacementWrapper'

export interface SelectedTablistPageType {
  [id: string]: TablistPageType
}

export enum TABLIST_AREA_NAME_ENUM {
  MostUsed = 'most_used_tabs',
  Open = 'open_tabs',
  RecentlySaved = 'other_recently_used_tabs',
  SearchResults = 'search_results',
  SmartSessions = 'smart_sessions',
  FeedHistory = 'feed_history',
}

const SectionContainer = styled.div`
  margin-bottom: 16px;
`

const TableHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-left: 16px;
`

const TableHeaderCell = styled.div<{ $isClickable?: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'default')};

  h2 {
    font-size: 14px;
    font-weight: 500;
    color: #585858;
    line-height: 22px; /* 157.143% */
  }
`

const CollapseIcon = styled.span<{ $isCollapsed: boolean }>`
  height: 16px;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 1.2em;
  font-weight: bold;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 8px;
  width: 16px;
  color: black;

  & > svg {
    display: flex;
    transform: ${({ $isCollapsed }) => `rotate(${$isCollapsed ? -90 : 0}deg)`};
    transition: transform 0.5s;
    transform-origin: 50% 50%;
  }
`

const onboardingPrepAnimationClassName = css`
  .using-now-header {
    transition: opacity 500ms ease-in-out 500ms;
    opacity: 0;
  }
  .saved-header {
    transition: opacity 500ms ease-in-out 1.5s;
    opacity: 0;
  }
  .most-used-section {
    transition: max-height 2600ms ease-in-out 2.5s;
    max-height: 0;
    overflow: hidden;
  }
`

const onboardingAnimationClassName = css`
  .using-now-header {
    opacity: 1;
  }
  .saved-header {
    opacity: 1;
  }
  .most-used-section {
    max-height: 500px;
  }
`

const TOAST_DURATION_MS = 8000
const USE_TOAST_OPTIONS: UseToastOptions = {
  position: 'bottom',
  containerStyle: {
    maxWidth: 'none',
    margin: 0,
  },
}

interface Props {
  isOnboardingTutorialEnabled?: boolean
}

const PutasideTabList: FC<Props> = (props) => {
  const { isOnboardingTutorialEnabled = false } = props
  const { captureAnalytics } = useUserContext()
  const { featureFlags } = useFeatureFlagContext()
  const navigate = useNavigate()

  const toast = useToast(USE_TOAST_OPTIONS)
  const toastIdRef = React.useRef<ToastId | undefined>(undefined)

  const IsPaywallEnabled = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsPaywallEnabled]

  const instantSaveQuota = useReduxSelector((state) => state.user.instantSaveQuota)
  const queryValue = useReduxSelector(selectSearchQueryValue)

  const isSearchingActive = queryValue.trim().length > 0
  const isResultsModeEnabled = isSearchingActive

  const [isMostUsedPagesCollapsed, setIsMostUsedPagesCollapsed] = useState<boolean>(
    !isOnboardingTutorialEnabled,
  )

  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false)
  useEffect(() => {
    setTimeout(() => {
      setShouldAnimate(isOnboardingTutorialEnabled)
    }, 500)
  }, [isOnboardingTutorialEnabled])

  const { data: tablistPagesQueryResult } = useGetTablistPagesQuery(undefined)
  const mostVisitedPages = tablistPagesQueryResult?.most_visited_pages

  const handleOnClickUpgrade = useCallback(() => {
    captureAnalytics('instant_save_toast:upgrade_click')
    navigate('/#pricing')
  }, [captureAnalytics, navigate])

  useEffect(() => {
    // Update the toast if the instant save quota changes
    if (IsPaywallEnabled && toastIdRef.current) {
      const toastParams = {
        duration: TOAST_DURATION_MS,
        render: () => (
          <InstantSaveToast
            instantSaveQuota={instantSaveQuota}
            onClickUpgrade={handleOnClickUpgrade}
          />
        ),
      }
      toast.update(toastIdRef.current, toastParams)
    }
  }, [IsPaywallEnabled, handleOnClickUpgrade, instantSaveQuota, toast])

  return (
    <Box
      className={cx({
        [onboardingPrepAnimationClassName]: isOnboardingTutorialEnabled,
        [onboardingAnimationClassName]: shouldAnimate,
      })}
      width="100%"
      height="100%"
      overflow="hidden"
      display="flex"
      flexDirection="column"
    >
      <Box p="24px 8px 24px 16px">
        <SearchResultsReplacementWrapper>
          <Collapse in={!isResultsModeEnabled} animateOpacity>
            {mostVisitedPages && mostVisitedPages.length > 0 && (
              <SectionContainer className="most-used-section">
                <TableHeaderContainer>
                  <TableHeaderCell
                    onClick={() => setIsMostUsedPagesCollapsed(!isMostUsedPagesCollapsed)}
                    $isClickable={true}
                  >
                    <h2>Most used tabs</h2>
                    <CollapseIcon $isCollapsed={!!isMostUsedPagesCollapsed}>
                      <FiChevronDown />
                    </CollapseIcon>
                  </TableHeaderCell>
                </TableHeaderContainer>

                <span
                  style={{
                    flex: '1 2 auto',
                    display: isMostUsedPagesCollapsed ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    overflow: 'auto',
                    minWidth: '24px',
                    maxWidth: '100%',
                    padding: '0 10px',
                  }}
                >
                  <FavIconList
                    items={mostVisitedPages.map((t) => {
                      return {
                        id: t.id,
                        url: t.url,
                        title: t.title,
                        faviconUrl: t.favicon_url,
                      } as FavIconListItemType
                    })}
                    size="md"
                    showTitleTooltip={true}
                    isClickable={true}
                    areaName={TABLIST_AREA_NAME_ENUM.MostUsed}
                  />
                </span>

                <Collapse in={!isMostUsedPagesCollapsed} animateOpacity>
                  <div>
                    {mostVisitedPages?.map((t, idx) => {
                      return (
                        <PutasideTabController
                          key={t.id}
                          id={t.id}
                          page={t}
                          showTimeString={false}
                          queryValue={queryValue}
                          index={idx}
                          numTotalResults={mostVisitedPages.length}
                          areaName={TABLIST_AREA_NAME_ENUM.MostUsed}
                          isDraggingDisabled={false}
                        />
                      )
                    })}
                  </div>
                </Collapse>
              </SectionContainer>
            )}
          </Collapse>

          <Collapse in={!isResultsModeEnabled} animateOpacity>
            <SectionContainer className="smart-session-section">
              <TableHeaderContainer>
                <TableHeaderCell>
                  <h2>Smart Sessions</h2>
                </TableHeaderCell>
              </TableHeaderContainer>

              <div>
                <SmartSessionDashboard isOnboardingTutorialEnabled={isOnboardingTutorialEnabled} />
              </div>
            </SectionContainer>
          </Collapse>
        </SearchResultsReplacementWrapper>
      </Box>
    </Box>
  )
}

export default PutasideTabList
