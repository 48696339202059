import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { Text, Flex, Box, Grid, GridItem, Spinner, Img, Link } from '@chakra-ui/react'
import { useGetProjectPagesQuery } from '../../redux/services/skeema/projects.endpoints'
import ProjectDetailSharedPageController from './projectDetail/ProjectDetailSharedPageController'
import ProjectDetailTitle from './projectDetail/ProjectDetailTitle'
import { styled } from 'styled-components'
import { ProjectType } from '../../models/saved_sessions.types'
import { useExtensionHealthContext } from '../../contexts/ExtensionHealthContext'
import { sendMessageToExtension } from '../../webapp/utils/externalMessaging'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../extension/models/messaging.types'
import { FiChevronRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from '../../contexts/UserContext'
import StaticBanner from '../../components/StaticBanner'
import { useAppendTablistPagesMutation } from '../../redux/services/skeema/tablist_pages.endpoints'
import { TABLIST_PAGE_ENUM, TablistPageType } from '../../models/tablist_pages.types'
import { WEBAPP_BASE_URL } from '../../constants'

const CardContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #f6f6f6;
  border-radius: 16px;
  padding: 24px 24px 24px 10px;
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  &:not(:has(.ProjectPageContainer:hover)):hover,
  &.force-hover {
    .hover-show-title-icons {
      opacity: 1;
    }
  }
`

const BodyContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`

const EmptyLabelContainer = styled.div`
  width: 100%;
  text-align: center;
  align-items: center;
  overflow: hidden;

  p {
    color: #a7a7a7;
    margin-left: 16px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const PagesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 6px;
  padding-bottom: 1px; //Fixes dnd indicator line cut off on the bottom of the last page
`

interface Props {
  project: ProjectType
}

const SharedProjectPage: FC<Props> = (props) => {
  const { project } = props
  const navigate = useNavigate()
  const { captureAnalytics, userInfo } = useUserContext()
  const isExtensionInstalled = !!useExtensionHealthContext().extensionHealth
  const { data: pages, isLoading: isPagesLoading } = useGetProjectPagesQuery({
    projectId: String(project.id),
  })
  const urls = useMemo(() => pages?.map((page) => page.url), [pages])

  const [appendTablistPages] = useAppendTablistPagesMutation()

  useEffect(() => {
    if (isExtensionInstalled) {
      sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.REPLACE_PINNED_TAB)
    }
  }, [isExtensionInstalled])

  useEffect(() => {
    window.document.title = `${project.title.trim() || 'Untitled Project'} - Skeema`
  }, [project.title])

  const onBannerSignUpClick = useCallback(() => {
    window.open(
      `https://www.skeema.com/focus?utm_source=skeema_app&utm_medium=shared_project&utm_campaign=shared_project`,
    )
    captureAnalytics('shared_project_page:banner_sign_up_click', {
      ...project,
    })
  }, [captureAnalytics, project])

  const onBannerSaveClick = useCallback(async () => {
    const page: TablistPageType = {
      id: '0',
      title: window.document.title,
      url: window.document.location.href,
      favicon_url: `${WEBAPP_BASE_URL}/favicon.ico`,
      entity_type: TABLIST_PAGE_ENUM.OPEN,
      last_access_timestamp_ms: Date.now(),
      last_access_datetime_utc_iso: new Date().toISOString(),
    }

    await appendTablistPages({ page, isManual: true })
      .unwrap()
      .then(() => {
        navigate('/saved')
      })
      .catch((error) => {
        console.error(error)
      })

    captureAnalytics('shared_project_page:banner_save_click', {
      ...project,
      url: page.url,
      page,
    })
  }, [appendTablistPages, captureAnalytics, navigate, project])

  return (
    <>
      {userInfo ? (
        <StaticBanner
          text="Need this tab later? Save it to Skeema"
          buttonText="Save"
          buttonOnClick={onBannerSaveClick}
        />
      ) : (
        <StaticBanner
          text="Welcome to Skeema! Sign up to save this project and organize your tabs."
          buttonText="Sign up"
          buttonIcon={<FiChevronRight />}
          buttonOnClick={onBannerSignUpClick}
        />
      )}
      <Flex direction="row" alignItems="center" h="100%" w="100%">
        <Grid
          height="100%"
          width="100%"
          templateColumns="repeat(12, 1fr)"
          templateRows="112px auto"
          flex="1 1 auto"
        >
          <GridItem colSpan={12} gridRow={1} rowSpan={1} display="flex">
            <Box display={'flex'} m="32px 0 0 24px">
              <Link href="/">
                <Img src="/logo-wordmark.svg" alt="Skeema logo" height={'22px'} />
              </Link>
            </Box>
          </GridItem>

          <GridItem
            className="scrollbars"
            colStart={4}
            gridRow={2}
            colSpan={6}
            height="100%"
            overflow="auto"
            minWidth="552px"
            maxWidth="1000px"
          >
            <CardContainer>
              <Box mb="16px" ml="16px">
                <Box mb="8px">
                  <ProjectDetailTitle
                    project={project}
                    urls={urls}
                    isViewOnly={true}
                    isExtensionInstalled={isExtensionInstalled}
                  />
                </Box>
                <Text fontSize="12px" fontWeight={500} lineHeight="16px" color="#A7A7A7">
                  {`by ${project.owner_email}`}
                </Text>
              </Box>
              {isPagesLoading && (
                <div className="flex-center" style={{ width: '100%', marginTop: '32px' }}>
                  <Spinner color="blue.500" size="lg" speed="1s" />
                </div>
              )}

              {!isPagesLoading && !pages && (
                <div className="flex-center" style={{ width: '100%' }}>
                  <Text textAlign="center" margin={4} fontSize="14px" color="#a7a7a7">
                    {`Pages for this project could not be loaded.`}
                  </Text>
                </div>
              )}

              {pages && (
                <BodyContainer className="scrollbars">
                  {pages.length === 0 && (
                    <EmptyLabelContainer>
                      <p>{`This project is empty.`}</p>
                    </EmptyLabelContainer>
                  )}
                  {pages.length > 0 && (
                    <PagesContainer>
                      {pages.map((p) => {
                        return (
                          <ProjectDetailSharedPageController
                            key={p.id}
                            projectId={project.id}
                            page={p}
                            isExtensionInstalled={isExtensionInstalled}
                          />
                        )
                      })}
                    </PagesContainer>
                  )}
                </BodyContainer>
              )}
            </CardContainer>
          </GridItem>
        </Grid>
      </Flex>
    </>
  )
}

export default SharedProjectPage
