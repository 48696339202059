import React, { FC } from 'react'
import { Link, Flex, Box, Grid, GridItem, Img, Button, Heading } from '@chakra-ui/react'
import SettingsMainContent from './SettingsMainContent'
import SubNavButtons from '../morningPageV2/SubNavButtons'
import { useNavigate } from 'react-router-dom'
import AdminSettingsContent from './AdminSettingsContent'
import { useFeatureFlagContext } from '../../contexts/FeatureFlagContext'
import { FEATURE_FLAG_KEYS_ENUM } from '../../models/feature_flags.types'
import { IoMdArrowBack } from 'react-icons/io'

const SettingsPage: FC = () => {
  const navigate = useNavigate()

  const { featureFlags } = useFeatureFlagContext()
  const isDemoAccount = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsDemoAccount]

  return (
    <Flex direction="row" h="100%" w="100%">
      <Box minWidth="320px" w="320px" maxW="320px" h="100%" overflow="hidden">
        <Box display={'flex'} alignItems={'center'} m="36px 24px 76px 24px">
          <Link href="/">
            <Img src="/logo-wordmark.svg" alt="Skeema logo" height={'22px'} />
          </Link>
        </Box>
      </Box>
      <Grid
        templateColumns="repeat(12, 1fr)"
        templateRows="112px auto"
        flex="1 1 auto"
        p="24px 24px 0 24px"
      >
        <GridItem colSpan={8} gridRow={1} minWidth="552px" maxWidth="1000px">
          <Button
            size="sm"
            w="98px"
            h="36px"
            borderRadius="100px"
            p="7px 20px"
            mb="16px"
            bg="transparent"
            color="#585858"
            position={'relative'}
            fontSize="14px"
            fontWeight="500"
            lineHeight="22px"
            _hover={{ bg: 'white' }}
            leftIcon={<IoMdArrowBack />}
            onClick={() => {
              navigate('/')
            }}
          >
            Back
          </Button>
          <Heading fontWeight={600} fontSize="20px" mt="12px">
            Settings
          </Heading>
        </GridItem>

        <GridItem colSpan={4} gridRow={1} minWidth="300px">
          <SubNavButtons />
        </GridItem>

        <GridItem className="scrollbars" colSpan={12} gridRow={2} overflow="auto">
          <Grid templateColumns="repeat(12, 1fr)" width="100%" flex="1 1 auto">
            <GridItem
              colSpan={8}
              minWidth="552px"
              maxWidth="1000px"
              overflow="hidden"
              backgroundColor="#F6F6F6"
              borderRadius="16px"
            >
              <SettingsMainContent />
            </GridItem>
            <GridItem colSpan={4} minWidth="300px" overflow="hidden">
              <div />
            </GridItem>
          </Grid>
        </GridItem>

        <GridItem colSpan={12} gridRow={3} minWidth="552px" maxWidth="1000px">
          {isDemoAccount && <AdminSettingsContent />}
        </GridItem>
      </Grid>
    </Flex>
  )
}

export default SettingsPage
