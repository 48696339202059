import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import {
  ONBOARDING_TUTORIAL_STEPS,
  WEBAPP_LOCAL_STORAGE_KEYS,
} from '../../../models/browserStorage.types'
import OnboardingDialogs from './OnboardingDialogs'
import { Box } from '@chakra-ui/react'
import Portal from '../../../widgets/Portal'
import { useLazyGetOpenTabsQuery } from '../../../webapp/redux/extension'
import ExpandWindowMessage from './ExpandWindowMessage'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import { usePostHog } from 'posthog-js/react'
import { useUserContext } from '../../../contexts/UserContext'
import { useLazyGetActiveProjectsQuery } from '../../../redux/services/skeema/projects.endpoints'
import { useLazyGetSmartSessionsWithOpenTabsV2Query } from '../../../redux/services/skeema/smart_sessions.endpoints'
import { useLazyGetTablistPagesQuery } from '../../../redux/services/skeema/tablist_pages.endpoints'

export const getOnboardingStepSequence = (params: {
  numOpenTabs: number
  numUnusedTabs: number
  numTabGroups: number
  maxNumTabsInTabGroup: number
  maxNumProjects: number
  maxNumProjectPages: number
}): ONBOARDING_TUTORIAL_STEPS[] => {
  const {
    numOpenTabs,
    numUnusedTabs,
    numTabGroups,
    maxNumTabsInTabGroup,
    maxNumProjects,
    maxNumProjectPages,
  } = params

  let steps = [
    ONBOARDING_TUTORIAL_STEPS.Preface,
    ONBOARDING_TUTORIAL_STEPS.Stats,
    ONBOARDING_TUTORIAL_STEPS.Intro,
    ONBOARDING_TUTORIAL_STEPS.Intro2,
    ONBOARDING_TUTORIAL_STEPS.TabGroups,
    ONBOARDING_TUTORIAL_STEPS.PinTabs,
    ONBOARDING_TUTORIAL_STEPS.AutoSaveConfig,
    ONBOARDING_TUTORIAL_STEPS.TabList,
    ONBOARDING_TUTORIAL_STEPS.DraftProjects,
    ONBOARDING_TUTORIAL_STEPS.Conclusion,
  ]

  if (numOpenTabs < 6) {
    steps = steps.filter(
      (step) =>
        step !== ONBOARDING_TUTORIAL_STEPS.Stats && step !== ONBOARDING_TUTORIAL_STEPS.Intro,
    )
  }

  if (numUnusedTabs === 0) {
    steps = steps.filter((step) => step !== ONBOARDING_TUTORIAL_STEPS.Intro)
  }

  if (
    numTabGroups === 0 ||
    numTabGroups > maxNumProjects ||
    maxNumTabsInTabGroup > maxNumProjectPages
  ) {
    steps = steps.filter((step) => step !== ONBOARDING_TUTORIAL_STEPS.TabGroups)
  }

  return steps
}

interface Props {
  currentStep: ONBOARDING_TUTORIAL_STEPS | null
  setCurrentStep: React.Dispatch<ONBOARDING_TUTORIAL_STEPS | null>
  isOnboardingTutorialEnabled: boolean
  smartSessionsContainerRef: React.RefObject<HTMLDivElement>
}

const OnboardingFlowController: FC<Props> = (props) => {
  const { currentStep, setCurrentStep, isOnboardingTutorialEnabled, smartSessionsContainerRef } =
    props
  const posthog = usePostHog()
  const { captureAnalytics } = useUserContext()
  const { projectConfig, isDefault: areFeatureFlagsLoading } = useFeatureFlagContext()
  const maxNumProjects = projectConfig.maxNumProjects
  const maxNumProjectPages = projectConfig.maxNumProjectsPages

  const dialogPositionRef = useRef<HTMLDivElement | null>(null)
  const [onboardingSteps, setOnboardingSteps] = useState<ONBOARDING_TUTORIAL_STEPS[] | undefined>(
    undefined,
  )
  const shouldShowOnboarding = !!isOnboardingTutorialEnabled && !!onboardingSteps && !!currentStep
  const currentStepIdx = shouldShowOnboarding
    ? onboardingSteps.findIndex((step) => step === currentStep)
    : undefined
  const numTotalSteps = shouldShowOnboarding ? onboardingSteps.length : undefined

  const [triggerTablistPagesQuery] = useLazyGetTablistPagesQuery({
    refetchOnFocus: false,
  })
  const [triggerSmartSessionsQuery] = useLazyGetSmartSessionsWithOpenTabsV2Query({
    refetchOnFocus: false,
  })
  const [triggerActiveProjectsQuery] = useLazyGetActiveProjectsQuery({ refetchOnFocus: false })
  const [triggerOpenTabsQuery] = useLazyGetOpenTabsQuery({
    refetchOnFocus: false,
  })

  useEffect(() => {
    // Remove focus from any active element (e.g. autofocused search bar)
    if (shouldShowOnboarding) {
      const el = window.document.activeElement as HTMLElement | null
      el?.blur()
    }
  }, [shouldShowOnboarding])

  useEffect(() => {
    if (onboardingSteps || areFeatureFlagsLoading) {
      return
    }

    async function initOnboardingSteps() {
      const openTabs = (await triggerOpenTabsQuery(undefined)).data ?? []
      const numOpenTabs = openTabs.length
      const numUnusedTabs = openTabs.filter(
        (t) => t.last_access_timestamp_ms < Date.now() - 24 * 60 * 60 * 1000,
      ).length
      const tabGroupCounts = openTabs.reduce(
        (acc, t) => {
          const groupId = t.group_id ?? -1
          if (groupId === -1) {
            return acc
          }
          if (acc[groupId] === undefined) {
            acc[groupId] = 0
          }
          acc[groupId]++
          return acc
        },
        {} as Record<number, number>,
      )

      const numTabGroups = Object.keys(tabGroupCounts).length
      const maxNumTabsInTabGroup = Math.max(...Object.values(tabGroupCounts))

      const steps = getOnboardingStepSequence({
        numOpenTabs,
        numUnusedTabs,
        numTabGroups,
        maxNumTabsInTabGroup,
        maxNumProjects,
        maxNumProjectPages,
      }) // Add any ff config here

      captureAnalytics('onboarding_tutorial:steps_loaded', {
        steps,
        numOpenTabs,
        numUnusedTabs,
        numTabGroups,
        maxNumTabsInTabGroup,
        maxNumProjects,
        maxNumProjectPages,
      })
      setOnboardingSteps(steps)
    }

    initOnboardingSteps()
  }, [
    areFeatureFlagsLoading,
    captureAnalytics,
    maxNumProjectPages,
    maxNumProjects,
    onboardingSteps,
    triggerOpenTabsQuery,
  ])

  useEffect(() => {
    if (!onboardingSteps || currentStep === ONBOARDING_TUTORIAL_STEPS.DONE) {
      return
    }

    // Init Onboarding Step in Local Storage
    if (currentStep === null) {
      const step = onboardingSteps[0]
      setCurrentStep(step)

      captureAnalytics('onboarding_tutorial:first_step_init', {
        step,
        steps: onboardingSteps,
      })
    } else if (!onboardingSteps.includes(currentStep)) {
      const step = onboardingSteps[0]
      setCurrentStep(step)

      captureAnalytics('onboarding_tutorial:invalid_step_reset', {
        step,
        steps: onboardingSteps,
      })
    }
  }, [captureAnalytics, currentStep, onboardingSteps, setCurrentStep])

  // ------------------- Animate modal transition during draft projects step -------------------
  const currentStepRef = useRef(currentStep)
  useEffect(() => {
    currentStepRef.current = currentStep
  }, [currentStep])

  useEffect(() => {
    if (!smartSessionsContainerRef.current || !dialogPositionRef.current) {
      return
    }

    let timeout: ReturnType<typeof setTimeout> | null = null
    if (currentStep === ONBOARDING_TUTORIAL_STEPS.DraftProjects) {
      timeout = setTimeout(() => {
        if (!smartSessionsContainerRef.current || !dialogPositionRef.current) {
          return
        }
        if (currentStepRef.current !== ONBOARDING_TUTORIAL_STEPS.DraftProjects) {
          return
        }

        const rect = smartSessionsContainerRef.current.getBoundingClientRect()
        const right = window.innerWidth - rect.x + 16
        dialogPositionRef.current.style.right = `${right}px`
      }, 500)
    } else {
      dialogPositionRef.current.style.right = '32px'
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [currentStep, smartSessionsContainerRef])

  // ------------------- Animate modal transition during draft projects step -------------------

  const logOnboardingCompletion = useCallback(async () => {
    const projects = (await triggerActiveProjectsQuery(undefined)).data
    const openTabs = (await triggerOpenTabsQuery(undefined)).data
    const tablistPages = (await triggerTablistPagesQuery(undefined)).data
    const smartSessions = (await triggerSmartSessionsQuery({ openTabs })).data

    const savedPages = tablistPages?.tablist
    const mostUsedTabs = tablistPages?.most_visited_pages
    const numSavedPages = savedPages?.length || 0
    const numMostUsedPages = mostUsedTabs?.length || 0
    const numOpenTabs = openTabs?.length || 0
    const numTabGroups = projects?.length || 0
    const numSmartSessions = smartSessions?.sessions?.length || 0

    captureAnalytics('onboarding_tutorial:completed', {
      numSmartSessions,
      numTabGroups,
      numMostUsedPages,
      numSavedPages,
      numOpenTabs,
    })
  }, [
    captureAnalytics,
    triggerActiveProjectsQuery,
    triggerOpenTabsQuery,
    triggerSmartSessionsQuery,
    triggerTablistPagesQuery,
  ])

  const handleIncrementStep = useCallback(() => {
    if (currentStep === null || currentStepIdx === undefined || !onboardingSteps) {
      return
    }

    const newIdx = currentStepIdx + 1
    const newStep =
      newIdx < onboardingSteps.length ? onboardingSteps[newIdx] : ONBOARDING_TUTORIAL_STEPS.DONE
    captureAnalytics('onboarding_tutorial:step_completed', {
      step: currentStep,
      stepIdx: currentStepIdx,
      newStep,
      steps: onboardingSteps,
    })
    setCurrentStep(newStep)

    if (currentStep === ONBOARDING_TUTORIAL_STEPS.Preface) {
      console.log('Posthog: Start recording')
      posthog.startSessionRecording()
    }

    if (newStep === ONBOARDING_TUTORIAL_STEPS.DONE) {
      window.localStorage.setItem(WEBAPP_LOCAL_STORAGE_KEYS.IsReadyForPinningTutorial, 'true')
      logOnboardingCompletion()
      console.log('Posthog: Finish recording')
      posthog.stopSessionRecording()
    }
  }, [
    currentStepIdx,
    onboardingSteps,
    captureAnalytics,
    currentStep,
    setCurrentStep,
    posthog,
    logOnboardingCompletion,
  ])

  if (!shouldShowOnboarding) {
    return null
  }

  return (
    <Portal>
      <Box position="fixed" top={0} left={0} right={0} bottom={0} w="0" h="0" zIndex={2}>
        <Box
          ref={dialogPositionRef}
          position="fixed"
          bottom="32px"
          right={'32px'}
          transition={'right 600ms ease-in-out'}
          zIndex={1}
        >
          <OnboardingDialogs
            currentStep={currentStep}
            currentStepIdx={currentStepIdx ?? 0}
            numSteps={numTotalSteps ?? 0}
            onNext={handleIncrementStep}
            didDoTabGroups={onboardingSteps.includes(ONBOARDING_TUTORIAL_STEPS.TabGroups)}
          />
        </Box>
        <ExpandWindowMessage />
      </Box>
    </Portal>
  )
}

export default OnboardingFlowController
