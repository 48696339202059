import React, { FC } from 'react'
import { Flex, Box, Img, Link, Button } from '@chakra-ui/react'
import ProjectsList from './ProjectsList'
import { useUserContext } from '../../../contexts/UserContext'
import { styled } from 'styled-components'
import { FiArchive } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../contexts/AuthContext'

const NavLinkContainer = styled.div`
  width: 100%;
  cursor: pointer;

  p {
    color: #585858;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
  }

  &:hover {
    p {
      color: #000;
    }
    svg {
      color: #000 !important;
    }
  }
`

interface Props {
  isProjectsSidebarBlurred: boolean
  isOnboardingTutorialEnabled: boolean
  detailProjectId?: string
}

const ProjectsSidebar: FC<Props> = (props) => {
  const { isProjectsSidebarBlurred, isOnboardingTutorialEnabled, detailProjectId } = props
  const { authHeader } = useAuthContext()
  const { captureAnalytics } = useUserContext()
  const navigate = useNavigate()

  if (!authHeader) {
    return null
  }

  return (
    <Flex direction="column" justifyContent={'space-between'} h="100%" w="100%">
      <Box display={'flex'} alignItems={'center'} m="36px 24px 76px 24px">
        <Link href="/">
          <Img src="/logo-wordmark.svg" alt="Skeema logo" height={'22px'} />
        </Link>
      </Box>
      <Box
        flex="1"
        overflow="hidden"
        width="100%"
        p="0 20px 0px 20px"
        opacity={isProjectsSidebarBlurred ? 0.4 : 1}
      >
        <ProjectsList
          authHeader={authHeader}
          isProjectsSidebarBlurred={isProjectsSidebarBlurred}
          isOnboardingTutorialEnabled={isOnboardingTutorialEnabled}
          detailProjectId={detailProjectId}
        />
      </Box>
      <Box p="24px" opacity={isOnboardingTutorialEnabled ? 0.4 : 1}>
        <NavLinkContainer>
          <Link
            onClick={() => {
              captureAnalytics('sidebar:archive_button_clicked')
              navigate('/archive')
            }}
          >
            <Button
              as="p"
              w="100%"
              justifyContent={'flex-start'}
              variant="ghost"
              leftIcon={<FiArchive color="#585858" />}
              borderRadius={'14px'}
              _hover={{
                bg: '#EBEBEB',
              }}
            >
              Archive
            </Button>
          </Link>
        </NavLinkContainer>
      </Box>
    </Flex>
  )
}

export default ProjectsSidebar
