import React, { FC, useEffect, useMemo, useState } from 'react'
import { styled } from 'styled-components'
import { useGetAllTabRecordsQuery } from '../../../webapp/redux/extension'
import { Text, Box, Spinner } from '@chakra-ui/react'
import PutasideTabController from '../PutasideTabList/PutasideTabController'
import { TABLIST_AREA_NAME_ENUM } from '../PutasideTabList/PutasideTabList'
import { AutoSaveSettingsFormValuesType } from './OnboardingAutoSaveConfigDialog'
import { GLOBAL_WINDOW_MESSAGE_ENUM, onGlobalWindowMessage } from '../../../utils/utils'
import { SETTINGS_FORM_FIELD_NAMES } from '../../settingsV2/SettingsForm'
import { TablistPageType } from '../../../models/tablist_pages.types'
import { getStaleTabRecords, mapTabRecordToTablistPage } from '../../../utils/tabUtils'

const SectionContainer = styled.div`
  margin-bottom: 16px;
`

const TableHeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-left: 16px;

  h2 {
    font-size: 14px;
    font-weight: 600;
    color: #0071e3;
    line-height: 22px; /* 157.143% */
  }
`

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 6px;
  padding-right: 94px;
  border-bottom: 1px solid #d5d5d5;
  margin-left: 16px;
  margin-right: 24px;
`

const TableHeaderCell = styled.div`
  h2 {
    font-size: 12px;
    font-weight: 500;
    color: #a7a7a7;
  }
`

const MagicMomentTablist: FC = () => {
  const [autoSaveSettings, setAutoSaveSettings] = useState<AutoSaveSettingsFormValuesType | null>(
    null,
  )
  const { data: openTabRecords } = useGetAllTabRecordsQuery(undefined, { pollingInterval: 1000 })

  const isLoading = !openTabRecords || !autoSaveSettings

  const [tabsToKeepByWindowId, tabsToCloseByWindowId] = useMemo(() => {
    if (!openTabRecords || !autoSaveSettings) {
      return [undefined, undefined]
    }

    const overwhelmedThreshold = Number(
      autoSaveSettings[SETTINGS_FORM_FIELD_NAMES.OVERWHELM_THRESHOLD] ?? 0,
    )
    const stalenessThresholdAfterOverwhelm =
      Number(autoSaveSettings[SETTINGS_FORM_FIELD_NAMES.STALE_THRESHOLD_AFTER_OVERWHELM]) ?? 0

    const sortTabsFunc = (a: TablistPageType, b: TablistPageType) => {
      if (a.is_pinned && !b.is_pinned) {
        return -1
      }
      if (!a.is_pinned && b.is_pinned) {
        return 1
      }
      return b.last_access_timestamp_ms - a.last_access_timestamp_ms
    }

    const windowIds = new Set(openTabRecords.map((t) => t.windowId))

    const tabsToClose = Array.from(windowIds)
      .map((id) => {
        return getStaleTabRecords({
          tabRecords: openTabRecords,
          shouldHaveExperimentalPutawayV2: false,
          windowId: id,
          tabOverwhelmThreshold: overwhelmedThreshold,
          stalenessThresholdBeforeOverwhelm: 0,
          stalenessThresholdAfterOverwhelm,
        })
      })
      .reduce((acc, val) => acc.concat(val), [])
      .map(mapTabRecordToTablistPage)
      .sort(sortTabsFunc)

    const tabsToKeep: TablistPageType[] = openTabRecords
      .filter((t) => !tabsToClose.find((c) => c.id === t.id))
      .map(mapTabRecordToTablistPage)
      .sort(sortTabsFunc)

    const tabsToKeepByWindowId = tabsToKeep.reduce(
      (acc, val) => {
        if (!val.window_id) {
          return acc
        }

        if (!acc[val.window_id]) {
          acc[val.window_id] = []
        }
        acc[val.window_id].push(val)
        return acc
      },
      {} as Record<number, TablistPageType[]>,
    )

    const tabsToCloseByWindowId = tabsToClose.reduce(
      (acc, val) => {
        if (!val.window_id) {
          return acc
        }

        if (!acc[val.window_id]) {
          acc[val.window_id] = []
        }
        acc[val.window_id].push(val)
        return acc
      },
      {} as Record<number, TablistPageType[]>,
    )

    return [Object.values(tabsToKeepByWindowId), Object.values(tabsToCloseByWindowId)]
  }, [autoSaveSettings, openTabRecords])

  useEffect(() => {
    return onGlobalWindowMessage(GLOBAL_WINDOW_MESSAGE_ENUM.CHANGE_AUTOSAVE_SETTINGS, (payload) => {
      const settings = payload as AutoSaveSettingsFormValuesType
      setAutoSaveSettings(settings)
    })
  }, [])

  return (
    <Box
      width="100%"
      height="100%"
      mb="400px" // Allows scroll to prevent the onboarding dialog from blocking the tablist
      overflow="hidden"
      display="flex"
      flexDirection="column"
    >
      <Box p="24px 8px 24px 16px">
        {openTabRecords && openTabRecords.length === 0 && (
          <Box className="flex-center" mt="6px" w="100%">
            <Text textAlign={'center'} margin={4} fontSize="14px" color="#a7a7a7">
              {`Wow! Your browser is already clean. We'll help you keep it that way.`}
            </Text>
          </Box>
        )}
        {(!openTabRecords || openTabRecords.length > 0) && (
          <>
            <SectionContainer>
              <TableHeaderTitleContainer>
                <h2>These tabs will stay open</h2>
              </TableHeaderTitleContainer>

              {isLoading && (
                <div className="flex-center" style={{ width: '100%', marginTop: '32px' }}>
                  <Spinner color="blue.500" size="lg" speed="1s" />
                </div>
              )}

              {tabsToKeepByWindowId && tabsToKeepByWindowId.length === 0 && (
                <Box className="flex-center" mt="6px" w="100%">
                  <Text textAlign={'center'} margin={4} fontSize="12px" color="#a7a7a7">
                    {`All your tabs (except for this one) will be closed based on the selected time and tab limits.`}
                  </Text>
                </Box>
              )}

              {tabsToKeepByWindowId && tabsToKeepByWindowId.length > 0 && (
                <>
                  <TableHeaderContainer>
                    <TableHeaderCell>
                      <h2>Tab title</h2>
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <h2>Last used</h2>
                    </TableHeaderCell>
                  </TableHeaderContainer>
                  <div>
                    {tabsToKeepByWindowId.map((tabsInWindow, idx) => {
                      return (
                        <React.Fragment key={idx}>
                          <Box m="12px 0px 4px 16px">
                            <TableHeaderCell>
                              <h2>Window {idx + 1}</h2>
                            </TableHeaderCell>
                          </Box>
                          {tabsInWindow.map((t, idx) => (
                            <PutasideTabController
                              key={t.id}
                              id={t.id}
                              page={t}
                              showTimeString={true}
                              queryValue={''}
                              index={idx}
                              numTotalResults={tabsInWindow.length}
                              areaName={TABLIST_AREA_NAME_ENUM.Open}
                              isTitleClickDisabled={true}
                              isDraggingDisabled={true}
                              isHoverDisabled={true}
                            />
                          ))}
                        </React.Fragment>
                      )
                    })}
                  </div>
                </>
              )}
            </SectionContainer>

            <SectionContainer>
              <TableHeaderTitleContainer>
                <h2>These tabs will be closed and saved right here</h2>
              </TableHeaderTitleContainer>

              {isLoading && (
                <div className="flex-center" style={{ width: '100%', marginTop: '32px' }}>
                  <Spinner color="blue.500" size="lg" speed="1s" />
                </div>
              )}

              {tabsToCloseByWindowId && tabsToCloseByWindowId.length === 0 && (
                <Box className="flex-center" mt="6px" w="100%">
                  <Text textAlign={'center'} margin={4} fontSize="12px" color="#a7a7a7">
                    {`No tabs will be closed based on the selected time and tab limits.`}
                  </Text>
                </Box>
              )}

              {tabsToCloseByWindowId && tabsToCloseByWindowId.length > 0 && (
                <>
                  <TableHeaderContainer>
                    <TableHeaderCell>
                      <h2>Tab title</h2>
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <h2>Last used</h2>
                    </TableHeaderCell>
                  </TableHeaderContainer>

                  <div>
                    {tabsToCloseByWindowId.map((tabsInWindow, idx) => {
                      return (
                        <React.Fragment key={idx}>
                          <Box m="12px 0px 4px 16px">
                            <TableHeaderCell>
                              <h2>Window {idx + 1}</h2>
                            </TableHeaderCell>
                          </Box>
                          {tabsInWindow.map((t, idx) => (
                            <PutasideTabController
                              key={t.id}
                              id={t.id}
                              page={t}
                              showTimeString={true}
                              queryValue={''}
                              index={idx}
                              numTotalResults={tabsInWindow.length}
                              areaName={TABLIST_AREA_NAME_ENUM.Open}
                              isTitleClickDisabled={true}
                              isDraggingDisabled={true}
                              isHoverDisabled={true}
                            />
                          ))}
                        </React.Fragment>
                      )
                    })}
                  </div>
                </>
              )}
            </SectionContainer>
          </>
        )}
      </Box>
    </Box>
  )
}

export default MagicMomentTablist
