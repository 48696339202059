import React, { FC, useCallback } from 'react'
import ProjectDetailPageView from './ProjectDetailPageView'
import { useUserContext } from '../../../contexts/UserContext'
import { ProjectPageType } from '../../../models/saved_sessions.types'
import { sendMessageToExtension } from '../../../webapp/utils/externalMessaging'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { shouldOpenInNewTab } from '../../../utils/utils'

interface PropTypes {
  projectId: string
  page: ProjectPageType
  isExtensionInstalled: boolean
}

const ProjectDetailSharedPageController: FC<PropTypes> = (props) => {
  const { projectId, page, isExtensionInstalled } = props
  const { captureAnalytics } = useUserContext()

  const handleClickTitle = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation()
      if (isExtensionInstalled) {
        sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.OPEN_TABS, {
          urls: [page.url],
          shouldActivate: !shouldOpenInNewTab(event),
        })
      } else {
        window.open(page.url, '_blank')
      }
      captureAnalytics('project_detail_dashboard:page_title_click', { projectId, ...page })
    },
    [isExtensionInstalled, captureAnalytics, projectId, page],
  )

  return (
    <ProjectDetailPageView
      title={page.title}
      url={page.url}
      favIconUrl={page.favicon_url}
      handleClickIcon={handleClickTitle}
      handleClickTitle={handleClickTitle}
      handleClickBody={handleClickTitle}
      shouldForceHighlightTop={false}
      shouldForceHighlightBottom={false}
      isDraggingOverThis={false}
      isDraggingThis={false}
      isDraggingDisabled={true}
    />
  )
}

export default ProjectDetailSharedPageController
