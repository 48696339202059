import React, { FC } from 'react'
import { Flex, Text, Spinner, Button } from '@chakra-ui/react'
import { FaArrowRight } from 'react-icons/fa6'

interface InstantSaveToastProps {
  instantSaveQuota?: number
  onClickUpgrade: () => void
}

const InstantSaveToast: FC<InstantSaveToastProps> = (props) => {
  const { instantSaveQuota, onClickUpgrade } = props

  return (
    <Flex
      bg="#CCE3F9"
      color="#0071E3"
      borderRadius="40px"
      borderColor="#CCE3F9"
      border="1px solid #A3CCF5"
      boxShadow="0px 0px 4px 0px #0000000A, 0px 8px 16px 0px #00000014"
      // w={instantSaveQuota !== null && instantSaveQuota <= 0 ? '679px' : '532px'}
      mb="32px"
      gap="40px"
      p="8px 8px 8px 24px"
      alignItems="center"
    >
      {instantSaveQuota === undefined && (
        <Text fontSize="md" fontWeight={500} ml={6}>
          <Spinner />
        </Text>
      )}
      {instantSaveQuota !== undefined && (
        <>
          <Text
            fontSize="md"
            fontWeight={500}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {instantSaveQuota <= 0
              ? "Oops!  You've used all your free Instant Saves this week."
              : `Saved to Skeema!  You have ${instantSaveQuota} more Instant Save${
                  instantSaveQuota > 1 ? 's' : ''
                } left this week`}
          </Text>
          <Button bg="#0071E3" color="white" borderRadius="100px" _hover={{ bg: '#0071E3' }}>
            <Text fontSize="md" fontWeight={500} ml={2} mr={2} onClick={onClickUpgrade}>
              Upgrade for unlimited
            </Text>
            <FaArrowRight style={{ width: '18px', flexShrink: 0 }} />
          </Button>
        </>
      )}
    </Flex>
  )
}

export default InstantSaveToast
