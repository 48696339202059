import React, { FC } from 'react'
import MainDashboard from './MainDashboard'
import { useParams } from 'react-router-dom'
import SharedProjectPage from './SharedProjectPage'
import { useGetProjectQuery } from '../../redux/services/skeema/projects.endpoints'
import { useReduxSelector } from '../../redux/baseStore'
import { useExtensionHealthContext } from '../../contexts/ExtensionHealthContext'
import NotFoundPage from '../notFound/NotFoundPage'

interface Props {
  contentType: 'tablist' | 'archive' | 'project'
}

const MorningPage: FC<Props> = (props) => {
  const { contentType } = props
  const isExtensionNotInstalled = useExtensionHealthContext().extensionHealth === null
  const isAuthLoading = useReduxSelector((state) => state.user.isAuthLoading)
  const { projectId } = useParams<{ projectId: string }>()
  const { data: project, error: projectQueryError } = useGetProjectQuery(
    {
      id: String(projectId),
    },
    {
      skip: !projectId || isAuthLoading,
    },
  )

  if (
    projectQueryError &&
    (('status' in projectQueryError && projectQueryError.status === 404) ||
      ('originalStatus' in projectQueryError && projectQueryError.originalStatus === 500)) //500 can occur for invalid UUIDs
  ) {
    return <NotFoundPage />
  }

  if (project && (!project.is_owner || isExtensionNotInstalled)) {
    return <SharedProjectPage project={project} />
  }

  return <MainDashboard contentType={contentType} />
}

export default MorningPage
