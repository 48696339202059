import React, { FC, useEffect } from 'react'
import { Button } from '@chakra-ui/react'
import { useNavigate } from 'react-router'
import { MINIMUM_EXTENSION_VERSION } from '../../constants'
import { useExtensionHealthContext } from '../../contexts/ExtensionHealthContext'
import AnnouncementsPage from '../../components/AnnouncementsPage'
import { useUserContext } from '../../contexts/UserContext'

const heading = 'Update Skeema'
const content = [
  'Your Skeema extension requires an update to keep functioning.',
  'Please update the Skeema extension to continue.',
]

const UpdateExtensionPage: FC = () => {
  const { captureAnalytics } = useUserContext()
  const { extensionHealth } = useExtensionHealthContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (extensionHealth?.version && extensionHealth?.version >= MINIMUM_EXTENSION_VERSION) {
      captureAnalytics('update_extension_page:update_not_required')
      navigate('/')
    }
  }, [captureAnalytics, extensionHealth?.version, navigate])

  const onClickLearnMore = () => {
    captureAnalytics('update_extension_page:click_learn_more')
  }

  return (
    <AnnouncementsPage
      heading={heading}
      content={content}
      widget={
        <Button
          mb="4"
          as="a"
          style={{
            backgroundColor: 'black',
            borderRadius: '20px',
            borderColor: '#D5D5D5',
            color: 'white',
            fontWeight: 'normal',
            padding: '0 30px',
          }}
          href={'https://support.cloudhq.net/how-to-manually-update-chrome-extensions/'}
          target="_blank"
          onClick={onClickLearnMore}
        >
          Learn More
        </Button>
      }
    />
  )
}

export default UpdateExtensionPage
