import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { css, styled } from 'styled-components'
import { ProjectType } from '../../../models/saved_sessions.types'
import { Collapse } from '@chakra-ui/react'
import { useLocalStorageBoolean } from '../../../utils/genericHooks'
import ProjectPageController from './ProjectPageController'
import ProjectTitle from './projectTitle/ProjectTitle'
import { useDrag, useDrop } from 'react-dnd'
import { DND_ITEM_ENUM, DnDItemPayload, DnDItemProjectPayload } from '../../../models/dnd.types'
import { TablistPageType } from '../../../models/tablist_pages.types'
import { useUserContext } from '../../../contexts/UserContext'
import { useReduxDispatch, useReduxSelector } from '../../../redux/baseStore'
import { setIsProjectDragging } from '../../../redux/dndSlice'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { convertSmartSessionPagesToTablistPages } from '../smartSessions/SmartSessionCard'

const Container = styled.div<{
  $isDraggingThis: boolean
  $isPageDraggingOverThis: boolean
  $isProjectExpanded: boolean
  $isHoverDisabled: boolean
  $isProjectSelected: boolean
}>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  border-radius: 14px;
  cursor: grab;
  box-sizing: border-box;

  border: ${({ $isProjectSelected }) =>
    $isProjectSelected ? '1.5px solid #585858' : '1px solid transparent'};

  opacity: ${({ $isDraggingThis }) => ($isDraggingThis ? 0.5 : 1)};

  ${({ $isHoverDisabled, $isProjectExpanded }) =>
    $isHoverDisabled
      ? ''
      : css`
          &:not(:has(.ProjectPageContainer:hover)):hover,
          &.force-hover {
            .hover-show-project-title {
              opacity: 1;
            }
          }

          &:hover,
          &.force-hover {
            ${$isProjectExpanded
              ? ''
              : css`
                  background-color: #ebebeb;
                `}

            h2 {
              color: #000;
              margin-left: 4px;
            }
          }
        `}

  h2 {
    transition: margin-left 0.2s;

    ${({ $isProjectExpanded }) =>
      $isProjectExpanded
        ? css`
            color: #000;
            margin-left: 4px;
          `
        : ''}
  }

  ${({ $isPageDraggingOverThis }) =>
    $isPageDraggingOverThis
      ? css`
          /* border: 1px dashed #0071e3; */
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='14' ry='14' stroke='%230071e3' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='8' stroke-linecap='butt'/%3e%3c/svg%3e");
          background-color: rgba(0, 113, 227, 0.2);
        `
      : ''}
`

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const InfoLabelContainer = styled.div`
  width: 100%;
  align-items: center;
  overflow: hidden;

  p {
    color: #a7a7a7;
    margin-left: 8px;
    margin-top: 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const PagesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 6px;
  padding-bottom: 1px; //Fixes dnd indicator line cut off on the bottom of the last page
`

const DndIndicatorLine = styled.div<{ $isVisible: boolean; $isTop: boolean }>`
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  position: absolute;
  width: calc(100% - 26px);
  height: 2px;
  right: 12px;
  background: #0071e3;
  pointer-events: none;
  z-index: 11;

  ${({ $isTop }) =>
    $isTop
      ? css`
          top: -1px;
        `
      : css`
          bottom: -1px;
        `};
`

interface Props {
  project: ProjectType
  maxNumPages: number
  isFocusedFromCreation: boolean
  shouldExpand: boolean | null
  handleMoveProject: (id: string, order: number) => Promise<void>
  handleMoveProjectPage: (params: {
    projectId: string
    pageId: string
    index: number
    destProjectId: string
  }) => Promise<void>
  handleAddTabsToProject: (params: {
    projectId: string
    tablistPages: TablistPageType[]
    index?: number
    loggingProps?: Record<string, unknown>
  }) => Promise<void>
  handlePageLimitError: () => void
  renameProject: (params: { id: string; title: string }) => Promise<void>
  deleteProjectPage: (params: { projectId: string; pageId: string }) => Promise<void>
  isProjectSelected: boolean
}

const ProjectSidebarItem: FC<Props> = (props) => {
  const {
    project,
    maxNumPages,
    handleMoveProject,
    handleAddTabsToProject,
    handleMoveProjectPage,
    handlePageLimitError,
    isFocusedFromCreation,
    isProjectSelected,
    shouldExpand,
    renameProject,
    deleteProjectPage,
  } = props
  const { id, title, order, pages } = project
  const { captureAnalytics } = useUserContext()
  const dispatch = useReduxDispatch()
  const isTablistPageDragging = useReduxSelector((state) => state.dnd.isTablistPageDragging)
  const isProjectPageDragging = useReduxSelector((state) => state.dnd.isProjectPageDragging)
  const isProjectDragging = useReduxSelector((state) => state.dnd.isProjectDragging)
  const isSmartSessionDragging = useReduxSelector((state) => state.dnd.isSmartSessionDragging)
  const isPageDragging = isTablistPageDragging || isProjectPageDragging || isSmartSessionDragging
  const [_isExpanded, setIsExpanded] = useLocalStorageBoolean(
    `ProjectExpanded.${id}`,
    shouldExpand ?? false,
  )
  const isExpanded = _isExpanded && !isProjectDragging && !isProjectSelected
  const projectContainerRef = useRef<HTMLDivElement | null>(null)
  const shouldCollapseAfterDragRef = useRef<boolean>(false)
  const numPages = pages.length
  const urls = useMemo(() => pages.map((page) => page.url), [pages])

  useEffect(() => {
    if (isFocusedFromCreation) {
      setIsExpanded(true)
    }
  }, [isFocusedFromCreation, setIsExpanded])

  useEffect(() => {
    if (shouldExpand !== null) {
      console.log('shouldExpand', shouldExpand)
      setIsExpanded(shouldExpand)
    }
  }, [setIsExpanded, shouldExpand])

  useEffect(() => {
    if (!isPageDragging) {
      setTimeout(() => {
        if (shouldCollapseAfterDragRef.current) {
          setIsExpanded(false)
          shouldCollapseAfterDragRef.current = false
        }
      }, 500)
    }
  }, [isPageDragging, setIsExpanded])

  const [{ isDraggingThis }, connectDragSource, connectDragPreview] = useDrag(
    () => ({
      type: DND_ITEM_ENUM.PROJECT,
      collect: (monitor) => ({
        isDraggingThis: monitor.isDragging(),
      }),
      end: () => dispatch(setIsProjectDragging(false)),
      item: () => {
        dispatch(setIsProjectDragging(true))
        const payload: DnDItemProjectPayload = {
          type: DND_ITEM_ENUM.PROJECT,
          project,
        }
        return payload
      },
    }),
    [project, dispatch],
  )

  useEffect(() => {
    connectDragPreview(getEmptyImage(), { captureDraggingState: true })
  }, [connectDragPreview])

  const [dragOverLocation, setDragOverLocation] = useState<'top' | 'bottom' | undefined>(undefined)
  const dragOverLocationRef = useRef<'top' | 'bottom' | undefined>(undefined)
  const shouldHighlightTop = dragOverLocation === 'top' && isProjectDragging
  const shouldHighlightBottom = dragOverLocation === 'bottom' && isProjectDragging

  useEffect(() => {
    dragOverLocationRef.current = dragOverLocation
  }, [dragOverLocation])

  const isPageDraggingOverThisRef = useRef<boolean>(false)
  const draggingOverTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const [{ isDraggingOverThis, isPageDraggingOverThis }, connectDropTarget] = useDrop<
    //Typescript was somehow unable to infer the type of the payload, so I had to manually specify it
    DnDItemPayload,
    unknown,
    {
      isDraggingOverThis: boolean
      isPageDraggingOverThis: boolean
    }
  >(
    () => ({
      accept: [
        DND_ITEM_ENUM.TABLIST_PAGE,
        DND_ITEM_ENUM.PROJECT_PAGE,
        DND_ITEM_ENUM.PROJECT,
        DND_ITEM_ENUM.SMART_SESSION,
      ],
      collect: (monitor) => {
        const payload: DnDItemPayload | null = monitor.getItem()
        const isPageDragging =
          payload?.type === DND_ITEM_ENUM.PROJECT_PAGE ||
          payload?.type === DND_ITEM_ENUM.TABLIST_PAGE ||
          payload?.type === DND_ITEM_ENUM.SMART_SESSION
        const isDraggingOverThis = monitor.isOver()
        const isPageDraggingOverThis = isDraggingOverThis && isPageDragging

        return {
          isDraggingOverThis,
          isPageDraggingOverThis,
        }
      },
      hover: (payload, monitor) => {
        if (payload.type === DND_ITEM_ENUM.PROJECT && payload.project.id === id) {
          return
        }

        if (
          payload.type === DND_ITEM_ENUM.PROJECT_PAGE ||
          payload.type === DND_ITEM_ENUM.TABLIST_PAGE ||
          payload.type === DND_ITEM_ENUM.SMART_SESSION
        ) {
          if (!draggingOverTimeoutRef.current) {
            draggingOverTimeoutRef.current = setTimeout(() => {
              if (isPageDraggingOverThisRef.current && !isExpanded) {
                shouldCollapseAfterDragRef.current = true
                setIsExpanded(true)
                captureAnalytics('projects_list:project_expand_drag', { id, numPages })
              }
              draggingOverTimeoutRef.current = null
            }, 300)
          }
        }

        const { y } = monitor.getClientOffset() ?? {}
        const { top, bottom, height } = projectContainerRef.current?.getBoundingClientRect() ?? {}
        if (top === undefined || bottom === undefined || y === undefined || height === undefined) {
          setDragOverLocation(undefined)
          return
        }

        if (payload.type === DND_ITEM_ENUM.PROJECT) {
          const middleY = top + height / 2

          const newDragLocation = middleY > y ? 'top' : 'bottom'

          const isSameLocation =
            (payload.project.order === order - 1 && newDragLocation === 'top') ||
            (payload.project.order === order + 1 && newDragLocation === 'bottom')

          if (isSameLocation) {
            setDragOverLocation(undefined)
            return
          }

          setDragOverLocation(newDragLocation)
        } else if (monitor.isOver({ shallow: true })) {
          //Dropping a page on the project container edges/title
          const newDragLocation = y < top + 60 ? 'top' : y > bottom - 18 ? 'bottom' : undefined

          if (payload.type === DND_ITEM_ENUM.PROJECT_PAGE && payload.projectId === id) {
            const isTopPointless = payload.index === 0 && newDragLocation === 'top'
            const isBottomPointless = payload.index === numPages - 1 && newDragLocation === 'bottom'
            if (isTopPointless || isBottomPointless) {
              //Trying to drop in the same location
              setDragOverLocation(undefined)
              return
            }
          }

          setDragOverLocation(newDragLocation)
        } else {
          setDragOverLocation(undefined)
        }
      },
      canDrop: (payload: DnDItemPayload) => {
        if (payload.type === DND_ITEM_ENUM.PROJECT_PAGE) {
          if (payload.projectId === id) {
            const isTopPointless = payload.index === 0 && dragOverLocationRef.current === 'top'
            const isBottomPointless =
              payload.index === numPages - 1 && dragOverLocationRef.current === 'bottom'
            if (isTopPointless || isBottomPointless) {
              return false
            }
          }
        } else if (payload.type === DND_ITEM_ENUM.PROJECT) {
          if (payload.project.id === id) {
            return false
          }
          const isSameLocation =
            (payload.project.order === order - 1 && dragOverLocationRef.current === 'top') ||
            (payload.project.order === order + 1 && dragOverLocationRef.current === 'bottom')
          if (isSameLocation) {
            return false
          }
        }

        return true
      },
      drop: async (payload: DnDItemPayload, monitor) => {
        shouldCollapseAfterDragRef.current = false

        if (monitor.didDrop()) {
          //Another drop target (a child item) received the drop event already
          return { status: 'DID_DROP' }
        }

        const isDragItemFromThisProject =
          payload?.type === DND_ITEM_ENUM.PROJECT_PAGE && payload?.projectId === id
        const numAddPages = isDragItemFromThisProject
          ? 0
          : payload?.type === DND_ITEM_ENUM.SMART_SESSION
            ? payload?.session.pages.length
            : 1
        const newNumPages = numPages + numAddPages
        const shouldShowPageLimitError = newNumPages > maxNumPages

        if (shouldShowPageLimitError) {
          handlePageLimitError()
          return { status: 'ERROR: Project page limit reached' }
        }

        if (payload.type === DND_ITEM_ENUM.TABLIST_PAGE) {
          const index = dragOverLocationRef.current === 'bottom' ? numPages : 0

          await handleAddTabsToProject({
            projectId: id,
            tablistPages: [payload.page],
            index,
          })

          return { status: 'SUCCESS' }
        } else if (payload.type === DND_ITEM_ENUM.SMART_SESSION) {
          const index = dragOverLocationRef.current === 'bottom' ? numPages : 0
          const newPages = convertSmartSessionPagesToTablistPages(payload.session.pages)

          await handleAddTabsToProject({
            projectId: id,
            tablistPages: newPages,
            index,
            loggingProps: {
              smartSession: payload.session,
            },
          })

          return { status: 'SUCCESS' }
        } else if (payload.type === DND_ITEM_ENUM.PROJECT_PAGE) {
          if (dragOverLocationRef.current === undefined) {
            // Fixes bug where in place dnd causes reorder
            // But prevents reordering a page to the left/right margins of the project
            return { status: 'SKIPPING' }
          }
          const index = dragOverLocationRef.current === 'bottom' ? numPages : 0

          await handleMoveProjectPage({
            projectId: payload.projectId,
            pageId: payload.page.id,
            destProjectId: id,
            index,
          })

          return { status: 'SUCCESS' }
        } else if (payload.type === DND_ITEM_ENUM.PROJECT) {
          let newOrder = order

          const isMovingUp = payload.project.order > order
          if (isMovingUp && dragOverLocationRef.current === 'bottom') {
            newOrder = order + 1
          } else if (!isMovingUp && dragOverLocationRef.current === 'top') {
            newOrder = order - 1
          }

          await handleMoveProject(payload.project.id, newOrder)

          return { status: 'SUCCESS' }
        }

        return { status: 'ERROR' }
      },
    }),
    [
      isExpanded,
      setIsExpanded,
      numPages,
      maxNumPages,
      handleAddTabsToProject,
      id,
      captureAnalytics,
      order,
      handleMoveProject,
      handleMoveProjectPage,
    ],
  )

  useEffect(() => {
    if (!isDraggingOverThis) {
      setDragOverLocation(undefined)
    }
  }, [isDraggingOverThis])

  const connectDnd = useCallback(
    (node: HTMLDivElement | null) => {
      projectContainerRef.current = node
      connectDropTarget(projectContainerRef)
      connectDragSource(projectContainerRef)
    },
    [connectDragSource, connectDropTarget],
  )

  useEffect(() => {
    isPageDraggingOverThisRef.current = isPageDraggingOverThis
  }, [isPageDraggingOverThis])

  const handleClickProjectContainer = () => {
    setIsExpanded(!isExpanded)
    captureAnalytics('projects_list:project_expand_click', { id, title, pages, numPages })
  }

  //Show error when hovered if project page limit reached and user hovers with something other than a tab from within the project

  return (
    <Container
      ref={connectDnd}
      onClick={handleClickProjectContainer}
      $isDraggingThis={isDraggingThis}
      $isPageDraggingOverThis={isPageDraggingOverThis}
      $isProjectExpanded={!!isExpanded}
      $isHoverDisabled={isProjectDragging}
      $isProjectSelected={isProjectSelected}
    >
      <DndIndicatorLine
        $isVisible={shouldHighlightTop || shouldHighlightBottom}
        $isTop={shouldHighlightTop}
      />
      <TitleContainer>
        <ProjectTitle
          id={id}
          title={title}
          urls={urls}
          isFocusedFromCreation={isFocusedFromCreation}
          renameProject={renameProject}
          isProjectExpanded={!!isExpanded}
        />
      </TitleContainer>
      <Collapse in={!!isExpanded}>
        {pages.length === 0 && (
          <InfoLabelContainer>
            <p>{`Drop tabs here to save them`}</p>
          </InfoLabelContainer>
        )}
        {pages.length > 0 && (
          <PagesContainer>
            {pages.map((child, index) => {
              if (child.url) {
                return (
                  <ProjectPageController
                    key={`${id}${child.id}`}
                    projectId={id}
                    page={child}
                    index={index}
                    shouldForceHighlightTop={
                      index === 0 && isPageDragging && dragOverLocation === 'top'
                    }
                    shouldForceHighlightBottom={
                      index === pages.length - 1 && isPageDragging && dragOverLocation === 'bottom'
                    }
                    numProjectPages={numPages}
                    maxNumProjectPages={maxNumPages}
                    handleAddTabsToProject={handleAddTabsToProject}
                    handleMoveProjectPage={handleMoveProjectPage}
                    handleProjectPageLimitError={handlePageLimitError}
                    deleteProjectPage={deleteProjectPage}
                  />
                )
              } else {
                return <></>
              }
            })}
          </PagesContainer>
        )}
      </Collapse>
    </Container>
  )
}

export default ProjectSidebarItem
