import React, { FC, useMemo } from 'react'
import { DnDItemSmartSessionPayload } from '../../../models/dnd.types'
import { styled } from 'styled-components'
import DragIcon from '../../../components/DragIcon'
import { Box, Heading } from '@chakra-ui/react'
import { convertSmartSessionPagesToTablistPages } from './SmartSessionCard'
import PutasideTabView from '../PutasideTabList/PutasideTabView'

const Container = styled.div`
  position: relative;
  width: 272px;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 16px 2px;
  border-radius: 12px;
  border: 1.5px solid #cfd2ef;
  cursor: grabbing;
  box-sizing: border-box;

  background: #f2f3fe;
  opacity: 0.7;

  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.04),
    0px 4px 8px 0px rgba(0, 0, 0, 0.06);
`

const TitleContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-bottom: 6px;

  & > h2 {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    word-break: break-word;
    line-height: 22px;
  }
`

function emptyFunc(_event: React.MouseEvent<HTMLElement, MouseEvent>): void {
  throw new Error('Function not implemented.')
}

interface Props {
  item: DnDItemSmartSessionPayload
}

const SmartSessionDragPreview: FC<Props> = (props) => {
  const { item } = props
  const { name } = item.session
  const pages = useMemo(() => {
    return convertSmartSessionPagesToTablistPages(item.session.pages)
  }, [item.session.pages])

  return (
    <Container>
      <TitleContainer>
        <Box w="12px" mr="6px" className="hover-show-title-icons">
          <DragIcon />
        </Box>
        <Heading as="h2">{name}</Heading>
      </TitleContainer>
      <Box>
        {pages.map((page) => {
          return (
            <PutasideTabView
              key={page.id}
              title={page.title ?? 'Untitled Page'}
              favIconUrl={page.favicon_url}
              isTitleClickDisabled={true}
              isFocused={false}
              isCheckboxShown={false}
              showTimeString={false}
              showActionIcons={false}
              isDraggingDisabled={true}
              showOpenTabIcon={page.is_open}
              lastAccessDateTime={page.last_access_datetime_utc_iso}
              handleClickFavIcon={emptyFunc}
              handleClickDragIcon={emptyFunc}
              handleClickTitle={emptyFunc}
              handleClickBody={emptyFunc}
              isDraggingThis={false}
              isChecked={false}
              isSelectionDisabled={false}
              isHoverDisabled={false}
            />
          )
        })}
      </Box>
    </Container>
  )
}

export default SmartSessionDragPreview
