import React, { FC, useCallback, useState } from 'react'
import { Flex, Text, Box, Button, Heading, Spinner } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import {
  selectNumActiveProjects,
  useDeleteArchivedProjectMutation,
  useGetArchivedProjectsQuery,
  useUnarchiveProjectMutation,
} from '../../../redux/services/skeema/projects.endpoints'
import { styled } from 'styled-components'
import ArchivedProjectCard from './ArchivedProjectCard'
import { useUserContext } from '../../../contexts/UserContext'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import { useReduxSelector } from '../../../redux/baseStore'
import ProjectDeletionModal from './ProjectDeletionModal'
import ProjectLimitModal from '../projectsSidebar/ProjectLimitModal'
import { IoMdArrowBack } from 'react-icons/io'

const CardListContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 12px;
  padding-bottom: 24px;
`

interface Props {
  authHeader: string
}

const ProjectArchiveDashboard: FC<Props> = (props) => {
  const { authHeader } = props
  const { captureAnalytics } = useUserContext()
  const navigate = useNavigate()

  const { projectConfig } = useFeatureFlagContext()
  const numProjects = useReduxSelector(selectNumActiveProjects)
  const maxNumProjects = projectConfig.maxNumProjects
  const isProjectLimitReached = numProjects === undefined || numProjects >= maxNumProjects

  const [projectDeletionId, setProjectDeletionId] = useState<string | null>(null)
  const [isProjectLimitModalOpen, setIsProjectLimitModalOpen] = useState<boolean>(false)

  const { data: projects } = useGetArchivedProjectsQuery(undefined, { skip: !authHeader })
  const [unarchiveProjectMutation] = useUnarchiveProjectMutation()
  const [deleteArchivedProjectMutation] = useDeleteArchivedProjectMutation()

  const handleUnarchiveProject = useCallback(
    (id: string) => {
      const project = projects?.find((project) => project.id === id)
      if (!project) {
        return
      }

      const urls = project?.pages?.map((page) => page.url)

      captureAnalytics('archived_projects_list:project_unarchive', {
        numTabs: urls?.length,
        urls,
        ...project,
      })

      unarchiveProjectMutation({ project })
    },
    [captureAnalytics, unarchiveProjectMutation, projects],
  )

  const handleDeleteProject = useCallback(() => {
    if (!projectDeletionId) {
      return
    }

    const project = projects?.find((project) => project.id === projectDeletionId)
    const urls = project?.pages?.map((page) => page.url)

    captureAnalytics('archived_projects_list:project_delete', {
      numTabs: urls?.length,
      urls,
      ...project,
    })

    deleteArchivedProjectMutation({ id: projectDeletionId })

    setProjectDeletionId(null)
  }, [captureAnalytics, deleteArchivedProjectMutation, projectDeletionId, projects])

  const handleOpenProjectDeletionModal = useCallback(
    (projectId: string) => {
      const project = projects?.find((project) => project.id === projectId)
      if (!project) {
        return
      }

      setProjectDeletionId(projectId)
    },
    [projects],
  )

  const handleCancelProjectDeletion = useCallback(() => {
    if (!projectDeletionId) {
      return
    }

    const project = projects?.find((p) => p.id === projectDeletionId)
    const urls = project?.pages?.map((page) => page.url)

    captureAnalytics('archived_projects_list:project_delete_dialog_cancel_click', {
      numTabs: urls?.length,
      urls,
      ...project,
    })

    setProjectDeletionId(null)
  }, [captureAnalytics, projectDeletionId, projects])

  const handleProjectLimitError = useCallback(() => {
    captureAnalytics('archived_projects_list:project_limit_modal_show')
    setIsProjectLimitModalOpen(true)
  }, [captureAnalytics])

  const handleDismissProjectLimitModal = () => {
    captureAnalytics('archived_projects_list:project_limit_modal_dismiss_click')
    setIsProjectLimitModalOpen(false)
  }

  const handleSubmitProjectLimitModal = () => {
    captureAnalytics('archived_projects_list:project_limit_modal_upgrade_click')
    setIsProjectLimitModalOpen(false)
    navigate('#pricing')
  }

  return (
    <>
      <Flex direction="column" h="100%" w="100%" overflow="hidden">
        <Box width="100%" mb="54px">
          <Button
            size="sm"
            w="98px"
            h="36px"
            borderRadius="100px"
            p="7px 20px"
            ml="10px"
            mb="16px"
            bg="transparent"
            color="#585858"
            position={'relative'}
            fontSize="14px"
            fontWeight="500"
            lineHeight="22px"
            _hover={{ bg: 'white' }}
            leftIcon={<IoMdArrowBack />}
            onClick={() => {
              navigate('/saved')
            }}
          >
            Back
          </Button>
          <Heading ml="24px" fontWeight={600} fontSize="20px">
            Archive
          </Heading>
        </Box>
        <CardListContainer className="scrollbars">
          {!projects && (
            <div className="flex-center" style={{ width: '100%', marginTop: '32px' }}>
              <Spinner color="blue.500" size="lg" speed="1s" />
            </div>
          )}
          {projects && projects.length === 0 && (
            <div className="flex-center" style={{ width: '100%' }}>
              <Text textAlign="center" margin={4} fontSize="14px" color="#a7a7a7">
                {`Archived items will appear here and can be either restored or deleted.`}
              </Text>
            </div>
          )}
          {projects &&
            projects.length > 0 &&
            projects.map((project, idx) => (
              <ArchivedProjectCard
                key={`${project.title}${idx}`}
                project={project}
                isProjectLimitReached={isProjectLimitReached}
                handleDeleteProject={handleOpenProjectDeletionModal}
                handleUnarchiveProject={handleUnarchiveProject}
                handleProjectLimitError={handleProjectLimitError}
              />
            ))}
        </CardListContainer>
      </Flex>

      <ProjectDeletionModal
        isOpen={projectDeletionId !== null}
        onCancel={handleCancelProjectDeletion}
        onDelete={handleDeleteProject}
      />

      <ProjectLimitModal
        isOpen={isProjectLimitModalOpen}
        onCancel={handleDismissProjectLimitModal}
        onSubmit={handleSubmitProjectLimitModal}
      />
    </>
  )
}

export default ProjectArchiveDashboard
