import React, { FC, useEffect, useState } from 'react'
import {
  Heading,
  IconButton,
  Tooltip,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from '@chakra-ui/react'
import { styled } from 'styled-components'
import { FaRegTrashAlt } from 'react-icons/fa'
import { FiArchive, FiEdit2, FiShare2 } from 'react-icons/fi'
import { PiArrowUUpLeftBold } from 'react-icons/pi'
import { MdOpenInNew, MdMoreVert, MdOutlineGroups } from 'react-icons/md'

const TitleContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;

  & > h1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
`

const HoverButtonsContainer = styled.div`
  height: 22px;
  /* position: absolute;
  right: 0;
  top: 0; */
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-left: 8px;
  background: #f6f6f6;
  transition: opacity 0.2s;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -24px;
    width: 24px;
    height: 100%;
    background-image: 'linear-gradient(to right, rgba(246, 246, 246, 0), #f6f6f6)';
  }
`

const ChakraMenuListContainer = styled.div`
  display: flex;

  .chakra-menu-list {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: #fff;
    box-shadow:
      0px 0px 4px 0px rgba(0, 0, 0, 0.04),
      0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    display: flex;
    min-width: 154px;
    width: 154px;
    padding: 8px;
    gap: 4px;
  }

  .chakra-menu-item {
    display: flex;
    align-items: center;
    padding: 10px 12px;
    border-radius: 10px;

    p {
      color: #585858;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
      white-space: nowrap;
    }

    svg {
      margin-right: 8px;
      color: #585858;
    }

    &:hover {
      background: #ebebeb;
      text-decoration: none;

      svg {
        color: #585858;
      }
    }

    &:focus {
      background: #ebebeb;
      text-decoration: none;
    }
  }
`

interface Props {
  title: string
  isArchived: boolean
  isShared: boolean
  handleClickDeleteIcon: (event: React.MouseEvent<Element, MouseEvent>) => void
  handleClickArchiveIcon: (event: React.MouseEvent<Element, MouseEvent>) => void
  handleClickUnarchiveIcon: (event: React.MouseEvent<Element, MouseEvent>) => void
  handleClickOpenIcon: (event: React.MouseEvent<Element, MouseEvent>) => void
  handleClickRenameIcon: (event: React.MouseEvent<Element, MouseEvent>) => void
  handleClickShareIcon: (event: React.MouseEvent<Element, MouseEvent>) => void
  onMenuOpen: () => void
  onMenuClose: () => void
}

const ProjectDetailStaticTitle: FC<Props> = (props) => {
  const {
    title,
    isArchived,
    isShared,
    handleClickDeleteIcon,
    handleClickArchiveIcon,
    handleClickUnarchiveIcon,
    handleClickOpenIcon,
    handleClickRenameIcon,
    handleClickShareIcon,
    onMenuOpen,
    onMenuClose,
  } = props
  const [wasMenuJustClosed, setWasMenuJustClosed] = useState<boolean>(false)

  const onMenuCloseWrapper = () => {
    setWasMenuJustClosed(true) //Fixes bug where tooltip reappears after closing menu
    onMenuClose()
  }

  useEffect(() => {
    if (wasMenuJustClosed) {
      setTimeout(() => {
        setWasMenuJustClosed(false)
      }, 100)
    }
  }, [wasMenuJustClosed])

  return (
    <TitleContainer>
      <Heading as="h1" className="is-ellipsis-1">
        {title}
      </Heading>
      <Flex ml="8px" gap="8px" alignItems="center">
        {isArchived && (
          <Flex
            alignItems="center"
            justifyContent="center"
            borderRadius="24px"
            p="4px 12px"
            fontWeight={500}
            fontSize="12px"
            lineHeight="16px"
            color="#585858"
            bg="#E0E0E0"
          >
            Archived
          </Flex>
        )}

        {isShared && (
          <Flex
            alignItems="center"
            justifyContent="center"
            borderRadius="8px"
            p="4px 8px"
            gap="6px"
            fontWeight={500}
            fontSize="12px"
            lineHeight="16px"
            color="#585858"
            bg="#E0E0E0"
          >
            <MdOutlineGroups />
            Shared
          </Flex>
        )}
      </Flex>
      <HoverButtonsContainer className="hover-show-title-icons">
        {!isArchived && (
          <span className="flex-center" style={{ height: '100%', cursor: 'pointer' }}>
            <Tooltip label={`Open all pages`} placement="top">
              <IconButton
                variant="outline"
                border="none"
                aria-label={`Open all pages`}
                as="a"
                icon={<MdOpenInNew color="#585858" />}
                onClick={handleClickOpenIcon}
                height="24px"
                width="24px"
                minWidth="24px"
                tabIndex={-1}
                _hover={{
                  background: 'inherit',
                }}
              />
            </Tooltip>
            <Tooltip label={`Share project`} placement="top">
              <IconButton
                variant="outline"
                border="none"
                aria-label={`Share project`}
                icon={<FiShare2 color="#585858" />}
                onClick={handleClickShareIcon}
                height="24px"
                width="24px"
                minWidth="24px"
                tabIndex={-1}
                _hover={{
                  background: 'inherit',
                }}
              />
            </Tooltip>
          </span>
        )}

        <Menu onOpen={onMenuOpen} onClose={onMenuCloseWrapper}>
          <span
            className="flex-center"
            style={{ height: '100%', cursor: 'pointer', marginLeft: '2px' }}
          >
            <Tooltip isDisabled={wasMenuJustClosed} label={`More`} placement="top">
              <MenuButton
                as={IconButton}
                variant="outline"
                border="none"
                aria-label={`See more options`}
                icon={<MdMoreVert color="#585858" />}
                onClick={(e) => e.stopPropagation()}
                height="24px"
                width="24px"
                minWidth="24px"
                tabIndex={-1}
                _hover={{
                  background: 'inherit',
                }}
              />
            </Tooltip>
          </span>
          <Portal>
            <ChakraMenuListContainer>
              <MenuList className="chakra-menu-list">
                <MenuItem className="chakra-menu-item" onClick={handleClickRenameIcon}>
                  <FiEdit2 />
                  <p>Rename project</p>
                </MenuItem>
                {!isArchived && (
                  <MenuItem className="chakra-menu-item" onClick={handleClickArchiveIcon}>
                    <FiArchive />
                    <p>Archive project</p>
                  </MenuItem>
                )}
                {isArchived && (
                  <>
                    <MenuItem className="chakra-menu-item" onClick={handleClickUnarchiveIcon}>
                      <PiArrowUUpLeftBold />
                      <p>Restore project</p>
                    </MenuItem>
                    <MenuItem className="chakra-menu-item" onClick={handleClickDeleteIcon}>
                      <FaRegTrashAlt />
                      <p>Delete project</p>
                    </MenuItem>
                  </>
                )}
              </MenuList>
            </ChakraMenuListContainer>
          </Portal>
        </Menu>
      </HoverButtonsContainer>
    </TitleContainer>
  )
}

export default ProjectDetailStaticTitle
