import React, { FC } from 'react'
import { Heading, Box, IconButton, Tooltip } from '@chakra-ui/react'
import { styled } from 'styled-components'
import { PiArrowUUpLeftBold } from 'react-icons/pi'
import { FaRegTrashAlt } from 'react-icons/fa'

const TitleContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-bottom: 6px;

  // Hovered & expanded states are managed in SmartSessionCard.tsx
  & > h2 {
    color: #585858;
    transition: color 0.2s;
    font-size: 14px;
    font-weight: 600;
    word-break: break-word;
    line-height: 22px;
  }

  .hover-show-title-icons {
    transition: opacity 0.2s;
    opacity: 0;
  }
`

const HoverButtonsContainer = styled.div`
  height: 22px;
  position: absolute;
  right: 0;
  top: 0;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-left: 8px;
  background: #f6f6f6;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -24px;
    width: 24px;
    height: 100%;
    background-image: 'linear-gradient(to right, rgba(246, 246, 246, 0), #f6f6f6)';
  }
`

interface Props {
  title: string
  handleClickDeleteProjectIcon: (event: React.MouseEvent<Element, MouseEvent>) => void
  handleClickUnarchiveProjectIcon: (event: React.MouseEvent<Element, MouseEvent>) => void
}

const ArchivedProjectCardTitle: FC<Props> = (props) => {
  const { title, handleClickDeleteProjectIcon, handleClickUnarchiveProjectIcon } = props
  const unarchiveProjectButtonTooltip = `Restore project`

  return (
    <TitleContainer>
      <Box w="12px" mr="6px" className="hover-show-title-icons">
        {/* <DragIcon /> */}
      </Box>

      <Heading as="h2">{title}</Heading>

      <HoverButtonsContainer className="hover-show-title-icons">
        <Tooltip label={unarchiveProjectButtonTooltip} placement="top">
          <span className="flex-center" style={{ height: '100%', cursor: 'pointer' }}>
            <IconButton
              variant="outline"
              border="none"
              aria-label={unarchiveProjectButtonTooltip}
              as="a"
              icon={<PiArrowUUpLeftBold color="#585858" />}
              onClick={handleClickUnarchiveProjectIcon}
              height="24px"
              width="24px"
              minWidth="24px"
              tabIndex={-1}
              _hover={{ background: 'inherit' }}
            />
          </span>
        </Tooltip>

        <Tooltip label={`Delete project`} placement="top">
          <span className="flex-center" style={{ height: '100%', cursor: 'pointer' }}>
            <IconButton
              variant="outline"
              border="none"
              aria-label={`Delete project`}
              as="a"
              icon={<FaRegTrashAlt color="#585858" />}
              onClick={handleClickDeleteProjectIcon}
              ml="4px"
              height="24px"
              width="24px"
              minWidth="24px"
              tabIndex={-1}
              _hover={{ background: 'inherit' }}
            />
          </span>
        </Tooltip>
      </HoverButtonsContainer>
    </TitleContainer>
  )
}

export default ArchivedProjectCardTitle
