import React, { FC, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { AiOutlineChrome } from 'react-icons/ai'
import { FaCircle } from 'react-icons/fa6'
import { TiPin, TiPinOutline } from 'react-icons/ti'
import { Tooltip } from '@chakra-ui/react'
import { MdClose, MdKeyboardReturn } from 'react-icons/md'
import { getLocalDateTimeString, getRelativeTimeString } from '../../../utils/utils'
import { FiDownload } from 'react-icons/fi'
import { cx } from '@emotion/css'
import DragIcon from '../../../components/DragIcon'

const TabBodyContainer = styled.div<{
  $isFocused: boolean
  $isDraggingThis: boolean
  $isDraggingDisabled: boolean
  $isTitleClickDisabled: boolean
  $isHoverDisabled: boolean
  $isGrayedOut: boolean
}>`
  position: relative;
  width: 100%;
  height: 28px;

  display: flex;
  align-items: center;

  border-radius: 4px;
  opacity: 1;
  z-index: ${({ $isFocused }) => ($isFocused ? 11 : 10)};

  overflow: hidden;

  opacity: ${({ $isDraggingThis, $isGrayedOut }) => ($isDraggingThis || $isGrayedOut ? 0.5 : 1)};

  cursor: ${({ $isDraggingDisabled, $isDraggingThis }) =>
    $isDraggingThis ? 'grabbing' : $isDraggingDisabled ? 'auto' : 'grab'}!important;

  & .hover-show:not(.is-focused) {
    opacity: 0;
    transition: opacity 0.2s;
  }

  ${({ $isHoverDisabled, $isFocused, $isDraggingDisabled, $isDraggingThis, $isGrayedOut }) =>
    !$isHoverDisabled &&
    css`
      &:hover,
      &.forceHoverStates {
        ${!$isDraggingThis && $isGrayedOut
          ? css`
              opacity: 1;
            `
          : ''};
        background: ${$isFocused ? 'rgba(0, 113, 227, 0.2)' : '#EBEBEB'};

        .hover-show {
          opacity: 1;

          &.DragIcon {
            opacity: ${$isDraggingDisabled ? 0 : 1};
          }
        }
      }
    `}

  ${({ $isFocused }) =>
    $isFocused &&
    css`
      outline: 1px solid #0071e3;
      background: rgba(0, 113, 227, 0.2);
    `}

  /* Wrapping elements in spans to create larger click targets */
  & > span {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .TabBodyContainerIcon {
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    opacity: 1;

    & > span {
      position: relative; //Need for children showOnHover/hideOnHover to be properly centered
      width: 16px;
      height: 16px;
      flex-shrink: 0;
    }
  }

  .TabBodyContainerTitle {
    flex-grow: 2;
    min-width: 0;
    margin-right: 16px;

    & > a {
      max-width: 90%;
    }

    p.Title {
      cursor: ${({ $isTitleClickDisabled }) => ($isTitleClickDisabled ? 'default' : 'pointer')};
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #585858;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px; /* 133.333% */

      & > mark {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        text-decoration: inherit;
        padding: 0;
        background-color: #585858;
        color: white;
      }

      &:hover:not(.disabled) {
        text-decoration: underline;
      }
    }
  }

  .TabBodyContainerTime {
    flex-shrink: 0;
    min-width: 90px;
    width: 90px;

    p.Time {
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #a7a7a7;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
    }
  }
`

const ReturnIconContainer = styled.div`
  width: 24px;
  height: 16px;
  padding: 2px 4px;
  margin-right: 6px;
  border-radius: 4px;
  background: #0071e3;

  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    width: 100%;
    height: 100%;
    color: white;
  }
`

function highlightText(text: string, indexMatches?: [number, number][]): ReactNode[] {
  if (!indexMatches || indexMatches.length === 0) {
    return [<React.Fragment key={1}>{text}</React.Fragment>]
  }

  const indices = [...indexMatches].sort((a, b) => a[0] - b[0])
  let lastEnd = 0
  const result: ReactNode[] = []

  for (let i = 0; i < indices.length; i++) {
    const start = indices[i][0]
    const end = Math.min(indices[i][1] + 1, text.length)

    result.push(text.slice(lastEnd, start))

    const marked = <mark key={i}>{text.slice(start, end)}</mark>
    result.push(marked)

    lastEnd = end
  }

  result.push(text.slice(lastEnd))

  return result
}

interface PropTypes {
  title: string
  favIconUrl?: string
  lastAccessDateTime: string
  handleClickFavIcon: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  handleClickDragIcon: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  handleClickDeleteIcon?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  handleClickSaveIcon?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  handleClickTitle: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  handleClickCheckbox?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  handleClickBody: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  tabContainerRef?: React.MutableRefObject<HTMLDivElement | null>
  connectDnD?: (node: HTMLDivElement | null) => void
  titleHighlights?: [number, number][]
  isDraggingThis: boolean
  isDraggingDisabled: boolean
  isChecked: boolean
  isCheckboxShown: boolean
  isSelectionDisabled: boolean
  isHoverDisabled: boolean
  isSelectionDisabledTooltipLabel?: string
  isTitleClickDisabled: boolean
  showTimeString: boolean
  showActionIcons: boolean
  isFocused: boolean
  showOpenTabIcon?: boolean
  isPinnedTab?: boolean
  titleToolTip?: string
  deleteIconToolTip?: string
  saveIconTooltip?: string
  shouldShowPinIconForCheckbox?: boolean
}

const PutasideTabView: FC<PropTypes> = (props) => {
  const {
    title,
    favIconUrl,
    lastAccessDateTime,
    handleClickFavIcon,
    handleClickDragIcon,
    handleClickDeleteIcon,
    handleClickSaveIcon,
    handleClickTitle,
    handleClickCheckbox,
    handleClickBody,
    tabContainerRef,
    connectDnD,
    isDraggingThis,
    isDraggingDisabled,
    isChecked,
    isCheckboxShown,
    isSelectionDisabled,
    isHoverDisabled,
    isSelectionDisabledTooltipLabel,
    isTitleClickDisabled,
    showTimeString,
    showActionIcons,
    titleHighlights,
    isFocused,
    showOpenTabIcon,
    isPinnedTab,
    titleToolTip,
    deleteIconToolTip,
    saveIconTooltip,
    shouldShowPinIconForCheckbox,
  } = props

  return (
    <TabBodyContainer
      className="TabBodyContainer"
      ref={(node) => {
        if (tabContainerRef) {
          tabContainerRef.current = node
        }
        connectDnD && connectDnD(node)
      }}
      $isFocused={isFocused}
      $isDraggingThis={isDraggingThis}
      $isDraggingDisabled={isDraggingDisabled}
      $isTitleClickDisabled={isTitleClickDisabled}
      $isHoverDisabled={isHoverDisabled}
      $isGrayedOut={!!shouldShowPinIconForCheckbox && !isChecked}
      onClick={handleClickBody}
    >
      <span
        style={{ width: '12px' }}
        className={cx('TabBodyContainerIcon', 'DragIcon', 'hover-show', {
          'is-focused': isFocused,
        })}
        onClick={handleClickDragIcon}
      >
        <DragIcon />
      </span>
      {isCheckboxShown && handleClickCheckbox && (
        <Tooltip
          placement="top"
          isDisabled={!isSelectionDisabled || !isSelectionDisabledTooltipLabel}
          label={isSelectionDisabledTooltipLabel}
          bg="#585858"
        >
          <span
            className={cx('TabBodyContainerIcon', { clickable: !isSelectionDisabled })}
            onClick={handleClickCheckbox}
          >
            {!shouldShowPinIconForCheckbox && (
              <input
                type="checkbox"
                className="pretty-checkbox"
                checked={isChecked}
                disabled={isSelectionDisabled}
                readOnly
              />
            )}
            {shouldShowPinIconForCheckbox && (
              <>
                {isChecked && <TiPin strokeWidth={'2px'} color="#000" />}
                {!isChecked && <TiPinOutline color="#000" />}
              </>
            )}
          </span>
        </Tooltip>
      )}

      <span className="TabBodyContainerIcon" onClick={handleClickFavIcon}>
        {favIconUrl ? (
          <img src={favIconUrl} width="16" height="16" />
        ) : (
          <AiOutlineChrome style={{ width: '16px', height: '16px', color: 'black' }} />
        )}
      </span>

      <span
        className="TabBodyContainerTitle"
        onClick={isTitleClickDisabled ? undefined : handleClickTitle}
      >
        <Tooltip
          openDelay={300}
          placement="top"
          label={titleToolTip}
          isDisabled={isTitleClickDisabled}
        >
          <p className={cx('Title', { disabled: isTitleClickDisabled })}>
            {highlightText(title, titleHighlights)}
          </p>
        </Tooltip>

        {isPinnedTab && (
          <TiPin color="#A7A7A7" size={16} style={{ marginLeft: '5px', flexShrink: 0 }} />
        )}
        {!isPinnedTab && showOpenTabIcon && (
          <FaCircle
            title="This tab is open"
            color="green"
            size={8}
            style={{ marginLeft: '5px', flexShrink: 0 }}
          />
        )}
      </span>

      {showTimeString && (
        <span className="TabBodyContainerTime">
          <p className="Time" title={getLocalDateTimeString(lastAccessDateTime)}>
            {getRelativeTimeString(lastAccessDateTime)}
          </p>
        </span>
      )}

      {showActionIcons && (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            minWidth: '84px',
          }}
        >
          {!handleClickDeleteIcon && (
            <span style={{ width: '32px' }} className="TabBodyContainerIcon" />
          )}

          {handleClickDeleteIcon && (
            <Tooltip placement="top" label={deleteIconToolTip}>
              <span
                style={{ width: '24px', marginRight: handleClickSaveIcon ? '0' : '4px' }}
                className="TabBodyContainerIcon hover-show clickable"
                onClick={handleClickDeleteIcon}
              >
                <MdClose
                  style={{
                    width: '16px',
                    height: '16px',
                    color: isFocused ? '#0071e3' : '#585858',
                  }}
                />
              </span>
            </Tooltip>
          )}

          {handleClickSaveIcon && (
            <Tooltip placement="top" label={saveIconTooltip}>
              <span
                style={{ width: '24px', marginRight: '4px' }}
                className="TabBodyContainerIcon hover-show clickable"
                onClick={handleClickSaveIcon}
              >
                <FiDownload
                  style={{
                    width: '16px',
                    height: '16px',
                    color: isFocused ? '#0071e3' : '#585858',
                  }}
                />
              </span>
            </Tooltip>
          )}

          {isFocused && (
            <Tooltip placement="top" label={'Press Enter to open'}>
              <ReturnIconContainer>
                <MdKeyboardReturn />
              </ReturnIconContainer>
            </Tooltip>
          )}
        </span>
      )}
    </TabBodyContainer>
  )
}

export default PutasideTabView
