import React, { FC } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import NotFoundPage from '../../pages//notFound/NotFoundPage'
import LoginHandler from './login/LoginHandler'
import ExtensionInstalledPage from './extensionInstalled/ExtensionInstalledPage'
import InstallExtensionPage from './installExtension/InstallExtensionPage'
import UpdateExtensionPage from '../../pages/updateExtension/UpdateExtensionPage'
import MorningPageV2 from '../../pages/morningPageV2/MorningPage'
import MorningPageV3 from '../../pages/morningPageV3/MorningPage'
import MorningPageV4 from '../../pages/morningPageV4/MorningPage'
import ExtensionUninstalledPage from './extensionUninstalled/ExtensionUninstalledPage'
import { useFeatureFlagContext } from '../../contexts/FeatureFlagContext'
import { FEATURE_FLAG_KEYS_ENUM } from '../../models/feature_flags.types'
import { useAuthContext } from '../../contexts/AuthContext'
import SettingsPage from '../../pages/settings/SettingsPage'
import SettingsPageV2 from '../../pages/settingsV2/SettingsPage'

enum ROUTES_ENUM {
  EMPTY = '/',
  AUTH_HANDLER = '/auth/handler',
  INSTALL_EXTENSION = '/extension/install',
  UPDATE_EXTENSION = '/extension/update',
  EXTENSION_INSTALLED = '/extension/installed',
  EXTENSION_UNINSTALLED = '/extension/uninstalled',
  SAVED = '/saved',
  COMPLETED_ACTIONS = '/actions',
  ARCHIVE = '/archive',
  PROJECTS = '/projects',
  SETTINGS = '/settings',
}

const MorningPageSwitcher: FC<{
  pageType: 'v2' | 'v3' | 'v4'
  contentType: 'tablist' | 'archive' | 'project' | 'saved' | 'actions'
}> = (props) => {
  const { pageType, contentType } = props

  if (pageType === 'v2') {
    if (contentType === 'saved' || contentType === 'actions') {
      return <MorningPageV2 contentType={'tablist'} />
    }
    return <MorningPageV2 contentType={contentType} />
  }

  if (pageType === 'v3') {
    return <MorningPageV3 contentType={contentType} />
  }

  if (pageType === 'v4') {
    if (contentType === 'saved' || contentType === 'actions') {
      return <MorningPageV4 contentType={'tablist'} />
    }
    return <MorningPageV4 contentType={contentType} />
  }

  return <NotFoundPage />
}

const SettingsPageSwitcher: FC<{ isInternal: boolean }> = (props) => {
  const { isInternal } = props
  if (isInternal) {
    return <SettingsPageV2 />
  } else {
    return <SettingsPage />
  }
}

const RequireAuth: FC<{ children: React.JSX.Element; loginPath: string }> = ({
  children,
  loginPath,
}) => {
  const { isAuthenticated } = useAuthContext()

  if (!isAuthenticated) {
    return <Navigate to={loginPath} />
  }
  return children
}

const MainPage: FC = () => {
  const { featureFlags } = useFeatureFlagContext()
  const isAlpha = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsAlphaUser]
  const isBetaDashboardEnabled = false
  const morningPageType = isAlpha ? 'v4' : isBetaDashboardEnabled ? 'v3' : 'v2'

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100vw' }}>
      <Routes>
        <Route path={ROUTES_ENUM.AUTH_HANDLER} element={<LoginHandler />} />
        <Route path={ROUTES_ENUM.INSTALL_EXTENSION} element={<InstallExtensionPage />} />
        <Route path={ROUTES_ENUM.UPDATE_EXTENSION} element={<UpdateExtensionPage />} />
        <Route path={ROUTES_ENUM.EXTENSION_INSTALLED} element={<ExtensionInstalledPage />} />
        <Route path={ROUTES_ENUM.EXTENSION_UNINSTALLED} element={<ExtensionUninstalledPage />} />
        <Route
          path={ROUTES_ENUM.EMPTY}
          element={
            <RequireAuth loginPath={ROUTES_ENUM.EXTENSION_INSTALLED}>
              {<MorningPageSwitcher pageType={morningPageType} contentType="tablist" />}
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES_ENUM.COMPLETED_ACTIONS}
          element={
            <RequireAuth loginPath={ROUTES_ENUM.EXTENSION_INSTALLED}>
              {<MorningPageSwitcher pageType={morningPageType} contentType="actions" />}
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES_ENUM.SAVED}
          element={
            <RequireAuth loginPath={ROUTES_ENUM.EXTENSION_INSTALLED}>
              {<MorningPageSwitcher pageType={morningPageType} contentType="saved" />}
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES_ENUM.ARCHIVE}
          element={
            <RequireAuth loginPath={ROUTES_ENUM.EXTENSION_INSTALLED}>
              {<MorningPageSwitcher pageType={morningPageType} contentType="archive" />}
            </RequireAuth>
          }
        />
        <Route
          path={`${ROUTES_ENUM.PROJECTS}/:projectId`}
          element={<MorningPageSwitcher pageType={morningPageType} contentType="project" />}
        />
        <Route
          path={ROUTES_ENUM.SETTINGS}
          element={
            <RequireAuth loginPath={ROUTES_ENUM.EXTENSION_INSTALLED}>
              {<SettingsPageSwitcher isInternal={isAlpha} />}
            </RequireAuth>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  )
}

export default MainPage
