import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import ProjectDetailPageView from './ProjectDetailPageView'
import { useUserContext } from '../../../contexts/UserContext'
import { setIsProjectPageDragging } from '../../../redux/dndSlice'
import { useDrag, useDrop } from 'react-dnd'
import { DND_ITEM_ENUM, DnDItemPayload, DnDItemProjectPagePayload } from '../../../models/dnd.types'
import { useReduxDispatch } from '../../../redux/baseStore'
import { ProjectPageType } from '../../../models/saved_sessions.types'
import { TablistPageType } from '../../../models/tablist_pages.types'
import { convertSmartSessionPagesToTablistPages } from '../smartSessions/SmartSessionCard'
import { sendMessageToExtension } from '../../../webapp/utils/externalMessaging'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { shouldOpenInNewTab } from '../../../utils/utils'

interface PropTypes {
  projectId: string
  page: ProjectPageType
  numProjectPages: number
  maxNumProjectPages: number
  index: number
  handleDropProjectPage: (params: {
    sourceProjectId: string
    index: number
    page: ProjectPageType
  }) => Promise<void>
  handleAddTabsToProject: (params: {
    tablistPages: TablistPageType[]
    index?: number
    loggingProps?: Record<string, unknown>
  }) => Promise<void>
  deleteProjectPage: (params: { page: ProjectPageType }) => Promise<void>
  handleProjectPageLimitError: () => void
  isDraggingDisabled?: boolean
  shouldForceHighlightTop?: boolean
  shouldForceHighlightBottom?: boolean
}

const ProjectDetailPageController: FC<PropTypes> = (props) => {
  const {
    projectId,
    page,
    index,
    deleteProjectPage,
    numProjectPages,
    maxNumProjectPages,
    handleDropProjectPage,
    handleAddTabsToProject,
    handleProjectPageLimitError,
    shouldForceHighlightTop = false,
    shouldForceHighlightBottom = false,
    isDraggingDisabled = false,
  } = props
  const { captureAnalytics } = useUserContext()
  const dispatch = useReduxDispatch()

  const handleClickTitle = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation()
      sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.OPEN_TABS, {
        urls: [page.url],
        shouldActivate: !shouldOpenInNewTab(event),
      })
      captureAnalytics('project_detail_dashboard:page_title_click', { projectId, ...page })
    },
    [captureAnalytics, projectId, page],
  )

  const handleClickDelete = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      captureAnalytics('project_detail_dashboard:page_delete_click', { projectId, ...page })
      deleteProjectPage({ page })
    },
    [captureAnalytics, projectId, page, deleteProjectPage],
  )

  const [{ isDraggingThis }, connectDragSource] = useDrag(
    () => ({
      type: DND_ITEM_ENUM.PROJECT_PAGE,
      collect: (monitor) => ({
        isDraggingThis: monitor.isDragging(),
      }),
      canDrag: () => !isDraggingDisabled,
      end: () => dispatch(setIsProjectPageDragging(false)),
      item: () => {
        dispatch(setIsProjectPageDragging(true))
        const payload: DnDItemProjectPagePayload = {
          type: DND_ITEM_ENUM.PROJECT_PAGE,
          page,
          projectId,
          index,
        }
        return payload
      },
    }),
    [page, projectId, index, dispatch],
  )

  const dropSourceRef = useRef<HTMLDivElement | null>(null)
  const [dragOverLocation, setDragOverLocation] = useState<'top' | 'bottom' | undefined>(undefined)
  const dragOverLocationRef = useRef<'top' | 'bottom' | undefined>(undefined)
  useEffect(() => {
    dragOverLocationRef.current = dragOverLocation
  }, [dragOverLocation])

  const [{ isDraggingOverThis }, connectDropTarget] = useDrop(
    () => ({
      accept: [DND_ITEM_ENUM.PROJECT_PAGE, DND_ITEM_ENUM.TABLIST_PAGE, DND_ITEM_ENUM.SMART_SESSION],
      collect: (monitor) => ({
        isDraggingOverThis: monitor.isOver(),
      }),
      canDrop: (payload: DnDItemPayload) => {
        if (payload.type === DND_ITEM_ENUM.PROJECT_PAGE) {
          if (payload.page.id === page.id) {
            return false
          }
          if (payload.projectId === projectId) {
            const isSameLocation =
              (payload.index === index - 1 && dragOverLocation === 'top') ||
              (payload.index === index + 1 && dragOverLocation === 'bottom')
            if (isSameLocation) {
              return false
            }
          }
          return true
        } else if (payload.type === DND_ITEM_ENUM.TABLIST_PAGE) {
          return true
        } else if (payload.type === DND_ITEM_ENUM.SMART_SESSION) {
          return true
        }

        return false
      },
      hover: (payload: DnDItemPayload, monitor) => {
        if (payload.type === DND_ITEM_ENUM.PROJECT_PAGE && payload.page.id === page.id) {
          return
        }

        const { y } = monitor.getClientOffset() ?? {}
        const { height, top } = dropSourceRef.current?.getBoundingClientRect() ?? {}
        const middleY = height && top ? top + height / 2 : undefined

        if (middleY === undefined || y === undefined) {
          setDragOverLocation(undefined)
          return
        }

        const newDragLocation = middleY > y ? 'top' : 'bottom'

        const isSameProject =
          payload.type === DND_ITEM_ENUM.PROJECT_PAGE && payload.projectId === projectId
        if (isSameProject) {
          const isSameLocation =
            (payload.index === index - 1 && newDragLocation === 'top') ||
            (payload.index === index + 1 && newDragLocation === 'bottom')
          if (isSameLocation) {
            setDragOverLocation(undefined)
            return
          }
        }

        setDragOverLocation(newDragLocation)
      },
      drop: async (payload: DnDItemPayload, monitor) => {
        if (monitor.didDrop()) {
          //Another drop target received the drop event already
          return { status: 'DID_DROP' }
        }

        const isDragItemFromThisProject =
          payload?.type === DND_ITEM_ENUM.PROJECT_PAGE && payload?.projectId === projectId
        const numAddPages = isDragItemFromThisProject
          ? 0
          : payload?.type === DND_ITEM_ENUM.SMART_SESSION
            ? payload?.session.pages.length
            : 1
        const newNumPages = numProjectPages + numAddPages
        const shouldShowPageLimitError = newNumPages > maxNumProjectPages

        if (shouldShowPageLimitError) {
          handleProjectPageLimitError()
          return { status: 'ERROR: Project page limit reached' }
        }

        const dropIndex = dragOverLocationRef.current === 'top' ? index : index + 1

        if (payload.type === DND_ITEM_ENUM.TABLIST_PAGE) {
          await handleAddTabsToProject({
            tablistPages: [payload.page],
            index: dropIndex,
          })

          return { status: 'SUCCESS' }
        } else if (payload.type === DND_ITEM_ENUM.SMART_SESSION) {
          const newPages = convertSmartSessionPagesToTablistPages(payload.session.pages)

          await handleAddTabsToProject({
            tablistPages: newPages,
            index: dropIndex,
            loggingProps: {
              smartSession: payload.session,
            },
          })

          return { status: 'SUCCESS' }
        } else if (payload.type === DND_ITEM_ENUM.PROJECT_PAGE) {
          await handleDropProjectPage({
            sourceProjectId: payload.projectId,
            index: dropIndex,
            page: payload.page,
          })

          return { status: 'SUCCESS' }
        }

        return { status: 'ERROR' }
      },
    }),
    [
      handleAddTabsToProject,
      handleDropProjectPage,
      handleProjectPageLimitError,
      numProjectPages,
      maxNumProjectPages,
      projectId,
      page,
      index,
      dragOverLocation,
    ],
  )

  useEffect(() => {
    if (!isDraggingOverThis) {
      setDragOverLocation(undefined)
    }
  }, [isDraggingOverThis])

  const connectDnd = useCallback(
    (node: HTMLDivElement | null) => {
      dropSourceRef.current = node
      connectDragSource(connectDropTarget(dropSourceRef))
    },
    [connectDragSource, connectDropTarget],
  )

  return (
    <ProjectDetailPageView
      title={page.title}
      url={page.url}
      favIconUrl={page.favicon_url}
      isDraggingOverThis={isDraggingOverThis}
      isDraggingThis={isDraggingThis}
      isDraggingDisabled={isDraggingDisabled}
      dragOverLocation={dragOverLocation}
      shouldForceHighlightTop={shouldForceHighlightTop}
      shouldForceHighlightBottom={shouldForceHighlightBottom}
      connectDnd={!isDraggingDisabled ? connectDnd : undefined}
      handleClickIcon={handleClickTitle}
      handleClickTitle={handleClickTitle}
      handleClickBody={handleClickTitle}
      handleClickDelete={handleClickDelete}
    />
  )
}

export default ProjectDetailPageController
