import React, { FC, useEffect, useRef, useState } from 'react'
import ReactPixel from 'react-facebook-pixel'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'
import { sendMessageToExtension } from '../../utils/externalMessaging'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { exchangeAuthCode, getUserInfo } from '../../../apiClient/skeema/fetchWrappers'
import { useExtensionHealthContext } from '../../../contexts/ExtensionHealthContext'
import { ENABLE_META_PIXEL, META_PIXEL_ID } from '../../../constants'
import { Button, Spinner } from '@chakra-ui/react'
import AnnouncementsPage from '../../../components/AnnouncementsPage'
import { useUserContext } from '../../../contexts/UserContext'
import { useAuthContext } from '../../../contexts/AuthContext'

const LoginHandler: FC = () => {
  const { captureAnalytics } = useUserContext()
  const { extensionHealth } = useExtensionHealthContext()
  const didHandlerRun = useRef<boolean>(false)
  const navigate = useNavigate()
  const { signIn } = useAuthContext()
  const [searchParams] = useSearchParams()
  const [status, setStatus] = useState('IN_PROGRESS')
  const isLoading = status === 'IN_PROGRESS'
  const authCode = searchParams.get('code')

  const onClickTryAgain = () => {
    captureAnalytics('login_handler:click_try_again')
    navigate('/extension/installed')
  }

  useEffect(() => {
    console.log('Login Handler Extension Health:', extensionHealth)
    //--------------------------------------
    async function exchangeToken(authCode: string, extensionVersion: number) {
      try {
        const data = await exchangeAuthCode(authCode).catch((e) => {
          console.error('ERROR: Failed to exchange auth code')
          setStatus('FAILURE: Failed to exchange auth code')
          throw e
        })
        const token = data.key
        const timezone = DateTime.now().zoneName
        const userState = await getUserInfo(
          {
            Authorization: `Bearer ${token}`,
          },
          extensionVersion,
          timezone,
        ).catch((e) => {
          console.error('ERROR: Failed to retrieve userState')
          setStatus('FAILURE: Failed to retrieve userState')
          throw e
        })
        const userInfo = userState.data

        const signInResult = signIn(token)

        if (!signInResult) {
          setStatus('FAILURE: Client signIn failed')
          return
        }

        if (ENABLE_META_PIXEL) {
          const advancedMatching = {
            em: userInfo.email,
            fn: userInfo.first_name,
            ln: userInfo.last_name,
            ct: '',
            st: '',
            zp: '',
            country: '',
            ph: '',
            db: '',
            ge: '',
          }
          ReactPixel.init(META_PIXEL_ID, advancedMatching)
          ReactPixel.track('Subscribe')
        }

        await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.UPDATE_AUTH, {
          token,
        }).catch((e) => {
          console.error('ERROR: Failed to send token to extension')
          setStatus('FAILURE: Skeema Extension not found')
          throw e
        })

        if (!userInfo.has_bootstrapped) {
          console.info('Bootstrapping extension...')
          const ok = await sendMessageToExtension(
            BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.BOOTSTRAP,
            {
              startTime: Date.now() - 7 * 24 * 60 * 60 * 1000,
              maxResults: 5000,
            },
          ).catch((e) => {
            console.error('ERROR: Failed to send bootstrap message to extension')
            setStatus('FAILURE: Failed to send bootstrap message to extension')
            throw e
          })
          if (!ok) {
            console.error('ERROR: Extension bootstrap failed')
            setStatus('FAILURE: Extension bootstrap failed')
          }
        }

        return navigate('/', { replace: true })
      } catch (e) {
        console.error(e)
      }
    }
    //--------------------------------------

    if (authCode && extensionHealth && !didHandlerRun.current) {
      exchangeToken(authCode, extensionHealth.version).catch(console.error)
      didHandlerRun.current = true
    }
  }, [authCode, extensionHealth, navigate, signIn])

  if (isLoading) {
    return (
      <AnnouncementsPage
        heading={'Logging in...'}
        content={['We are logging you into Skeema.', 'Please do not leave or refresh this page.']}
        widget={<Spinner color="blue.500" size="lg" speed="1s" />}
      />
    )
  }

  return (
    <AnnouncementsPage
      heading={'Sign in unsuccessful'}
      content={[
        'We were unable to sign you in. Please try again.',
        'If the problem persists, please email support@skeema.com',
      ]}
      widget={
        <Button
          mb="4"
          as="a"
          style={{
            backgroundColor: 'black',
            borderRadius: '20px',
            borderColor: '#D5D5D5',
            color: 'white',
            fontWeight: 'normal',
            padding: '0 30px',
          }}
          onClick={onClickTryAgain}
        >
          Try Again
        </Button>
      }
    />
  )
}

export default LoginHandler
