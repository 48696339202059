import React, { FC } from 'react'
import { VStack, Heading, Select, Box, HStack } from '@chakra-ui/react'
import { Field } from 'formik'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import { FEATURE_FLAG_KEYS_ENUM } from '../../../models/feature_flags.types'
import { SETTINGS_FORM_FIELD_NAMES } from '../../settingsV2/SettingsForm'

const OnboardingAutoSaveSettingsFormContent: FC = () => {
  const { featureFlags } = useFeatureFlagContext()
  const isDeveloper = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsDeveloper]
  const isDemoAccount = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsDemoAccount]

  return (
    <VStack alignItems={'start'}>
      <HStack alignItems="flex-start">
        <Heading as="h3" mt="7px" fontSize="16px" fontWeight={500} mb="4px" whiteSpace={'nowrap'}>
          Time limit:
        </Heading>
        <Box fontSize="16px" lineHeight="16px" color="#585858">
          Automatically save and close tabs after
          <Field
            as={Select}
            display={'inline-block'}
            w="94px"
            h="28px"
            margin="4px 6px"
            name={SETTINGS_FORM_FIELD_NAMES.STALE_THRESHOLD_AFTER_OVERWHELM}
            size="xs"
            fontSize="14px"
            fontWeight={500}
            color="#585858"
            borderColor="#585858"
            borderRadius={'8px'}
            _hover={{ bg: '#E3E3E3' }}
          >
            {isDeveloper && (
              <option key="10000" value="10000">
                10s (developer only)
              </option>
            )}
            {isDemoAccount && (
              <option key="60000" value="60000">
                1 min (demo only)
              </option>
            )}
            <option key="3600000" value="3600000">
              1 hour &nbsp; (Recommended for minimal clutter)
            </option>
            <option key="14400000" value="14400000">
              4 hours
            </option>
            <option key="28800000" value="28800000">
              8 hours
            </option>
            <option key="86400000" value="86400000">
              24 hours
            </option>
          </Field>
          of inactivity.
        </Box>
      </HStack>
      <HStack mt="4px" alignItems="flex-start">
        <Heading as="h3" mt="7px" fontSize="16px" fontWeight={500} mb="4px" whiteSpace={'nowrap'}>
          Tab limit:
        </Heading>
        <Box fontSize="16px" lineHeight="16px" color="#585858">
          Only run Automatic Save when I have more than
          <Field
            as={Select}
            display={'inline-block'}
            w="58px"
            h="28px"
            margin="4px 6px"
            size="xs"
            name={SETTINGS_FORM_FIELD_NAMES.OVERWHELM_THRESHOLD}
            fontSize="14px"
            fontWeight={500}
            color="#585858"
            borderColor="#585858"
            borderRadius={'8px'}
            _hover={{ bg: '#E3E3E3' }}
          >
            <option key="0" value="0">
              0 &nbsp; (Recommended)
            </option>
            <option key="6" value="6">
              6
            </option>
            {isDeveloper && (
              <option key="8" value="8">
                8 (developer only)
              </option>
            )}
            <option key="12" value="12">
              12
            </option>
            <option key="18" value="18">
              18
            </option>
          </Field>
          unpinned tabs in a window.
        </Box>
      </HStack>
    </VStack>
  )
}

export default OnboardingAutoSaveSettingsFormContent
