import React, { FC, useEffect } from 'react'
import { Button } from '@chakra-ui/react'
import { CHROME_STORE_URL } from '../../../constants'
import { useExtensionHealthContext } from '../../../contexts/ExtensionHealthContext'
import { useNavigate } from 'react-router'
import { useUserContext } from '../../../contexts/UserContext'
import AnnouncementsPage from '../../../components/AnnouncementsPage'

const heading = 'Say goodbye to browser clutter. 👋'
const content = [
  'The Skeema Chrome extension automatically saves and closes your unused tabs to help you focus on just the ones you need right now.',
  'Install the Skeema extension to enjoy a new way of browsing!',
]

const InstallExtensionPage: FC = () => {
  const { captureAnalytics } = useUserContext()
  const { extensionHealth } = useExtensionHealthContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (extensionHealth?.version) {
      navigate('/')
    }
  }, [extensionHealth?.version, navigate])

  const onClickGetSkeemaExtension = () => {
    captureAnalytics('install_extension_page:click_get_skeema')
  }

  return (
    <AnnouncementsPage
      heading={heading}
      headingMaxWidth="530px"
      content={content}
      widget={
        <Button
          mb="4"
          as="a"
          style={{
            backgroundColor: 'black',
            borderRadius: '20px',
            borderColor: '#D5D5D5',
            color: 'white',
            fontWeight: 'normal',
            padding: '0 30px',
          }}
          href={CHROME_STORE_URL}
          target="_blank"
          onClick={onClickGetSkeemaExtension}
        >
          Install Skeema
        </Button>
      }
    />
  )
}

export default InstallExtensionPage
