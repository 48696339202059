import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Flex, Text, Box, Spinner, IconButton } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import {
  useAddProjectPagesMutation,
  useDeleteProjectPageMutation,
  useGetProjectPagesQuery,
  useGetProjectQuery,
  useMoveProjectPageMutation,
} from '../../../redux/services/skeema/projects.endpoints'
import { styled } from 'styled-components'
import { useUserContext } from '../../../contexts/UserContext'
import ProjectDetailTitle from './ProjectDetailTitle'
import ProjectDetailPageController from './ProjectDetailPageController'
import { useDrop } from 'react-dnd'
import { DND_ITEM_ENUM } from '../../../models/dnd.types'
import { useReduxSelector } from '../../../redux/baseStore'
import { TablistPageType } from '../../../models/tablist_pages.types'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import ProjectPageLimitModal from '../projectsSidebar/ProjectPageLimitModal'
import { ProjectPageType } from '../../../models/saved_sessions.types'
import { IoMdArrowBack } from 'react-icons/io'
import { sendMessageToExtension } from '../../../webapp/utils/externalMessaging'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import SearchResultsReplacementWrapper from '../search/SearchResultsReplacementWrapper'

const BodyContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`

const EmptyLabelContainer = styled.div`
  width: 100%;
  text-align: center;
  align-items: center;
  overflow: hidden;

  p {
    color: #a7a7a7;
    margin-left: 16px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const PagesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 6px;
  padding-bottom: 1px; //Fixes dnd indicator line cut off on the bottom of the last page
`

interface Props {
  projectId: string
}

const ProjectDetailDashboard: FC<Props> = (props) => {
  const { projectId } = props
  const { captureAnalytics } = useUserContext()
  const navigate = useNavigate()

  const { projectConfig } = useFeatureFlagContext()
  const maxNumProjectPages = projectConfig.maxNumProjectsPages

  const isProjectPageDragging = useReduxSelector((state) => state.dnd.isProjectPageDragging)
  const { data: project, isLoading: isProjectLoading } = useGetProjectQuery({
    id: String(projectId),
  })
  const { data: pages, isLoading: isPagesLoading } = useGetProjectPagesQuery({
    projectId: String(projectId),
  })

  const [isProjectPageLimitModalOpen, setIsProjectPageLimitModalOpen] = useState<boolean>(false)

  const [addProjectPagesMutation] = useAddProjectPagesMutation()
  const [moveProjectPageMutation] = useMoveProjectPageMutation()
  const [deleteProjectPageMutation] = useDeleteProjectPageMutation()

  const urls = useMemo(() => pages?.map((page) => page.url), [pages])

  useEffect(() => {
    if (project?.is_owner) {
      sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.OPEN_WITHIN_PINNED_TAB, {
        url: window.location.href,
        shouldCloseSenderTab: true,
      })
    }
  }, [project])

  const handleAddTabsToProject = useCallback(
    async (params: {
      tablistPages: TablistPageType[]
      index?: number
      loggingProps?: Record<string, unknown>
    }) => {
      const { tablistPages, index, loggingProps } = params
      if (!project) {
        return
      }

      const pages = await addProjectPagesMutation({
        project,
        tablistPages,
        maxNumProjectPages,
        index,
      })
        .then((result) => {
          if ('error' in result) {
            console.error(result.error)
            return undefined
          }
          return result.data
        })
        .catch((e) => {
          console.error(e)
          return undefined
        })

      // Logging analytics below
      const numNewPages = pages?.length
      const numCurrentPages = urls?.length
      const totalNumPages =
        numNewPages !== undefined && numCurrentPages !== undefined
          ? numCurrentPages + numNewPages
          : undefined
      const pageEntityType = tablistPages.length > 0 ? tablistPages[0].entity_type : null
      captureAnalytics('project_detail_dashboard:pages_add_dnd', {
        newPages: pages,
        numNewPages,
        numCurrentPages,
        totalNumPages,
        urls,
        maxNumProjectPages,
        pageEntityType,
        index,
        isError: !pages,
        ...project,
        ...loggingProps,
      })
    },
    [addProjectPagesMutation, captureAnalytics, maxNumProjectPages, project, urls],
  )

  const handleDropProjectPage = useCallback(
    async (params: { sourceProjectId: string; index: number; page: ProjectPageType }) => {
      const { sourceProjectId, page, index } = params

      if (!project) {
        return
      }

      const destProject = project
      const sourceProject = undefined

      const pages = await moveProjectPageMutation({
        projectId: sourceProjectId,
        page,
        index,
        destProject,
        maxNumProjectPages,
      })
        .then((result) => {
          if ('error' in result) {
            console.error(result.error)
            return undefined
          }
          return result.data
        })
        .catch((e) => {
          console.error(e)
          return undefined
        })

      // Logging analytics below
      captureAnalytics('project_detail_dashboard:pages_move_dnd', {
        id: page.id,
        page,
        sourceProject,
        destProject,
        index,
        maxNumProjectPages,
        isError: !pages,
      })
    },
    [moveProjectPageMutation, captureAnalytics, project, maxNumProjectPages],
  )

  const handleDeleteProjectPage = useCallback(
    async (params: { page: ProjectPageType }) => {
      const { page } = params

      if (!project) {
        return
      }

      const pageId = page.id
      const projectId = project.id

      await deleteProjectPageMutation({
        projectId,
        pageId,
      })

      // Logging analytics below
      captureAnalytics('project_detail_dashboard:page_delete', {
        page,
        project,
      })
    },
    [project, deleteProjectPageMutation, captureAnalytics],
  )

  const handleProjectPageLimitError = useCallback(() => {
    captureAnalytics('project_detail_dashboard:project_page_limit_modal_show', {
      numTabs: urls?.length,
      urls,
      ...project,
    })
    setIsProjectPageLimitModalOpen(true)
  }, [captureAnalytics, project, urls])

  const handleDismissProjectPageLimitModal = useCallback(() => {
    captureAnalytics('project_detail_dashboard:project_page_limit_modal_cancel_click', {
      numTabs: urls?.length,
      urls,
      ...project,
    })
    setIsProjectPageLimitModalOpen(false)
  }, [captureAnalytics, project, urls])

  const handleSubmitProjectPageLimitModal = useCallback(() => {
    captureAnalytics('project_detail_dashboard:project_page_limit_modal_upgrade_click', {
      numTabs: urls?.length,
      urls,
      ...project,
    })
    setIsProjectPageLimitModalOpen(false)
    navigate('#pricing')
  }, [captureAnalytics, navigate, project, urls])

  const handleClickMenuBack = () => {
    captureAnalytics('project_detail_dashboard:back_click')
    navigate('/')
  }

  const [{ isDraggingOver }, connectDropTarget] = useDrop(
    () => ({
      accept: [DND_ITEM_ENUM.PROJECT_PAGE],
      collect: (monitor) => ({
        isDraggingOver: monitor.isOver(),
      }),
      canDrop: () => {
        return false
      },
    }),
    [],
  )

  if (project && project.is_archived) {
    navigate('/archive')
    return null
  }

  return (
    <>
      <Flex direction="column" h="100%" w="100%" p="24px 16px" overflow="auto">
        <SearchResultsReplacementWrapper>
          <Flex mb="6px" ml="16px" alignItems="center" gap="8px">
            <IconButton
              variant="outline"
              border="none"
              aria-label="Go to home page"
              icon={<IoMdArrowBack />}
              onClick={handleClickMenuBack}
              height="24px"
              width="24px"
              minWidth="24px"
              flexShrink={0}
              _hover={{
                bg: '#EBEBEB',
              }}
            />
            {!isProjectLoading && project && <ProjectDetailTitle project={project} urls={urls} />}
            {!isProjectLoading && !project && (
              <div style={{ width: '100%' }}>
                <Text fontSize="16px" fontWeight={600} color="#585858">
                  {`Error: Project does not exist`}
                </Text>
              </div>
            )}
          </Flex>
          {(isProjectLoading || isPagesLoading) && (
            <div className="flex-center" style={{ width: '100%', marginTop: '32px' }}>
              <Spinner color="blue.500" size="lg" speed="1s" />
            </div>
          )}

          {!isPagesLoading && !pages && (
            <div className="flex-center" style={{ width: '100%' }}>
              <Text textAlign="center" margin={4} fontSize="14px" color="#a7a7a7">
                {`Pages for this project could not be loaded.`}
              </Text>
            </div>
          )}

          {pages && (
            <BodyContainer
              className="scrollbars"
              ref={(node) => {
                connectDropTarget(node)
              }}
              $shouldShowDnDHighlight={isProjectPageDragging && !isDraggingOver}
            >
              {pages.length === 0 && (
                <EmptyLabelContainer>
                  <p>{`This project is empty.`}</p>
                </EmptyLabelContainer>
              )}
              {pages.length > 0 && (
                <PagesContainer>
                  {pages.map((p, index) => {
                    return (
                      <ProjectDetailPageController
                        key={p.id}
                        projectId={projectId}
                        page={p}
                        index={index}
                        numProjectPages={pages.length}
                        maxNumProjectPages={maxNumProjectPages}
                        handleAddTabsToProject={handleAddTabsToProject}
                        handleDropProjectPage={handleDropProjectPage}
                        handleProjectPageLimitError={handleProjectPageLimitError}
                        deleteProjectPage={handleDeleteProjectPage}
                      />
                    )
                  })}
                </PagesContainer>
              )}
            </BodyContainer>
          )}
        </SearchResultsReplacementWrapper>
      </Flex>

      <ProjectPageLimitModal
        isOpen={isProjectPageLimitModalOpen}
        onCancel={handleDismissProjectPageLimitModal}
        onSubmit={handleSubmitProjectPageLimitModal}
      />
    </>
  )
}

export default ProjectDetailDashboard
