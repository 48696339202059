import React, { FC, useRef } from 'react'
import { Tooltip, IconButton, Heading, Box } from '@chakra-ui/react'
import { styled } from 'styled-components'
import { MdOpenInNew, MdOutlineCreateNewFolder } from 'react-icons/md'
import DragIcon from '../../../components/DragIcon'
import Portal from '../../../widgets/Portal'

const TitleContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-bottom: 6px;

  // Hovered & expanded states are managed in SmartSessionCard.tsx
  & > h2 {
    color: #585858;
    transition: color 0.2s;
    font-size: 14px;
    font-weight: 600;
    word-break: break-word;
    line-height: 22px;
  }

  .hover-show-title-icons {
    transition: opacity 0.2s;
    opacity: 0;
  }
`

const HoverButtonsContainer = styled.div`
  height: 22px;
  position: absolute;
  right: 0;
  top: 0;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-left: 8px;
  background: #f2f3fe;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -24px;
    width: 24px;
    height: 100%;
    background-image: 'linear-gradient(to right, rgba(255, 255, 255, 0), #fff)';
  }
`

interface Props {
  title: string
  handleClickOpenIcon: (event: React.MouseEvent<Element, MouseEvent>) => void
  handleClickCreateProjectIcon: (event: React.MouseEvent<Element, MouseEvent>) => void
  forceShowSaveButton?: boolean
}

const SmartSessionCardTitle: FC<Props> = (props) => {
  const {
    title,
    handleClickOpenIcon,
    handleClickCreateProjectIcon,
    forceShowSaveButton = false,
  } = props
  const saveButtonRef = useRef<HTMLSpanElement | null>(null)
  const createProjectButtonTooltip = `Save as project`

  const saveButtonIcon = (
    <IconButton
      variant="outline"
      border="none"
      aria-label={createProjectButtonTooltip}
      as="a"
      icon={<MdOutlineCreateNewFolder color="#585858" />}
      onClick={handleClickCreateProjectIcon}
      height="24px"
      width="24px"
      minWidth="24px"
      tabIndex={-1}
      _hover={{ background: 'inherit' }}
    />
  )

  return (
    <TitleContainer>
      <Box w="12px" mr="6px" className="hover-show-title-icons">
        <DragIcon />
      </Box>

      <Heading as="h2">{title}</Heading>

      <HoverButtonsContainer>
        <Tooltip label={`Open all pages`} placement="top">
          <span
            className="flex-center hover-show-title-icons"
            style={{ height: '100%', cursor: 'pointer' }}
          >
            <IconButton
              variant="outline"
              border="none"
              aria-label={`Open all pages`}
              as="a"
              icon={<MdOpenInNew color="#585858" />}
              onClick={handleClickOpenIcon}
              height="24px"
              width="24px"
              minWidth="24px"
              tabIndex={-1}
              _hover={{ background: 'inherit' }}
            />
          </span>
        </Tooltip>
        <Tooltip label={createProjectButtonTooltip} placement="top">
          <span
            ref={saveButtonRef}
            className={'flex-center hover-show-title-icons'}
            style={{ height: '100%', cursor: 'pointer' }}
          >
            {saveButtonIcon}
          </span>
        </Tooltip>
        {forceShowSaveButton && saveButtonRef.current && (
          <Portal>
            <Tooltip label={createProjectButtonTooltip} placement="top">
              <span
                className={'flex-center'}
                style={{
                  zIndex: 1,
                  position: 'fixed',
                  cursor: 'pointer',
                  left: saveButtonRef.current.getBoundingClientRect().left,
                  top: saveButtonRef.current.getBoundingClientRect().top,
                }}
              >
                {saveButtonIcon}
              </span>
            </Tooltip>
          </Portal>
        )}
      </HoverButtonsContainer>
    </TitleContainer>
  )
}

export default SmartSessionCardTitle
