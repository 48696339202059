import React, { useCallback, useContext } from 'react'

type AuthContextType = {
  authHeader: string | undefined
  isAuthenticated: boolean
  signIn: (token: string) => boolean
  signOut: () => void
}

export const AuthContext = React.createContext<AuthContextType>({
  authHeader: undefined,
  isAuthenticated: false,
  signIn: () => false,
  signOut: () => {},
})

export const useAuthContext = (): AuthContextType => {
  return useContext(AuthContext)
}

abstract class AuthContextBackend {
  abstract setAuthHeader(token: string): void
  abstract clearAuthHeader(): void
  abstract getAuthHeader(): string | undefined
}

export class LocalStorageAuthContextBackend extends AuthContextBackend {
  constructor(private readonly key: string) {
    super()
  }

  setAuthHeader(token: string): void {
    localStorage.setItem(this.key, token)
  }

  clearAuthHeader(): void {
    localStorage.removeItem(this.key)
  }

  getAuthHeader(): string | undefined {
    const item = localStorage.getItem(this.key)
    if (item === null) {
      // Try if there are keys from react-auth-kit, which is saved with a key "undefined"
      const reactAuthKitItem = localStorage.getItem('undefined')
      if (reactAuthKitItem) {
        const authHeader = `Bearer ${reactAuthKitItem}`
        this.setAuthHeader(authHeader)
        return reactAuthKitItem
      }
    }
    return item ? item : undefined
  }
}

export const AuthContextProvider: React.FC<{
  backend: AuthContextBackend
  children: React.ReactNode
}> = ({ backend, children }) => {
  const [authHeader, setAuthHeader] = React.useState<string | undefined>(backend.getAuthHeader())

  const signIn = useCallback(
    (token: string): boolean => {
      const authHeader = `Bearer ${token}`
      backend.setAuthHeader(authHeader)
      setAuthHeader(authHeader)
      return true
    },
    [backend],
  )

  const signOut = useCallback(() => {
    setAuthHeader(undefined)
    backend.clearAuthHeader()
  }, [backend])

  return (
    <AuthContext.Provider value={{ authHeader, isAuthenticated: !!authHeader, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  )
}
