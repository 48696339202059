export const APP_NAME_SHORT = 'sk'
export const APP_NAME_FULL = 'Skeema'
export const {
  CURRENT_ENV,
  EXTENSION_VERSION,
  MINIMUM_EXTENSION_VERSION,
  EXTENSION_ID,
  OAUTH_WEB_CLIENT_ID,
  WEBAPP_BASE_URL,
  API_SERVER_URL,
  ENABLE_POSTHOG,
  POSTHOG_HOST,
  POSTHOG_KEY,
  ENABLE_SENTRY,
  SENTRY_DSN,
  ENABLE_META_PIXEL,
  META_PIXEL_ID,
  ENABLE_CONSOLE_LOG,
  STRIPE_PUBLISHABLE_KEY,
  PUTAWAY_ALARM_DELAY_IN_MIN,
  PUTAWAY_ALARM_PERIOD_IN_MIN,
} = __ENV_VAR__
export const TOLERATED_TAB_URLS = [`${WEBAPP_BASE_URL}/projects`, `${WEBAPP_BASE_URL}/auth/handler`]
export const CHROME_STORE_URL = `https://chrome.google.com/webstore/detail/${EXTENSION_ID}`
export const FEEDBACK_FORM_URL = 'https://forms.gle/9UvMS46f6N2fsGiN6'
export const HOW_TO_URL =
  'https://skeema.notion.site/Skeema-Getting-Started-Guide-8ceb9ad1509b4e7eaeb61bbc997d0e00?pvs=4'
export const UNINSTALL_FEEDBACK_FORM_URL = 'https://forms.gle/ZF5aPhFqSR4LDnVA9'
export const GIT_COMMIT_HASH = __COMMIT_HASH__
