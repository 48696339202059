import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { Box } from '@chakra-ui/react'
import { SmartSessionPageType, SmartSessionType } from '../../../models/smart_sessions.types'
import PutasideTabController from '../PutasideTabList/PutasideTabController'
import { TablistPageType } from '../../../models/tablist_pages.types'
import { TABLIST_AREA_NAME_ENUM } from '../PutasideTabList/PutasideTabList'
import SmartSessionCardTitle from './SmartSessionCardTitle'
import { styled } from 'styled-components'
import { useUserContext } from '../../../contexts/UserContext'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { sendMessageToExtension } from '../../../webapp/utils/externalMessaging'
import { useDrag } from 'react-dnd'
import { DND_ITEM_ENUM, DnDItemSmartSessionPayload } from '../../../models/dnd.types'
import { useReduxDispatch } from '../../../redux/baseStore'
import { setIsSmartSessionDragging } from '../../../redux/dndSlice'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import { FEATURE_FLAG_KEYS_ENUM } from '../../../models/feature_flags.types'

const Container = styled(Box)<{ $isDraggingThis: boolean }>`
  background: #f2f3fe;
  border-radius: 12px;
  border: 1.5px solid #cfd2ef;
  padding: 16px 16px 16px 2px;
  transition: all 0.2s ease-in-out;
  cursor: grab;
  opacity: ${({ $isDraggingThis }) => ($isDraggingThis ? 0.5 : 1)};

  &:not(:has(.TabBodyContainer:hover)):hover {
    .hover-show-title-icons {
      opacity: 1;
    }
  }

  &:hover {
    box-shadow:
      0px 4px 4px 0px rgba(0, 0, 0, 0.04),
      0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    h2 {
      color: #000;
    }
  }
`

export function convertSmartSessionPagesToTablistPages(pages: SmartSessionPageType[]) {
  return pages.map((page) => ({
    ...page,
    last_access_datetime_utc_iso: '',
    last_access_timestamp_ms: 0,
  }))
}

interface Props {
  session: SmartSessionType
  handleCreateProject: (title: string, tablistPages: TablistPageType[]) => Promise<void>
  handleProjectLimitError: () => void
  isProjectLimitReached: boolean
  forceShowSaveButton: boolean
}

const SmartSessionCard: FC<Props> = (props) => {
  const {
    session,
    handleCreateProject,
    handleProjectLimitError,
    isProjectLimitReached,
    forceShowSaveButton,
  } = props
  const { captureAnalytics, userInfo } = useUserContext()
  const dispatch = useReduxDispatch()
  const [disableCreateProject, setDisableCreateProject] = useState<boolean>(false)
  useEffect(() => {
    if (!forceShowSaveButton) {
      setDisableCreateProject(false)
    }
  }, [forceShowSaveButton])

  const { featureFlags } = useFeatureFlagContext()
  const isSmartSessionsEnabled =
    userInfo?.is_premium || featureFlags[FEATURE_FLAG_KEYS_ENUM.IsSmartSessionsEnabled]

  const pages = useMemo(() => {
    return convertSmartSessionPagesToTablistPages(session.pages)
  }, [session.pages])

  const handleClickOpenIcon = useCallback(
    async (event: React.MouseEvent<Element, MouseEvent>) => {
      event.stopPropagation()
      const urls = session.pages.map((page) => page.url)

      captureAnalytics('smart_session_card:open_pages_icon_click', {
        ...session,
        urls,
        isFullDraftProjectsEnabled: isSmartSessionsEnabled,
      })

      await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.OPEN_TABS, {
        urls,
      })
    },
    [captureAnalytics, isSmartSessionsEnabled, session],
  )

  const handleClickCreateProject = useCallback(
    async (event: React.MouseEvent<Element, MouseEvent>) => {
      event.stopPropagation()
      if (disableCreateProject) {
        return
      }
      if (forceShowSaveButton) {
        //Prevent users creating multiple projects during onboarding
        setDisableCreateProject(true)
      }
      captureAnalytics('smart_session_card:create_project_icon_click', {
        ...session,
        pages,
        isFullDraftProjectsEnabled: isSmartSessionsEnabled,
        isProjectLimitReached,
      })

      if (isProjectLimitReached) {
        handleProjectLimitError()
      } else {
        await handleCreateProject(session.name, pages)
      }
    },
    [
      captureAnalytics,
      disableCreateProject,
      forceShowSaveButton,
      handleCreateProject,
      handleProjectLimitError,
      isProjectLimitReached,
      isSmartSessionsEnabled,
      pages,
      session,
    ],
  )

  const [{ isDraggingThis }, connectDragSource, connectDragPreview] = useDrag(
    () => ({
      type: DND_ITEM_ENUM.SMART_SESSION,
      collect: (monitor) => ({
        isDraggingThis: monitor.isDragging(),
      }),
      end: () => dispatch(setIsSmartSessionDragging(false)),
      item: () => {
        dispatch(setIsSmartSessionDragging(true))
        const payload: DnDItemSmartSessionPayload = {
          type: DND_ITEM_ENUM.SMART_SESSION,
          session: session,
          isFullDraftProjectsEnabled: isSmartSessionsEnabled,
        }
        return payload
      },
    }),
    [dispatch, session],
  )

  useEffect(() => {
    connectDragPreview(getEmptyImage(), { captureDraggingState: true })
  }, [connectDragPreview])

  return (
    <Container ref={connectDragSource} $isDraggingThis={isDraggingThis}>
      <SmartSessionCardTitle
        title={session.name}
        handleClickOpenIcon={handleClickOpenIcon}
        handleClickCreateProjectIcon={handleClickCreateProject}
        forceShowSaveButton={forceShowSaveButton}
      />
      <Box>
        {pages.map((page, idx) => {
          return (
            <PutasideTabController
              key={page.id}
              id={page.id}
              page={page}
              queryValue=""
              index={idx}
              numTotalResults={session.pages.length}
              areaName={TABLIST_AREA_NAME_ENUM.SmartSessions}
              showTimeString={false}
              showActionIcons={false}
              isDraggingDisabled={false}
              showOpenTabIcon={page.is_open}
              extraAnalyticsProps={{
                isFullDraftProjectsEnabled: isSmartSessionsEnabled,
              }}
            />
          )
        })}
      </Box>
    </Container>
  )
}

export default SmartSessionCard
