import {
  BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS,
  MESSAGE_AGENTS,
} from '../../models/messaging.types'
import { EXTENSION_ID } from '../../../constants'
import { NOTIFICATION_FUNCTIONS } from './utils/notificationUtils'

export function onMessageActionGetPageHTML(
  _msg: unknown,
  _sender: chrome.runtime.MessageSender,
): unknown {
  const html = window.document.documentElement.innerHTML
  return { html }
}

export function onMessageShowNotification(
  msg: { payload: { remainingQuota: number } },
  _sender: chrome.runtime.MessageSender,
): boolean {
  const remainingQuota = msg.payload?.remainingQuota
  NOTIFICATION_FUNCTIONS.showNotification(remainingQuota)
  return true
}

export async function sendMessageToBackground(
  action: BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS,
  payload?: unknown,
): Promise<unknown> {
  return chrome.runtime.sendMessage(EXTENSION_ID, {
    to: MESSAGE_AGENTS.BACKGROUND,
    from: MESSAGE_AGENTS.CONTENT,
    action,
    payload,
  })
}
